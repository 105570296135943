import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GridItemActions } from 'types/ui'
import { removeFrame, updateFrameOptions } from 'store/dashboard/actions'
import * as UI from 'types/ui'
import * as Store from 'types/store'
import * as api from 'api/reports'
import * as Api from 'types/api'
import TechnicalChart from 'v2/components/frames/TechnicalChart'
import SelectTimeRangeModal from 'components/modals/SelectTimeRangeModal'
import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'
import moment from 'moment'
import { TechnicalFrameContext, TechnicalFrameContextInterface } from 'contexts/TechnicalFrameContext'
import { isApiError } from 'core'
import { showDetailedError } from 'core/helpers'
import FileDownload from 'js-file-download'
import { SessionStatus } from 'types/enums'

const getTimeRangeValue = (session: Store.Session): [moment.Moment, moment.Moment] => {
  if (session.isHistorical) {
    return [moment(session.startTime), moment(session.endTime)]
  }
  return [moment(session.startTime), null]
}

const generateReport = async (sessionId: number, assetIds: number[]) => {
  const response = await api.getVirtualSessionReport(sessionId, assetIds, "CET")
  if (isApiError(response)) {
    // debug('ERROR getDistributionStatistics')
    return response
  }

  return {
    data: response.data,
    filename: (response as Api.ReportResponse).filename
  }
}

interface Props {
  frame: Store.Frame
}

const TechnicalChartContainer: React.FC<Props> = ({ frame }) => {
  const {
    options: { viewType },
  } = frame
  const [isModalVisible, setModalVisible] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const session = useSelector(selectOperativeSessionSession)
  const selectedAssets = useSelector(selectOperativeSessionAssets)
  const [timeRange, setTimeRange] = useState<[moment.Moment, moment.Moment]>(null)

  const reportEnabled = 
    session.isHistorical && session.status === SessionStatus.Completed
    || session.isHistorical && session.status === SessionStatus.Broken
    || session.isHistorical && session.status === SessionStatus.Stopped

  useEffect(() => {
    if (session.startTime && !timeRange) {
      setTimeRange(getTimeRangeValue(session))
    }
  }, [session.startTime])

  const downloadReport = async () => {
    const assetIds = selectedAssets.map((asset) => asset.id)
    const res = await generateReport(session.id, assetIds)

    if (isApiError(res)) {
      const r = res as ApiError
      showDetailedError(
        r?.data?.result?.responseEnum ?? 'BAD REQUEST',
        r?.data?.result?.description ?? 'Not able to generate the report'
      )
      return
    }
    FileDownload(res.data as Blob, (res as Api.ReportResponse).filename)
  }

  const dispatch = useDispatch()
  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Configure:
        setModalVisible(true)
        break
      case GridItemActions.Download:
        downloadReport()
        break
    }
  }

  const onTimeRangeSelected = (start: moment.Moment, end: moment.Moment) => {
    if (session.startTime && start.isBefore(moment(session.startTime))) {
      setTimeRange([moment(session.startTime), end])
    } else {
      setTimeRange([start, end])
    }
    setModalVisible(false)
  }

  const setViewType = (viewType: UI.ViewType) => {
    const options = { ...frame.options, viewType }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const context: TechnicalFrameContextInterface = {
    id: frame.id,
    squares: {
      x: frame.layout.w,
      y: frame.layout.h,
    },
    isFullscreen: isFullScreen,
    viewType,
    setViewType,
    setFullScreen,
    timeRange: timeRange,
  }

  useEffect(() => {
    if (timeRange && timeRange[0].isBefore(moment(session.startTime))) {
      setTimeRange((timeRange) => [moment(session.startTime), timeRange[1]])
    }
  }, [session.startTime])

  return (
    <>
      {timeRange && (
        <SelectTimeRangeModal
          visible={isModalVisible}
          onClose={() => setModalVisible(false)}
          timeRange={timeRange}
          onOk={onTimeRangeSelected}
        />
      )}
      <TechnicalFrameContext.Provider value={context}>
        <TechnicalChart key={timeRange && timeRange[0].toISOString()} onItemClick={onItemClick} reportEnabled={reportEnabled} />
      </TechnicalFrameContext.Provider>
    </>
  )
}

export default TechnicalChartContainer
