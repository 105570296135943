import * as Api from 'types/api'
import * as Store from 'types/store'
import { ApiSessionType } from 'types/enums.api'
import { ColumnsType } from 'antd/lib/table'

export function storeToLayoutPayload(layout: Store.Layout): Api.LayoutPayload {
  const json: Api.LayoutPayload = {
    layoutName: layout.name,
    configurationJson: JSON.stringify(layout.configuration),
    sessionId: 3, //this should be not required anymore
    type: ApiSessionType.Trading, //this is not used anymore, any value is fine
  }

  if (layout.id) {
    json.layoutId = layout.id
  }

  return json
}

const mapOldFilterFnToTanstack = (oldFilterFn) => {
  return (row, columnId, filterValue) => {
    const cellValue = row.getValue(columnId)
    return oldFilterFn(cellValue, filterValue)
  }
}

const mapOldRenderFnToTanstack = (oldRenderFn) => {
  return ({cell, column, row, table, getValue, renderValue}) => {
    // console.log('cell', cell, column.renderArgs, oldRenderFn)
    if (column?.renderArgs && column.renderArgs.length > 0) {
      return oldRenderFn(column.renderArgs.map(a => a))
    }
    return cell.getValue() ? oldRenderFn(cell.getValue()) : null
  }
}

type TanstackColumn = {
  id?: string
  meta?: any
  header: () => string
  accessorKey: string
  enableResizing: boolean
  size: number
  fixed: boolean
  enableColumnFilter: boolean
  enableSorting: boolean
  cell: (value: any) => any
  // filterFn: (row: any, columnId: number, filterValue: any) => any
  filterFn: any
}

export const mapOldColumnsToNew = (columns: ColumnsType<Store.PortfolioTable | Store.PortfolioListAssetsTable | Store.StrategyTable>, dataRows: number): TanstackColumn[] => {
  return columns.map((column: any, i: number) => {
    // console.log('column', column)
    return {
      id: column.dataIndex,
      header: () => column.title,
      accessorKey: column.dataIndex,
      enableResizing: !column.fixed,
      size: column.width,
      fixed: column.fixed ?? false,
      enableColumnFilter: dataRows > 1,
      enableSorting: dataRows > 1,
      meta: column.meta ?? null,
      cell: column.render ? mapOldRenderFnToTanstack(column.render) : (value) => value?.getValue(),
      // filterFn: dataRows > 1 && column.sorter ? mapOldFilterFnToTanstack(column.sorter) : null,
      filterFn: column.sorter,
    }
  })
}
