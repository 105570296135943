export enum RequestStatus {
  None = 0,
  Requesting = 1,
  Success = 2,
  Error = 3,
}

export enum ErrorCode {
  Unknown = 0,
  Api = 1,
  Parameters = 3,
  PageUndefined = 4,
  GraphQlApi = 11,
  BootstrapFailed = 101,
  Login = 102,
  Register = 103,
  RegisterCodeNotFound = 104,
  RegisterInvalidPassword = 105,
  RegisterEmailTaken = 106,
  UserAlreadyRegistered = 107,
  AuthenticationFailed = 108,
  GenericError = 999,
}

// Reference link: https://ats05.atlassian.net/jira/software/projects/JI/boards/30?selectedIssue=JI-734

export enum Versions {
  V1 = 100,
  V2 = 200,
}

export enum LoadingState {
  Unset = 'unset',
  Loading = 'loading',
  Loaded = 'loaded',
  LoadFailure = 'load failure',
  Deleting = 'deleting',
  Deleted = 'deleted',
  DeleteFailed = 'deleting failed',
  Creating = 'creating',
  Created = 'created',
  CreateFailure = 'created failure',
  Updating = 'updating',
  Updated = 'updated',
  UpdateFailure = 'update failure',
}

export enum LongShort {
  Short = 'ONS',
  Long = 'ONL',
  LongShort = 'LAS',
}

export enum CrudOperation {
  None = 0,
  Create = 1,
  Read = 2,
  Update = 3,
  Delete = 4,
  List = 5,
}

export enum TransferLayout {
  Horizontal = 1,
  Vertical = 2,
}

export enum TransferContext {
  All = 1,
  AtSessionLaunch = 2,
  OnlyAssets = 3,
  AtBacktest = 4,
}

export enum SessionHistoryStatus {
  All,
  Live,
  Historical,
}

export enum SessionStatus {
  All,
  Off,
  Running,
  StandBy,
  Stopped,
  Completed,
  Created,
  Broken,
  Updating,
  Terminating,
  Unknown,
}

export enum SessionType {
  Trading = 'trading',
  Signals = 'signals',
  Virtual = 'virtual',
  BackTest = 'backtest',
  Unknown = 'unknown',
}

export enum AssetStatus {
  Freeze = 'FRZ',
  Automatic = 'AUT',
  StandByLong = 'SBL',
  StandByShort = 'SBS',
  StandByFlat = 'SBF',
  Broken = 'BRK',
  Off = 'OFF',
  NotDefined = 'NDF',
  Terminating = 'TRM',
  Updating = 'UPD',
  Quit = 'QUI',
}

export enum FrameType {
  MapView = 'mapView',
  Chart = 'graph',
  Table = 'table',
  Aggregate = 'aggregate',
  Custom = 'custom',
  SignalsTable = 'signals-table',
  PositionMonitoring = 'position-monitoring',
  ReturnAnalysis = 'return-analysis',
  ForwardAnalysis = 'forward-analysis',
  Statistics = 'statistics',
  StatisticsChartless = 'statistics-chartless',
  TechnicalChart = 'technical-chart',
  Undefined = 'undefined',
}

export enum TimeFrame {
  TDY = 'TDY', // Today
  YTD = 'YTD', // Year to day
  TOT = 'TOT', // TOTAL
}

export enum OpenOperationType {
  Short = 'SHR',
  Long = 'LNG',
  Flat = 'FLT',
}

export enum OrderSign {
  Sell = 'SELL',
  Buy = 'BUY',
}

export enum Dimension {
  Market = 'market',
  Currency = 'currency',
  AssetClass = 'assetClass',
  LongShort = 'longShort',
}

export enum TSInterval {
  Second = 1,
  HalfMinute = 30,
  Minute = 60,
  TwoMinutes = 120,
  ThreeMinutes = 180,
  FourMinutes = 240,
  FiveMinutes = 300,
  TenMinutes = 600,
  Fifteen = 900,
  ThirtyMinutes = 1800,
  SixHours = 21600,
  Day = 86400,
}

export enum AnalysisWindowTabType {
  PositionMonitoring = 'position-monitoring',
  ReturnAnalysis = 'return-analysis',
}

export enum FlowControlSpeed {
  Slow = 'slow',
  Medium = 'medium',
  High = 'high',
}

export enum UserStatus {
  All,
  Active,
  Inactive,
}

export enum TimeUnit {
  Days = 'd',
  Hours = 'h',
  Minutes = 'm',
  Seconds = 's',
  MilliSeconds = 'ms',
}
export enum AssetMetricStatus {
  Freeze = 'FREEZE',
  Off = 'OFF',
}

export enum MetricType {
  Asset = 'asset',
  Session = 'session',
  Clock = 'clock',
  Progress = 'progress',
}

export type Instruments =
  | '10EQ'
  | '15RT'
  | '20CW'
  | '30BO'
  | '40FU'
  | '50OP'
  | '55ST'
  | '60IB'
  | '70FN'
  | '80CO'
  | '90FW'
  | '95RA'
  | '96OT'
  | '97DC'
  | '99UN'
