// source: metrics_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.metrics.Alive', null, global);
goog.exportSymbol('proto.metrics.Message', null, global);
goog.exportSymbol('proto.metrics.Message.MessagetypeCase', null, global);
goog.exportSymbol('proto.metrics.Metric', null, global);
goog.exportSymbol('proto.metrics.Resources', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metrics.Alive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metrics.Alive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metrics.Alive.displayName = 'proto.metrics.Alive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metrics.Resources = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metrics.Resources, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metrics.Resources.displayName = 'proto.metrics.Resources';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metrics.Metric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metrics.Metric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metrics.Metric.displayName = 'proto.metrics.Metric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metrics.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.metrics.Message.oneofGroups_);
};
goog.inherits(proto.metrics.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metrics.Message.displayName = 'proto.metrics.Message';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metrics.Alive.prototype.toObject = function(opt_includeInstance) {
  return proto.metrics.Alive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metrics.Alive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metrics.Alive.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metrics.Alive}
 */
proto.metrics.Alive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metrics.Alive;
  return proto.metrics.Alive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metrics.Alive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metrics.Alive}
 */
proto.metrics.Alive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metrics.Alive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metrics.Alive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metrics.Alive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metrics.Alive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metrics.Resources.prototype.toObject = function(opt_includeInstance) {
  return proto.metrics.Resources.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metrics.Resources} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metrics.Resources.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpu: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    ram: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metrics.Resources}
 */
proto.metrics.Resources.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metrics.Resources;
  return proto.metrics.Resources.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metrics.Resources} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metrics.Resources}
 */
proto.metrics.Resources.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCpu(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metrics.Resources.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metrics.Resources.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metrics.Resources} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metrics.Resources.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double cpu = 1;
 * @return {number}
 */
proto.metrics.Resources.prototype.getCpu = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.metrics.Resources} returns this
 */
proto.metrics.Resources.prototype.setCpu = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.metrics.Resources} returns this
 */
proto.metrics.Resources.prototype.clearCpu = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Resources.prototype.hasCpu = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double ram = 2;
 * @return {number}
 */
proto.metrics.Resources.prototype.getRam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.metrics.Resources} returns this
 */
proto.metrics.Resources.prototype.setRam = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.metrics.Resources} returns this
 */
proto.metrics.Resources.prototype.clearRam = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Resources.prototype.hasRam = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metrics.Metric.prototype.toObject = function(opt_includeInstance) {
  return proto.metrics.Metric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metrics.Metric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metrics.Metric.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    status: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    resource: (f = msg.getResource()) && proto.metrics.Resources.toObject(includeInstance, f),
    message: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    value: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metrics.Metric}
 */
proto.metrics.Metric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metrics.Metric;
  return proto.metrics.Metric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metrics.Metric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metrics.Metric}
 */
proto.metrics.Metric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.metrics.Resources;
      reader.readMessage(value,proto.metrics.Resources.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metrics.Metric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metrics.Metric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metrics.Metric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metrics.Metric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.metrics.Resources.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string timestamp = 1;
 * @return {string}
 */
proto.metrics.Metric.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.setTimestamp = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.clearTimestamp = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Metric.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string key = 2;
 * @return {string}
 */
proto.metrics.Metric.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.clearKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Metric.prototype.hasKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.metrics.Metric.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Metric.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Resources resource = 4;
 * @return {?proto.metrics.Resources}
 */
proto.metrics.Metric.prototype.getResource = function() {
  return /** @type{?proto.metrics.Resources} */ (
    jspb.Message.getWrapperField(this, proto.metrics.Resources, 4));
};


/**
 * @param {?proto.metrics.Resources|undefined} value
 * @return {!proto.metrics.Metric} returns this
*/
proto.metrics.Metric.prototype.setResource = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Metric.prototype.hasResource = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string message = 5;
 * @return {string}
 */
proto.metrics.Metric.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Metric.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string value = 6;
 * @return {string}
 */
proto.metrics.Metric.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.metrics.Metric} returns this
 */
proto.metrics.Metric.prototype.clearValue = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Metric.prototype.hasValue = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.metrics.Message.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.metrics.Message.MessagetypeCase = {
  MESSAGETYPE_NOT_SET: 0,
  METRIC: 1,
  ALIVE: 2
};

/**
 * @return {proto.metrics.Message.MessagetypeCase}
 */
proto.metrics.Message.prototype.getMessagetypeCase = function() {
  return /** @type {proto.metrics.Message.MessagetypeCase} */(jspb.Message.computeOneofCase(this, proto.metrics.Message.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metrics.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.metrics.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metrics.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metrics.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    metric: (f = msg.getMetric()) && proto.metrics.Metric.toObject(includeInstance, f),
    alive: (f = msg.getAlive()) && proto.metrics.Alive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metrics.Message}
 */
proto.metrics.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metrics.Message;
  return proto.metrics.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metrics.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metrics.Message}
 */
proto.metrics.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.metrics.Metric;
      reader.readMessage(value,proto.metrics.Metric.deserializeBinaryFromReader);
      msg.setMetric(value);
      break;
    case 2:
      var value = new proto.metrics.Alive;
      reader.readMessage(value,proto.metrics.Alive.deserializeBinaryFromReader);
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metrics.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metrics.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metrics.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metrics.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetric();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.metrics.Metric.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.metrics.Alive.serializeBinaryToWriter
    );
  }
};


/**
 * optional Metric metric = 1;
 * @return {?proto.metrics.Metric}
 */
proto.metrics.Message.prototype.getMetric = function() {
  return /** @type{?proto.metrics.Metric} */ (
    jspb.Message.getWrapperField(this, proto.metrics.Metric, 1));
};


/**
 * @param {?proto.metrics.Metric|undefined} value
 * @return {!proto.metrics.Message} returns this
*/
proto.metrics.Message.prototype.setMetric = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.metrics.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metrics.Message} returns this
 */
proto.metrics.Message.prototype.clearMetric = function() {
  return this.setMetric(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Message.prototype.hasMetric = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Alive alive = 2;
 * @return {?proto.metrics.Alive}
 */
proto.metrics.Message.prototype.getAlive = function() {
  return /** @type{?proto.metrics.Alive} */ (
    jspb.Message.getWrapperField(this, proto.metrics.Alive, 2));
};


/**
 * @param {?proto.metrics.Alive|undefined} value
 * @return {!proto.metrics.Message} returns this
*/
proto.metrics.Message.prototype.setAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.metrics.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metrics.Message} returns this
 */
proto.metrics.Message.prototype.clearAlive = function() {
  return this.setAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metrics.Message.prototype.hasAlive = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.metrics);
