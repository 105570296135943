import React, { useEffect, useMemo, useRef, useState } from 'react'
import StrategyTable from 'v2/components/frames/StrategyTable'
import * as Store from 'types/store'
import { useDispatch, useSelector } from 'react-redux'
import { removeFrame } from 'store/dashboard/actions'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { GridItemActions } from 'types/ui'
import AddFrameModalContainer from 'v2/containers/settings/modals/AddFrameModal'
import { selectPortfolios, selectStrategies } from 'store/resources/selectors'
import Unavaliable from 'v2/components/frames/Unavailable/Unavaliable'
import SelectColumnsModal from 'v2/components/settings/modals/SelectColumnsModal'
import { FlowControlSpeed, SessionStatus } from 'types/enums'
import sigmaServices from 'config/sigmaServices'
import { PortfolioFeed, StrategyFeed } from 'types/enums.api'
import { isOffline } from 'hooks/common'
import { config } from 'core'
import { ColumnSelector } from 'v2/components/tables/JITable/ColumnSelector'

const {
  Strategies: {
    Return: StrategyReturn,
    Operations: StrategyOperations,
    ReferenceData: StrategyRefData,
    Position: StrategyPosition,
  },
  Portfolios: {
    ReferenceData: PortfolioRefData,
    Position: PortfolioPosition,
    Operations: PortfolioOperations,
    Return: PortfolioReturn,
  },
} = sigmaServices

interface Props {
  frame: Store.Frame
}

const StrategyTableContainer: React.FC<Props> = ({ frame }) => {
  const dispatch = useDispatch()
  const resource = frame.selectedResources[0].resource as Store.Strategy
  const { columnKeys, columnsConfigs } = frame.options
  const { id: sessionId } = useSelector(selectOperativeSessionSession)
  const strategies = useSelector(selectStrategies)
  const portfolios = useSelector(selectPortfolios)
  const session = useSelector(selectOperativeSessionSession)
  const sessionPortfolios = useMemo(
    () =>
      portfolios.filter((portfolio) => portfolio.strategyId === resource.id).map((portfolio) => portfolio.id),
    [portfolios],
  )
  const [isModalVisible, setModalVisible] = useState(false)
  const [isSelectColumnsModalVisible, setSelectColumnsModalVisible] = useState(false)

  const getHasMissingResource = () => {
    const strategy = strategies.find((strategy) => strategy.id === resource.id)
    if (!strategy) {
      return true
    }
    return false
  }

  const webWorkerRef = useRef<Worker | null>(null)

  const [data, setData] = useState<any>({ sigmasData: {}, strategyData: {} })

  const [lastTick, setLastTick] = useState<Date>(new Date())

  const messageReceived = () => {
    setLastTick(new Date())
  }

  useEffect(() => {
    webWorkerRef.current = new Worker(new URL('../../../../workers/strategyPortfolioSockets.web.worker.ts', import.meta.url))

    webWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { type, data: workerData, error } = event.data
      if (type === 'data') {
        setData(workerData)
        messageReceived()
      } else if (type === 'error') {
        console.error('Worker error:', error)
      }
    }

    const initMsg = {
      type: 'init',
      payload: {
        strategyId: resource.id,
        portfolioIds: sessionPortfolios,
        sessionId,
        sigmas: [
          // Strategy sigmas
          { service: StrategyFeed.Position, sigmas: [StrategyReturn.StgExchRetBcList, StrategyReturn.StgAllRetBcList, StrategyReturn.StgTradRetBcList] },
          { service: StrategyFeed.ReferenceData, sigmas: [StrategyRefData.StgRefData, StrategyRefData.StgCartesianHeading] },
          { service: StrategyFeed.Operations, sigmas: [StrategyOperations.StgCartesianOperationsList] },
          { service: StrategyFeed.Position, sigmas: [StrategyPosition.StgCartesianPosition] },
          { service: StrategyFeed.Return, sigmas: [StrategyReturn.StgExchRetBcList, StrategyReturn.StgAllRetBcList, StrategyReturn.StgTradRetBcList] },
          // Portfolio sigmas
          { service: PortfolioFeed.Return, sigmas: [PortfolioReturn.PrtExchRetBcList, PortfolioReturn.PrtAllRetBcList, PortfolioReturn.PrtTradRetBcList] },
          { service: PortfolioFeed.ReferenceData, sigmas: [PortfolioRefData.PrtCartesianHeading, PortfolioRefData.PrtRefData] },
          { service: PortfolioFeed.Operations, sigmas: [PortfolioOperations.PrtCartesianOperationsList] },
          { service: PortfolioFeed.Position, sigmas: [PortfolioPosition.PrtCartesianPosition] },
        ],
        flowControlSpeed: FlowControlSpeed.Slow,
        webSocketUrl: config.socketBaseUrl,
      },
    }

    // Send startup message (with all the setup params) to the worker
    webWorkerRef.current.postMessage(initMsg)

    return () => {
      webWorkerRef.current.postMessage({ type: 'terminate' })
      webWorkerRef.current.terminate()
    }
  }, [])

  const onClose = () => {
    setModalVisible(false)
  }

  const onSelectColumnsModalClose = () => {
    setSelectColumnsModalVisible(false)
  }

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
      case GridItemActions.ColumnSelect:
        setSelectColumnsModalVisible(true)
    }
  }

  if (getHasMissingResource()) {
    return <Unavaliable onItemClick={onItemClick} title={resource.name} />
  }

  const isStrategyOffline = () => {
    if (session.status !== SessionStatus.Running) {
      return false
    }
    return isOffline(lastTick)
  }

  // console.log(' strategy data', data.strategyData)

  return (
    <>
      <StrategyTable
        portfolioData={data.sigmasData.length ? data.sigmasData : []}
        strategyData={[data.strategyData ?? {}]}
        columnKeys={columnKeys}
        columnsConfigs={columnsConfigs}
        onItemClick={onItemClick}
        isOffline={isStrategyOffline()}
        frame={frame}
      />
      <AddFrameModalContainer
        initialFrame={frame}
        visible={isModalVisible}
        onClose={onClose}
        initialX={0}
        initialY={0}
      />
      {/* <SelectColumnsModal
        frame={frame}
        visible={isSelectColumnsModalVisible}
        onClose={onSelectColumnsModalClose}
        tableTypes={['strategy', 'strategy-portfolios']}
      /> */}
      <ColumnSelector
        open={isSelectColumnsModalVisible}
        frame={frame}
        tableTypes={['strategy', 'strategy-portfolios']}
        onClose={onSelectColumnsModalClose}
      />
    </>
  )
}

export default StrategyTableContainer
