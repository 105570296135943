import { isAnyItemsSelected } from 'helpers/transfer/core'
import { ExtendedDataNode, NodeType, TransferContextData, TransferType } from 'types/ui'

export const getAllAssets = (node: ExtendedDataNode): TransferContextData => {
  if (!isAnyItemsSelected(node.children, NodeType.Asset)) {
    return null
  }

  return {
    key: 'all-assets',
    // label: 'transfer.removeAllAssets', JI-TRANSLATE for some reason this label is not working here
    label: 'Remove All Assets',
    transferType: TransferType.RemoveAssets,
  }
}

export const getAllPortfolios = (node: ExtendedDataNode): TransferContextData => {
  if (!isAnyItemsSelected(node.children, NodeType.Portfolio)) {
    return null
  }

  return {
    key: 'all-portfolios',
    label: 'transfer.removeAllPortfolios',
    transferType: TransferType.RemovePortfolios,
  }
}

// export const getAllStrategies = (node: ExtendedDataNode): TransferContextData => {
//   if (!isAnyItemsSelected(node.children, NodeType.Strategy)) {
//     return null
//   }
//   return {
//     key: 'all-strategies',
//     label: 'transfer.removeAllStrategies',
//     transferType: TransferType.RemoveStrategies,
//   }
// }

export const getRemoveAllNodes = (): TransferContextData => {
  return {
    key: 'all-nodes',
    label: 'transfer.removeAll',
    transferType: TransferType.RemoveAll,
  }
}

export const getMainItem = (node: ExtendedDataNode): TransferContextData => {
  if (!node.selected) {
    return null
  }
  const transferType = TransferType.Remove
  let label: string

  switch (node.type) {
    case NodeType.Portfolio:
      label = 'transfer.removePortfolio'
      break
    case NodeType.Strategy:
      label = 'transfer.removeStrategy'
      break
    case NodeType.Group:
      label = 'Remove Group'
      break
    default:
      label = 'undefined'
      break
  }

  return {
    key: 'main-item',
    label,
    transferType,
  }
}
