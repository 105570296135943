import { Tag } from 'antd'
import React from 'react'
import { t } from 'core/i18n'
import { SessionStatus } from 'types/enums'
import colors from 'config/colors'

export const getStatus = (status: SessionStatus) => {
  switch (status) {
    case SessionStatus.StandBy:
      return <Tag color={colors.orange}>{t('status.standBy')}</Tag>
    case SessionStatus.Created:
      return (
        <Tag className="tag-status tag-status--created" color={colors.created}>
          {t('status.created')}
        </Tag>
      )
    case SessionStatus.Running:
      return (
        <Tag className="tag-status tag-status--running" color={colors.running}>
          {t('status.running')}
        </Tag>
      )
    case SessionStatus.Completed:
      return (
        <Tag className="tag-status tag-status--completed" color={colors.completed}>
          {t('status.completed')}
        </Tag>
      )
    case SessionStatus.Stopped:
      return (
        <Tag className="tag-status tag-status--stopped" color={colors.stopped}>
          {t('status.stopped')}
        </Tag>
      )
    case SessionStatus.Broken:
      return (
        <Tag className="tag-status tag-status--broken" color={colors.broken}>
          {t('status.broken')}
        </Tag>
      )
    case SessionStatus.Off:
      return (
        <Tag className="tag-status tag-status--off" color={colors.off}>
          {t('status.off')}
        </Tag>
      )
    case SessionStatus.Updating:
      return (
        <Tag className="tag-status tag-status--updating" color={colors.updating}>
          {t('status.updating')}
        </Tag>
      )
    case SessionStatus.Terminating:
      return (
        <Tag className="tag-status tag-status--terminating" color={colors.terminating}>
          {t('status.terminating')}
        </Tag>
      )
    default:
      return <Tag color={colors.grey}>{`${t('status.undefined')} (${status})`}</Tag>
  }
}

export const getSessionStatusLabel = (status: SessionStatus) => {
  switch (status) {
    case SessionStatus.StandBy:
      return t('status.standBy')
    case SessionStatus.Created:
      return t('status.created')
    case SessionStatus.Running:
      return t('status.running')
    case SessionStatus.Completed:
      return t('status.completed')
    case SessionStatus.Stopped:
      return t('status.stopped')
    case SessionStatus.Broken:
      return t('status.broken')
    case SessionStatus.Off:
      return t('status.off')
    case SessionStatus.Updating:
      return t('status.updating')
    case SessionStatus.Terminating:
      return t('status.terminating')
    default:
      return `${t('status.undefined')} (${status})`
  }
}
