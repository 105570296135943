import React, { useEffect, useState } from 'react'
import * as api from 'api/groups'
import * as assetApi from 'api/assets'
import * as Store from 'types/store'
import { isApiError } from 'core'
import { CrudOperation } from 'types/enums'
import { jsonToGroupModelParameters, jsonToModelParameters } from 'services/store/mapService'
import { GroupModelParameters } from 'components/StrategyViewer/forms/AssetForm/GroupModelParameters'

export const getModelParameters = async (
  // mode: CrudOperation,
  modelHasChanged?: boolean,
  groupId?: number,
  modelId?: number,
): Promise<Store.ModelParameter[]> => {

  if (modelHasChanged) {
    const response = await assetApi.getModelParameters(modelId)
    if (isApiError(response)) {
      return
    }
    
    const modelParameters = response
    const parameters = modelParameters.data.application_model_class_parameter
  
    return jsonToModelParameters(parameters)
  }

  const response = await api.getGroupModelParameters(groupId)
  if (isApiError(response)) {
    return null
  }

  const modelParameters = response
  const parameters = modelParameters.data.application_model_group_parameter

  return jsonToGroupModelParameters(parameters)


}

interface Props {
  modelId?: number
  groupId?: number
  mode: CrudOperation
  modelHasChanged?: boolean
}

export const GroupModelParametersContainer: React.FC<Props> = ({ groupId, mode, modelId, modelHasChanged }) => {
  const [modelParams, setModelParams] = useState<Store.ModelParameter[]>([])

  useEffect(() => {
    const getData = async () => {
      const data = await getModelParameters(modelHasChanged, groupId, modelId)

      if (data) {
        setModelParams(data)
      }
    }

    getData()
  }, [groupId, modelId])

  return <GroupModelParameters params={modelParams} mode={mode} />
}
