import { BaseSyntheticEvent, SyntheticEvent, useCallback, useState, useRef, useMemo, useEffect, memo, useLayoutEffect } from "react"
import {
  Column,
  ColumnFiltersState,
  ColumnResizeDirection,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  RowData,
  useReactTable,
} from "@tanstack/react-table"
import { useVirtualizer, useWindowVirtualizer } from '@tanstack/react-virtual'
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, FilterFilled, SearchOutlined } from "@ant-design/icons"
import { ColumnKeys, Frame, FrameOptions, TableConfig } from "types/store"
import { useDispatch, useSelector } from "react-redux"
import { updateFrameOptions } from "store/dashboard/actions"
import { selectDashboardSessionFrames } from "store/dashboard/selectors"
import './jiVirtualTable.scss'
import { throttle } from "lodash"

type JITableProps = {
  data: any[]
  columns: any[]
  frame: Frame,
  columnsConfig?: TableConfig
  classname?: string
  scrollHeight?: number
  width?: number
}

declare module '@tanstack/react-table' {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select' | 'date'
  }
}

const moveTableColumn = (columnOrder: string[], movingColumnKey: string, targetColumnKey: string) => {
  const updatedColumnsOrder = [...columnOrder]
  const movingColumnIndex = columnOrder.indexOf(movingColumnKey)
  const targetColumnIndex = columnOrder.indexOf(targetColumnKey)

  const element = updatedColumnsOrder.splice(movingColumnIndex, 1)[0] // Remove the element
  updatedColumnsOrder.splice(targetColumnIndex, 0, element) // Insert it at the new position

  // console.log(`column ${movingColumnKey} from`, movingColumnIndex, `to ${targetColumnKey} column`, targetColumnIndex)

  return updatedColumnsOrder
}

const ColumnResizer = ({ header, tableInstance }) => {
  const resizeHandler = header.getResizeHandler()

  const onMouseDown = (e) => {
    e.stopPropagation()
    resizeHandler(e)
  }

  const onTouchStart = (e) => {
    e.stopPropagation()
    resizeHandler(e)
  }

  return (
    <div
      onDoubleClick={() => header.column.resetSize()}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      className={`
        ${'resizer'}
        ${header.column.getIsResizing() ? 'isResizing' : ''}
      `}
      style={{
        transform:
          header.column.getIsResizing()
            ? `translateX(${
                1 * (tableInstance.getState().columnSizingInfo.deltaPercentage ?? 0)
              }px)`
            : '',
      }}
    >
    </div>
  )
}

const Filter = ({ column, setFilterOpen }: { column: Column<any, unknown>, setFilterOpen: any }) => {
  const { filterVariant } = column.columnDef.meta ?? {}

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = useMemo(
    () =>
      filterVariant === 'range'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys())
            .sort()
            // .slice(0, 5000)
            ,
    [column.getFacetedUniqueValues(), filterVariant]
  )

  const renderFilter = () => {
    switch (filterVariant) {
      case 'range':
        return (
          <>
            <DebouncedInput
              type="number"
              min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
              max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
              value={(columnFilterValue as [number, number])?.[0] ?? ''}
              onChange={(value) =>
                column.setFilterValue((old: [number, number]) => [value, old?.[1]])
              }
              placeholder={`Min ${
                // eslint-disable-next-line no-undefined
                column.getFacetedMinMaxValues()?.[0] !== undefined
                  ? `(${column.getFacetedMinMaxValues()?.[0]})`
                  : ''
              }`}
              className="filter-number-input"
            />
            <DebouncedInput
              type="number"
              min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
              max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
              value={(columnFilterValue as [number, number])?.[1] ?? ''}
              onChange={(value) =>
                column.setFilterValue((old: [number, number]) => [old?.[0], value])
              }
              placeholder={`Max ${
                column.getFacetedMinMaxValues()?.[1]
                  ? `(${column.getFacetedMinMaxValues()?.[1]})`
                  : ''
              }`}
              className="filter-number-input"
            />
          </>
        )
      case 'select':
        return (
          <>
            <select
              className="filter-select-input"
              onChange={(e) => {
                column.setFilterValue(e.target.value)
              }}
              value={columnFilterValue?.toString()}
            >
              <option value="">All</option>
              {sortedUniqueValues.map((value: any) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            <div className={'close-btn'} onClick={() => setFilterOpen(null)}>
              <CloseOutlined />
            </div>
          </>
        )
      case 'text':
        return (
          <>
            {sortedUniqueValues.length > 1 &&
              <datalist id={column.id + 'list'}>
                {sortedUniqueValues.map((value: any) => (
                  <option value={value} key={value} />
                ))}
              </datalist>
            }
            <DebouncedInput
              type="text"
              value={(columnFilterValue ?? '') as string}
              onChange={(value) => column.setFilterValue(value)}
              placeholder={`Search... ${column.getFacetedUniqueValues().size > 1 ? '(' + column.getFacetedUniqueValues().size + ')' : ''}`}
              className="filter-text-input"
              list={column.id + 'list'}
            />
            <div className={'close-btn'} onClick={() => setFilterOpen(null)}>
              <CloseOutlined />
            </div>
          </>
        )
      default:
        return null
    }
  }

  return renderFilter()
}

// Debounced input
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={e => setValue(e.target.value)} />

}

const HeaderRowCell = ({
  header,
  handleDragStart = (e: BaseSyntheticEvent) => {},
  handleDragOver = (e: BaseSyntheticEvent) => {},
  handleDrop = (e: BaseSyntheticEvent) => {},
  handleDragEnd = (e: BaseSyntheticEvent) => {},
  handleDragLeave = (e: BaseSyntheticEvent) => {},
  filterOpen,
  setFilterOpen,
  handleFilterOpen,
  tableInstance,
}) => {
  const isFixed = (header as any).column.getIsPinned()
  const isResizable = (header as any).column.getCanResize()
  const colWidth = header.getSize()
  return (
    <div
      style={{
        position: isFixed ? 'sticky' : 'relative',
        // overflowX: 'hidden',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
        left: isFixed === 'left' ? 0 : 'unset',
        zIndex: isFixed ? 1 : 0,
        backgroundColor: '#131a2c',
        width: isNaN(colWidth) ? 'auto' : `${colWidth}px`,
        // borderRight: '1px solid red',
      }}
      // className={classname}
      className="header-cell"
      data-column-key={(header as any).column.columnDef.accessorKey}
    >
      <div className={'col-header'}>
        <div
          className="title"
          style={{ cursor: isResizable ? 'move' : 'default' }}
          draggable={!isFixed}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragEnd={handleDragEnd}
          onDragLeave={handleDragLeave}
          // data-column-key={(header as any).column.columnDef.accessorKey}
        >
          {header.isPlaceholder
            ? null
            : flexRender(
                header.column.columnDef.header,
                header.getContext()
              )
          }
        </div>
        <div className={`${'col-actions'}`}>
          {/* <div className={`${'grab-handle'}`}>
            {!isFixed &&
              <span>🟰</span>
            }
          </div> */}
          <div
            className={
              `${'sorter'}
                ${header.column.getCanSort()
                  ? 'cursor-pointer select-none'
                  : ''
              }`
            }
            onClick={header.column.getToggleSortingHandler()}
          >
            {
              header.column.getCanSort()
                ? header.column.getNextSortingOrder() === 'asc'
                  ? <CaretUpOutlined style={{color: "#bfbfbf"}} />
                  : header.column.getNextSortingOrder() === 'desc'
                    ? <CaretDownOutlined style={{color: "#bfbfbf"}} />
                    : <div style={{display: 'flex', flexDirection: 'column'}}>
                        <CaretUpOutlined style={{color: "#bfbfbf"}} />
                        <CaretDownOutlined style={{color: "#bfbfbf"}} />
                      </div>
                : null
            }
          </div>
          <div className={'filter'}>
            {header.column.getCanFilter() &&
              header.column.columnDef.meta?.filterVariant
              ? header.column.columnDef.meta?.filterVariant === 'select'
                ?
                  (<>
                    <FilterFilled style={{color: header.column.getIsFiltered() ? "#1890ff" : "#bfbfbf"}} onClick={() => handleFilterOpen(header.column)} />
                    {filterOpen === header.column
                      ?
                        <div className={'filter-panel'}>
                          <Filter column={filterOpen} setFilterOpen={setFilterOpen} />
                        </div>
                      : null
                    }
                  </>)
                : (<>
                    <SearchOutlined style={{color: header.column.getIsFiltered() ? "#1890ff" : "#bfbfbf"}} onClick={() => handleFilterOpen(header.column)} />
                    {filterOpen === header.column
                      ?
                        <div className={'filter-panel'}>
                          <Filter column={filterOpen} setFilterOpen={setFilterOpen} />
                        </div>
                      : null
                    }
                  </>)
              : null
              // flexRender(
              //   header.column.columnDef.filter,
              //   header.getContext()
              // )
            }
          </div>
          {header.column.getCanResize() &&
            <ColumnResizer header={header} tableInstance={tableInstance} />
          }
        </div>
      </div>
    </div>
  )
}

export const JIVirtualTable: React.FC<JITableProps> = ({ data, columns, classname, frame, scrollHeight, width, columnsConfig }) => {
  const [columnOrder, setColumnOrder] = useState<string[]>(columns.map((col) => col.accessorKey))
  const [movingColumnKey, setMovingColumnKey] = useState<string | null>(null)
  const [targetColumnKey, setTargetColumnKey] = useState<string | null>(null)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState({})
  // const [columnSizingState, setColumnSizingState] = useState({})
  const [filterOpen, setFilterOpen] = useState<any>(null)
  const [clickedRow, setClickedRow] = useState<string | null>(null)

  const columnsConfigs = useMemo(() => columnsConfig, [columnsConfig])

  const tableHeight = useMemo(() => scrollHeight - (14), [scrollHeight])
  const tableWidth = useMemo(() => width - 28, [width])

  const dispatch = useDispatch()

	const autoSave = useCallback((columnsConfigs: TableConfig[]) => {
    const options: FrameOptions = { ...frame.options, columnsConfigs }

    dispatch(updateFrameOptions({ id: frame.id, options }))
  }, [columnsConfigs])

  useEffect(() => {
    const obj = {}
    if ((columnsConfigs as any)?.columns) {
      (columnsConfigs as any)?.columns.forEach((c) => {
        obj[c.key] = !c.hidden
      })
    }
    // console.log('columnsConfigs', columnsConfigs, obj)

    // autoSave(columnsConfigs)

    setColumnVisibility(obj)
  }, [columnsConfigs])

  const handleRowClick = useCallback((rowId) => {
    if (clickedRow === rowId) {
      setClickedRow(null)
      return
    }
    setClickedRow(rowId)
  }, [clickedRow])

  const handleFilterOpen = useCallback((column) => {
    if (filterOpen && filterOpen === column) {
      setFilterOpen(null)
      return
    }
    setFilterOpen(column)
  }, [filterOpen])

  const trows = useMemo(() => data, [data])
  const cols = useMemo(() => columns, [columns])

  const fixedLeftColumns = useMemo(() => cols.filter((col) => {
    if (col.fixed === 'left') {
      return col
    }
  })
  .map((col) => col.accessorKey), [])

  const fixedRightColumns = useMemo(() => cols.filter((col) => {
    if (col.fixed === 'right') {
      return col
    }
  })
  .map((col) => col.accessorKey), [])

  const tableInstance = useReactTable({
    data: trows,
    columns: cols,
    defaultColumn: {
      // size: "auto" as any,
      minSize: 40,
      maxSize: 800,
    },
    state: { // TODO: save table state in local storage OR within the layout
      columnOrder: columnOrder,
      columnVisibility: columnVisibility,
      columnPinning: {
        left: fixedLeftColumns,
        right: fixedRightColumns,
      },
      columnFilters: columnFilters,
      // columnSizing: columnSizingState,
    },
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    // onColumnSizingChange: setColumnSizingState,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  })

  // const { rows } = tableInstance.getRowModel()
  const visibleColumns = tableInstance.getVisibleLeafColumns()

  // The virtualizers need to know the scrollable container element
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const columnVirtualizer = useVirtualizer({
    count: visibleColumns.length,
    estimateSize: index => visibleColumns[index].getSize(), // Estimate width of each column for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    horizontal: true,
    overscan: 3,
    // debug: true,
  })

  const rowVirtualizer = useVirtualizer({
    count: trows.length,
    estimateSize: () => 22,
    getScrollElement: () => tableContainerRef.current,
    overscan: 7,
    paddingEnd: 14,
    // debug: true,
    // enabled: false,
  })

  const virtualColumns = columnVirtualizer.getVirtualItems()
  const virtualRows = rowVirtualizer.getVirtualItems()
  // const isScrolling = rowVirtualizer.isScrolling

  // useEffect(() => {
  //   console.log('isScrolling', isScrolling)
  // }, [isScrolling])

  // Different virtualization strategy for columns
  // instead of absolute and translateY, we add empty columns to the left and right
  let virtualPaddingLeft: number | undefined
  let virtualPaddingRight: number | undefined

  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[0]?.start ?? 0
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1]?.end ?? 0)
  }

  const handleDragStart = useCallback((e: BaseSyntheticEvent) => {
    e.stopPropagation()

    const headerCell = e.target.closest('.header-cell')
    if (headerCell.dataset.columnKey) {
      setMovingColumnKey(headerCell.dataset.columnKey)
    }
    // Add visual feedback
    headerCell.classList.add('dragging')
  }, [])
  
  const handleDragOver = useCallback((e: BaseSyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault() // Necessary for drop to work
    const headerCell = e.target.closest('.header-cell')
    // Add hover effect
    headerCell.classList.add('drag-over')
    // Add hover effect to all cells in the same column
    // TODO: use a more efficient way to do this
    const tableBodyCells = Array.from(document.querySelectorAll(`.${'ji-virtual-table'} .table-body .body-cell`))
    tableBodyCells.map((el: any) => {
      if (el.dataset.columnKey === headerCell.dataset.columnKey) {
        el.classList.add('drag-over')
      }
    })
  }, [])

  const handleDragLeave = useCallback((e: BaseSyntheticEvent) => {
    e.stopPropagation()
    // Remove hover effect
    const hoveredCells = Array.from(document.querySelectorAll(`.${'ji-virtual-table'} .${'drag-over'}`))
    // Cleanup hover effect for all the body cells
    hoveredCells.map((el: any) => {
      el.classList.remove('drag-over')
    })
    e.target.classList.remove('drag-over')
    e.target.classList.remove('dragging')
  }, [])
  
  const handleDrop = useCallback((e: BaseSyntheticEvent) => {
    const headerCell = e.target.closest('.header-cell')

    if (headerCell.dataset.columnKey) {
      setTargetColumnKey(headerCell.dataset.columnKey)
    }
    e.stopPropagation()
    // Remove visual feedback
    e.target.classList.remove('drag-over')
    e.target.classList.remove('dragging')
  }, [])
  
  const handleDragEnd = useCallback((e: BaseSyntheticEvent) => {
    e.stopPropagation()
    const headerCell = e.target.closest('.header-cell')

    // Reorder columns
    if (targetColumnKey && movingColumnKey !== targetColumnKey) {      
      // const reordereColumns = swapTableColumns(columnOrder, movingColumnKey, targetColumnKey)
      const reordereColumns = moveTableColumn(columnOrder, movingColumnKey, targetColumnKey)
      setColumnOrder(reordereColumns)
    }
    // Clean up visual feedback
    const hoveredCells = Array.from(document.querySelectorAll(`.${'ji-virtual-table'} .${'drag-over'}`))
    // Cleanup hover effect for all the body cells
    hoveredCells.map((el: any) => {
      el.classList.remove('drag-over')
    })
    e.target.classList.remove('dragging')
    headerCell.classList.remove('dragging')
  }, [movingColumnKey, targetColumnKey, columnOrder])

  return (
    <div
      ref={tableContainerRef}
      className={`${classname ? 'ji-virtual-table ' + classname : 'ji-virtual-table'} nonDraggable`}
      style={{
        height: `${tableHeight}px`,
        width: `${tableWidth}px`,
        overflow: 'auto',
        position: 'relative',
      }}
    >
      <div
        className={'table-header'}
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          display: 'flex',
          width: '100%',
        }}
      >
        {/* Header */}
        {tableInstance.getHeaderGroups().map((headerGroup) => {
          return (
            <div
              key={headerGroup.id}
              className={'table-row'}
              style={{
                position: 'relative',
                display: 'flex',
              }}
            >
              {/* Left Pinned Columns */}
              {/* {leftPinnedColumns.map((column) => (
                console.log('column', column),
                <div
                  key={column.id}
                  className="header-cell"
                  style={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 11,
                    width: column.size,
                    background: 'red',
                  }}
                >
                  {column.header()}
                </div>
              ))} */}
              {virtualPaddingLeft
                // Fake empty column to the left for virtualization scroll padding
                ? (<div style={{ display: 'flex', width: `${virtualPaddingLeft}px` }} />)
                : null
              }

              {virtualColumns.map(vc => {
                const header = headerGroup.headers[vc.index]
                return (
                  <div key={header.id}>
                    <HeaderRowCell
                      header={header}
                      handleDragStart={handleDragStart}
                      handleDragEnd={handleDragEnd}
                      handleDragLeave={handleDragLeave}
                      handleDragOver={handleDragOver}
                      handleDrop={handleDrop}
                      handleFilterOpen={handleFilterOpen}
                      setFilterOpen={setFilterOpen}
                      filterOpen={filterOpen}
                      tableInstance={tableInstance}
                    />
                  </div>
                )
              })}

              {virtualPaddingRight ? (
                // Fake empty column to the right for virtualization scroll padding
                <div style={{ display: 'flex', width: `${virtualPaddingRight}px` }} />
              ) : null}
            </div>
          )
        })}
      </div>

      {/* Rows */}
      <div
        className={'table-body'}
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: `${tableInstance.getTotalSize()}px`,
          position: 'relative',
          backgroundColor: '#0e1422'
        }}
      >
        {virtualRows.map((virtualRow, i) => {
          const row = tableInstance.getRowModel().rows[virtualRow.index]
          if (!row) {
            return null
          }
          const isRowClicked = clickedRow === row.id
          // Rows
          return (
            <div
              key={row.id}
              data-index={virtualRow.index} // Needed for dynamic row height measurement
              className={`table-row ${isRowClicked ? 'row-clicked' : ''}`}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
                display: 'flex',
              }}
              onClick={() => handleRowClick(row.id)}
            >
              {/* Left Pinned Cells
              {leftPinnedColumns.map((column) => (
                <div
                  key={column.id}
                  className="body-cell"
                  style={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 10,
                    width: column.size,
                    background: 'red',
                  }}
                >
                  {row.getValue(column.accessorKey)}
                </div>
              ))} */}

              {virtualPaddingLeft ? (
                // Fake empty column to the left for virtualization scroll padding
                <div
                  style={{ display: 'flex', width: `${virtualPaddingLeft}px` }}
                />
              ) : null}

              {virtualColumns.map((virtualColumn) => {
                const cell = row.getVisibleCells()[virtualColumn.index]
                const isRowClicked = clickedRow === row.id
                return (
                  <div
                    key={cell.id}
                    className={'body-cell'}
                    data-column-key={(cell.column as any).columnDef.accessorKey}
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      // width: `${virtualColumn.size}px`,
                      width: `${cell.column.getSize()}px`,
                      backgroundColor: isRowClicked ? '#2d8dd1' : cell.row.index % 2 ? '#1b2339' : '#131a2c',
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                )
              })}

              {virtualPaddingRight ? (
                // Fake empty column to the right for virtualization scroll padding
                <div
                  style={{ display: 'flex', width: `${virtualPaddingRight}px` }}
                />
              ) : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}
