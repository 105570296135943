// source: strategy_operations.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.strategies.operations.alive', null, global);
goog.exportSymbol('proto.strategies.operations.decimal', null, global);
goog.exportSymbol('proto.strategies.operations.end_snapshot', null, global);
goog.exportSymbol('proto.strategies.operations.feed_event', null, global);
goog.exportSymbol('proto.strategies.operations.feed_event.EventTypeCase', null, global);
goog.exportSymbol('proto.strategies.operations.stg_avg_princ_bc', null, global);
goog.exportSymbol('proto.strategies.operations.stg_cartesian_operations', null, global);
goog.exportSymbol('proto.strategies.operations.stg_cum_perc_ret', null, global);
goog.exportSymbol('proto.strategies.operations.stg_dt', null, global);
goog.exportSymbol('proto.strategies.operations.tstamp', null, global);
goog.exportSymbol('proto.strategies.operations.update_payload', null, global);
goog.exportSymbol('proto.strategies.operations.update_snapshot', null, global);
goog.exportSymbol('proto.strategies.operations.update_ticker', null, global);
goog.exportSymbol('proto.strategies.operations.update_type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.tstamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.tstamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.tstamp.displayName = 'proto.strategies.operations.tstamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.decimal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.decimal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.decimal.displayName = 'proto.strategies.operations.decimal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.stg_cartesian_operations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.stg_cartesian_operations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.stg_cartesian_operations.displayName = 'proto.strategies.operations.stg_cartesian_operations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.stg_cum_perc_ret = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.stg_cum_perc_ret, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.stg_cum_perc_ret.displayName = 'proto.strategies.operations.stg_cum_perc_ret';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.stg_avg_princ_bc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.stg_avg_princ_bc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.stg_avg_princ_bc.displayName = 'proto.strategies.operations.stg_avg_princ_bc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.stg_dt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.stg_dt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.stg_dt.displayName = 'proto.strategies.operations.stg_dt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.update_payload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.strategies.operations.update_payload.repeatedFields_, null);
};
goog.inherits(proto.strategies.operations.update_payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.update_payload.displayName = 'proto.strategies.operations.update_payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.update_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.update_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.update_snapshot.displayName = 'proto.strategies.operations.update_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.end_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.end_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.end_snapshot.displayName = 'proto.strategies.operations.end_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.update_ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.update_ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.update_ticker.displayName = 'proto.strategies.operations.update_ticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.alive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.strategies.operations.alive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.alive.displayName = 'proto.strategies.operations.alive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.strategies.operations.feed_event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.strategies.operations.feed_event.oneofGroups_);
};
goog.inherits(proto.strategies.operations.feed_event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.strategies.operations.feed_event.displayName = 'proto.strategies.operations.feed_event';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.tstamp.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.tstamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.tstamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.tstamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    offset: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    code: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.tstamp}
 */
proto.strategies.operations.tstamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.tstamp;
  return proto.strategies.operations.tstamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.tstamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.tstamp}
 */
proto.strategies.operations.tstamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.tstamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.tstamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.tstamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.tstamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * required sint64 time = 1;
 * @return {number}
 */
proto.strategies.operations.tstamp.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.tstamp} returns this
 */
proto.strategies.operations.tstamp.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.tstamp} returns this
 */
proto.strategies.operations.tstamp.prototype.clearTime = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.tstamp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 offset = 2;
 * @return {number}
 */
proto.strategies.operations.tstamp.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.tstamp} returns this
 */
proto.strategies.operations.tstamp.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.tstamp} returns this
 */
proto.strategies.operations.tstamp.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.tstamp.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required uint32 code = 3;
 * @return {number}
 */
proto.strategies.operations.tstamp.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.tstamp} returns this
 */
proto.strategies.operations.tstamp.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.tstamp} returns this
 */
proto.strategies.operations.tstamp.prototype.clearCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.tstamp.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.decimal.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.decimal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.decimal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.decimal.toObject = function(includeInstance, msg) {
  var f, obj = {
    coeff: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.decimal}
 */
proto.strategies.operations.decimal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.decimal;
  return proto.strategies.operations.decimal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.decimal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.decimal}
 */
proto.strategies.operations.decimal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setCoeff(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.decimal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.decimal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.decimal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.decimal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * required sint64 coeff = 1;
 * @return {number}
 */
proto.strategies.operations.decimal.prototype.getCoeff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.decimal} returns this
 */
proto.strategies.operations.decimal.prototype.setCoeff = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.decimal} returns this
 */
proto.strategies.operations.decimal.prototype.clearCoeff = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.decimal.prototype.hasCoeff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 exp = 2;
 * @return {number}
 */
proto.strategies.operations.decimal.prototype.getExp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.decimal} returns this
 */
proto.strategies.operations.decimal.prototype.setExp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.decimal} returns this
 */
proto.strategies.operations.decimal.prototype.clearExp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.decimal.prototype.hasExp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.stg_cartesian_operations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.stg_cartesian_operations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.stg_cartesian_operations.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    positiveOp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    negativeOp: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    closePositiveOp: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    openPositiveOp: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    closeNegativeOp: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    openNegativeOp: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    openOp: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    closeOp: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    totalOp: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.stg_cartesian_operations}
 */
proto.strategies.operations.stg_cartesian_operations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.stg_cartesian_operations;
  return proto.strategies.operations.stg_cartesian_operations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.stg_cartesian_operations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.stg_cartesian_operations}
 */
proto.strategies.operations.stg_cartesian_operations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveOp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeOp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClosePositiveOp(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOpenPositiveOp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCloseNegativeOp(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOpenNegativeOp(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOpenOp(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCloseOp(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.stg_cartesian_operations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.stg_cartesian_operations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.stg_cartesian_operations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 positive_op = 2;
 * @return {number}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getPositiveOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setPositiveOp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearPositiveOp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasPositiveOp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 negative_op = 3;
 * @return {number}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getNegativeOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setNegativeOp = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearNegativeOp = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasNegativeOp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 close_positive_op = 4;
 * @return {number}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getClosePositiveOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setClosePositiveOp = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearClosePositiveOp = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasClosePositiveOp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 open_positive_op = 5;
 * @return {number}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getOpenPositiveOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setOpenPositiveOp = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearOpenPositiveOp = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasOpenPositiveOp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 close_negative_op = 6;
 * @return {number}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getCloseNegativeOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setCloseNegativeOp = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearCloseNegativeOp = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasCloseNegativeOp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 open_negative_op = 7;
 * @return {number}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getOpenNegativeOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setOpenNegativeOp = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearOpenNegativeOp = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasOpenNegativeOp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 open_op = 8;
 * @return {number}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getOpenOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setOpenOp = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearOpenOp = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasOpenOp = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint32 close_op = 9;
 * @return {number}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getCloseOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setCloseOp = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearCloseOp = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasCloseOp = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional uint32 total_op = 10;
 * @return {number}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.getTotalOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.setTotalOp = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_cartesian_operations} returns this
 */
proto.strategies.operations.stg_cartesian_operations.prototype.clearTotalOp = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cartesian_operations.prototype.hasTotalOp = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.stg_cum_perc_ret.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.stg_cum_perc_ret} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.stg_cum_perc_ret.toObject = function(includeInstance, msg) {
  var f, obj = {
    tdyCumPercRet: (f = msg.getTdyCumPercRet()) && proto.strategies.operations.decimal.toObject(includeInstance, f),
    tdyCumPercRet2: (f = msg.getTdyCumPercRet2()) && proto.strategies.operations.decimal.toObject(includeInstance, f),
    ytdCumPercRet: (f = msg.getYtdCumPercRet()) && proto.strategies.operations.decimal.toObject(includeInstance, f),
    ytdCumPercRet2: (f = msg.getYtdCumPercRet2()) && proto.strategies.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.stg_cum_perc_ret}
 */
proto.strategies.operations.stg_cum_perc_ret.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.stg_cum_perc_ret;
  return proto.strategies.operations.stg_cum_perc_ret.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.stg_cum_perc_ret} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.stg_cum_perc_ret}
 */
proto.strategies.operations.stg_cum_perc_ret.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.strategies.operations.decimal;
      reader.readMessage(value,proto.strategies.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyCumPercRet(value);
      break;
    case 2:
      var value = new proto.strategies.operations.decimal;
      reader.readMessage(value,proto.strategies.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyCumPercRet2(value);
      break;
    case 3:
      var value = new proto.strategies.operations.decimal;
      reader.readMessage(value,proto.strategies.operations.decimal.deserializeBinaryFromReader);
      msg.setYtdCumPercRet(value);
      break;
    case 4:
      var value = new proto.strategies.operations.decimal;
      reader.readMessage(value,proto.strategies.operations.decimal.deserializeBinaryFromReader);
      msg.setYtdCumPercRet2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.stg_cum_perc_ret.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.stg_cum_perc_ret} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.stg_cum_perc_ret.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTdyCumPercRet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.strategies.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyCumPercRet2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.strategies.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdCumPercRet();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.strategies.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdCumPercRet2();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.strategies.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal tdy_cum_perc_ret = 1;
 * @return {?proto.strategies.operations.decimal}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.getTdyCumPercRet = function() {
  return /** @type{?proto.strategies.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.decimal, 1));
};


/**
 * @param {?proto.strategies.operations.decimal|undefined} value
 * @return {!proto.strategies.operations.stg_cum_perc_ret} returns this
*/
proto.strategies.operations.stg_cum_perc_ret.prototype.setTdyCumPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.stg_cum_perc_ret} returns this
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.clearTdyCumPercRet = function() {
  return this.setTdyCumPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.hasTdyCumPercRet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal tdy_cum_perc_ret2 = 2;
 * @return {?proto.strategies.operations.decimal}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.getTdyCumPercRet2 = function() {
  return /** @type{?proto.strategies.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.decimal, 2));
};


/**
 * @param {?proto.strategies.operations.decimal|undefined} value
 * @return {!proto.strategies.operations.stg_cum_perc_ret} returns this
*/
proto.strategies.operations.stg_cum_perc_ret.prototype.setTdyCumPercRet2 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.stg_cum_perc_ret} returns this
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.clearTdyCumPercRet2 = function() {
  return this.setTdyCumPercRet2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.hasTdyCumPercRet2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal ytd_cum_perc_ret = 3;
 * @return {?proto.strategies.operations.decimal}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.getYtdCumPercRet = function() {
  return /** @type{?proto.strategies.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.decimal, 3));
};


/**
 * @param {?proto.strategies.operations.decimal|undefined} value
 * @return {!proto.strategies.operations.stg_cum_perc_ret} returns this
*/
proto.strategies.operations.stg_cum_perc_ret.prototype.setYtdCumPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.stg_cum_perc_ret} returns this
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.clearYtdCumPercRet = function() {
  return this.setYtdCumPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.hasYtdCumPercRet = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal ytd_cum_perc_ret2 = 4;
 * @return {?proto.strategies.operations.decimal}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.getYtdCumPercRet2 = function() {
  return /** @type{?proto.strategies.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.decimal, 4));
};


/**
 * @param {?proto.strategies.operations.decimal|undefined} value
 * @return {!proto.strategies.operations.stg_cum_perc_ret} returns this
*/
proto.strategies.operations.stg_cum_perc_ret.prototype.setYtdCumPercRet2 = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.stg_cum_perc_ret} returns this
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.clearYtdCumPercRet2 = function() {
  return this.setYtdCumPercRet2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_cum_perc_ret.prototype.hasYtdCumPercRet2 = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.stg_avg_princ_bc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.stg_avg_princ_bc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.stg_avg_princ_bc.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    avgPrincBc: (f = msg.getAvgPrincBc()) && proto.strategies.operations.decimal.toObject(includeInstance, f),
    cumPrincWdt: (f = msg.getCumPrincWdt()) && proto.strategies.operations.decimal.toObject(includeInstance, f),
    dt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.stg_avg_princ_bc}
 */
proto.strategies.operations.stg_avg_princ_bc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.stg_avg_princ_bc;
  return proto.strategies.operations.stg_avg_princ_bc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.stg_avg_princ_bc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.stg_avg_princ_bc}
 */
proto.strategies.operations.stg_avg_princ_bc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.strategies.operations.decimal;
      reader.readMessage(value,proto.strategies.operations.decimal.deserializeBinaryFromReader);
      msg.setAvgPrincBc(value);
      break;
    case 3:
      var value = new proto.strategies.operations.decimal;
      reader.readMessage(value,proto.strategies.operations.decimal.deserializeBinaryFromReader);
      msg.setCumPrincWdt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.stg_avg_princ_bc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.stg_avg_princ_bc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.stg_avg_princ_bc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvgPrincBc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.strategies.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getCumPrincWdt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.strategies.operations.decimal.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.strategies.operations.stg_avg_princ_bc} returns this
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_avg_princ_bc} returns this
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal avg_princ_bc = 2;
 * @return {?proto.strategies.operations.decimal}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.getAvgPrincBc = function() {
  return /** @type{?proto.strategies.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.decimal, 2));
};


/**
 * @param {?proto.strategies.operations.decimal|undefined} value
 * @return {!proto.strategies.operations.stg_avg_princ_bc} returns this
*/
proto.strategies.operations.stg_avg_princ_bc.prototype.setAvgPrincBc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.stg_avg_princ_bc} returns this
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.clearAvgPrincBc = function() {
  return this.setAvgPrincBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.hasAvgPrincBc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal cum_princ_wdt = 3;
 * @return {?proto.strategies.operations.decimal}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.getCumPrincWdt = function() {
  return /** @type{?proto.strategies.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.decimal, 3));
};


/**
 * @param {?proto.strategies.operations.decimal|undefined} value
 * @return {!proto.strategies.operations.stg_avg_princ_bc} returns this
*/
proto.strategies.operations.stg_avg_princ_bc.prototype.setCumPrincWdt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.stg_avg_princ_bc} returns this
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.clearCumPrincWdt = function() {
  return this.setCumPrincWdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.hasCumPrincWdt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 dt = 4;
 * @return {number}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.getDt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_avg_princ_bc} returns this
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.setDt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_avg_princ_bc} returns this
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.clearDt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_avg_princ_bc.prototype.hasDt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.stg_dt.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.stg_dt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.stg_dt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.stg_dt.toObject = function(includeInstance, msg) {
  var f, obj = {
    dtOpen: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    dtClose: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    dtEnd: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    cumPrincWdtEnd: (f = msg.getCumPrincWdtEnd()) && proto.strategies.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.stg_dt}
 */
proto.strategies.operations.stg_dt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.stg_dt;
  return proto.strategies.operations.stg_dt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.stg_dt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.stg_dt}
 */
proto.strategies.operations.stg_dt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDtOpen(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDtClose(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDtEnd(value);
      break;
    case 4:
      var value = new proto.strategies.operations.decimal;
      reader.readMessage(value,proto.strategies.operations.decimal.deserializeBinaryFromReader);
      msg.setCumPrincWdtEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.stg_dt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.stg_dt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.stg_dt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.stg_dt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCumPrincWdtEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.strategies.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 dt_open = 1;
 * @return {number}
 */
proto.strategies.operations.stg_dt.prototype.getDtOpen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_dt} returns this
 */
proto.strategies.operations.stg_dt.prototype.setDtOpen = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_dt} returns this
 */
proto.strategies.operations.stg_dt.prototype.clearDtOpen = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_dt.prototype.hasDtOpen = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 dt_close = 2;
 * @return {number}
 */
proto.strategies.operations.stg_dt.prototype.getDtClose = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_dt} returns this
 */
proto.strategies.operations.stg_dt.prototype.setDtClose = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_dt} returns this
 */
proto.strategies.operations.stg_dt.prototype.clearDtClose = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_dt.prototype.hasDtClose = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 dt_end = 3;
 * @return {number}
 */
proto.strategies.operations.stg_dt.prototype.getDtEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.stg_dt} returns this
 */
proto.strategies.operations.stg_dt.prototype.setDtEnd = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.stg_dt} returns this
 */
proto.strategies.operations.stg_dt.prototype.clearDtEnd = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_dt.prototype.hasDtEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal cum_princ_wdt_end = 4;
 * @return {?proto.strategies.operations.decimal}
 */
proto.strategies.operations.stg_dt.prototype.getCumPrincWdtEnd = function() {
  return /** @type{?proto.strategies.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.decimal, 4));
};


/**
 * @param {?proto.strategies.operations.decimal|undefined} value
 * @return {!proto.strategies.operations.stg_dt} returns this
*/
proto.strategies.operations.stg_dt.prototype.setCumPrincWdtEnd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.stg_dt} returns this
 */
proto.strategies.operations.stg_dt.prototype.clearCumPrincWdtEnd = function() {
  return this.setCumPrincWdtEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.stg_dt.prototype.hasCumPrincWdtEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.strategies.operations.update_payload.repeatedFields_ = [77,79];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.update_payload.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.update_payload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.update_payload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.update_payload.toObject = function(includeInstance, msg) {
  var f, obj = {
    stgCartesianOperationsList: jspb.Message.toObjectList(msg.getStgCartesianOperationsList(),
    proto.strategies.operations.stg_cartesian_operations.toObject, includeInstance),
    stgCumPercRet: (f = msg.getStgCumPercRet()) && proto.strategies.operations.stg_cum_perc_ret.toObject(includeInstance, f),
    stgAvgPrincBcList: jspb.Message.toObjectList(msg.getStgAvgPrincBcList(),
    proto.strategies.operations.stg_avg_princ_bc.toObject, includeInstance),
    stgDt: (f = msg.getStgDt()) && proto.strategies.operations.stg_dt.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.update_payload}
 */
proto.strategies.operations.update_payload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.update_payload;
  return proto.strategies.operations.update_payload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.update_payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.update_payload}
 */
proto.strategies.operations.update_payload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 77:
      var value = new proto.strategies.operations.stg_cartesian_operations;
      reader.readMessage(value,proto.strategies.operations.stg_cartesian_operations.deserializeBinaryFromReader);
      msg.addStgCartesianOperations(value);
      break;
    case 78:
      var value = new proto.strategies.operations.stg_cum_perc_ret;
      reader.readMessage(value,proto.strategies.operations.stg_cum_perc_ret.deserializeBinaryFromReader);
      msg.setStgCumPercRet(value);
      break;
    case 79:
      var value = new proto.strategies.operations.stg_avg_princ_bc;
      reader.readMessage(value,proto.strategies.operations.stg_avg_princ_bc.deserializeBinaryFromReader);
      msg.addStgAvgPrincBc(value);
      break;
    case 80:
      var value = new proto.strategies.operations.stg_dt;
      reader.readMessage(value,proto.strategies.operations.stg_dt.deserializeBinaryFromReader);
      msg.setStgDt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.update_payload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.update_payload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.update_payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.update_payload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStgCartesianOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      77,
      f,
      proto.strategies.operations.stg_cartesian_operations.serializeBinaryToWriter
    );
  }
  f = message.getStgCumPercRet();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto.strategies.operations.stg_cum_perc_ret.serializeBinaryToWriter
    );
  }
  f = message.getStgAvgPrincBcList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      79,
      f,
      proto.strategies.operations.stg_avg_princ_bc.serializeBinaryToWriter
    );
  }
  f = message.getStgDt();
  if (f != null) {
    writer.writeMessage(
      80,
      f,
      proto.strategies.operations.stg_dt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated stg_cartesian_operations stg_cartesian_operations_ = 77;
 * @return {!Array<!proto.strategies.operations.stg_cartesian_operations>}
 */
proto.strategies.operations.update_payload.prototype.getStgCartesianOperationsList = function() {
  return /** @type{!Array<!proto.strategies.operations.stg_cartesian_operations>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.strategies.operations.stg_cartesian_operations, 77));
};


/**
 * @param {!Array<!proto.strategies.operations.stg_cartesian_operations>} value
 * @return {!proto.strategies.operations.update_payload} returns this
*/
proto.strategies.operations.update_payload.prototype.setStgCartesianOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 77, value);
};


/**
 * @param {!proto.strategies.operations.stg_cartesian_operations=} opt_value
 * @param {number=} opt_index
 * @return {!proto.strategies.operations.stg_cartesian_operations}
 */
proto.strategies.operations.update_payload.prototype.addStgCartesianOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 77, opt_value, proto.strategies.operations.stg_cartesian_operations, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.strategies.operations.update_payload} returns this
 */
proto.strategies.operations.update_payload.prototype.clearStgCartesianOperationsList = function() {
  return this.setStgCartesianOperationsList([]);
};


/**
 * optional stg_cum_perc_ret stg_cum_perc_ret_ = 78;
 * @return {?proto.strategies.operations.stg_cum_perc_ret}
 */
proto.strategies.operations.update_payload.prototype.getStgCumPercRet = function() {
  return /** @type{?proto.strategies.operations.stg_cum_perc_ret} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.stg_cum_perc_ret, 78));
};


/**
 * @param {?proto.strategies.operations.stg_cum_perc_ret|undefined} value
 * @return {!proto.strategies.operations.update_payload} returns this
*/
proto.strategies.operations.update_payload.prototype.setStgCumPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.update_payload} returns this
 */
proto.strategies.operations.update_payload.prototype.clearStgCumPercRet = function() {
  return this.setStgCumPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_payload.prototype.hasStgCumPercRet = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * repeated stg_avg_princ_bc stg_avg_princ_bc_ = 79;
 * @return {!Array<!proto.strategies.operations.stg_avg_princ_bc>}
 */
proto.strategies.operations.update_payload.prototype.getStgAvgPrincBcList = function() {
  return /** @type{!Array<!proto.strategies.operations.stg_avg_princ_bc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.strategies.operations.stg_avg_princ_bc, 79));
};


/**
 * @param {!Array<!proto.strategies.operations.stg_avg_princ_bc>} value
 * @return {!proto.strategies.operations.update_payload} returns this
*/
proto.strategies.operations.update_payload.prototype.setStgAvgPrincBcList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 79, value);
};


/**
 * @param {!proto.strategies.operations.stg_avg_princ_bc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.strategies.operations.stg_avg_princ_bc}
 */
proto.strategies.operations.update_payload.prototype.addStgAvgPrincBc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 79, opt_value, proto.strategies.operations.stg_avg_princ_bc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.strategies.operations.update_payload} returns this
 */
proto.strategies.operations.update_payload.prototype.clearStgAvgPrincBcList = function() {
  return this.setStgAvgPrincBcList([]);
};


/**
 * optional stg_dt stg_dt_ = 80;
 * @return {?proto.strategies.operations.stg_dt}
 */
proto.strategies.operations.update_payload.prototype.getStgDt = function() {
  return /** @type{?proto.strategies.operations.stg_dt} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.stg_dt, 80));
};


/**
 * @param {?proto.strategies.operations.stg_dt|undefined} value
 * @return {!proto.strategies.operations.update_payload} returns this
*/
proto.strategies.operations.update_payload.prototype.setStgDt = function(value) {
  return jspb.Message.setWrapperField(this, 80, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.update_payload} returns this
 */
proto.strategies.operations.update_payload.prototype.clearStgDt = function() {
  return this.setStgDt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_payload.prototype.hasStgDt = function() {
  return jspb.Message.getField(this, 80) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.update_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.update_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.update_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.update_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.strategies.operations.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.strategies.operations.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.update_snapshot}
 */
proto.strategies.operations.update_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.update_snapshot;
  return proto.strategies.operations.update_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.update_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.update_snapshot}
 */
proto.strategies.operations.update_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.strategies.operations.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 5:
      var value = new proto.strategies.operations.tstamp;
      reader.readMessage(value,proto.strategies.operations.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 6:
      var value = new proto.strategies.operations.update_payload;
      reader.readMessage(value,proto.strategies.operations.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.update_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.update_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.update_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.update_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.strategies.operations.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.strategies.operations.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.strategies.operations.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.strategies.operations.update_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.strategies.operations.update_type}
 */
proto.strategies.operations.update_snapshot.prototype.getUpdateType = function() {
  return /** @type {!proto.strategies.operations.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.strategies.operations.update_type} value
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_snapshot.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.strategies.operations.update_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required string key = 4;
 * @return {string}
 */
proto.strategies.operations.update_snapshot.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.clearKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_snapshot.prototype.hasKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required tstamp event_ts = 5;
 * @return {!proto.strategies.operations.tstamp}
 */
proto.strategies.operations.update_snapshot.prototype.getEventTs = function() {
  return /** @type{!proto.strategies.operations.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.tstamp, 5, 1));
};


/**
 * @param {!proto.strategies.operations.tstamp} value
 * @return {!proto.strategies.operations.update_snapshot} returns this
*/
proto.strategies.operations.update_snapshot.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_snapshot.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional update_payload sigmas = 6;
 * @return {?proto.strategies.operations.update_payload}
 */
proto.strategies.operations.update_snapshot.prototype.getSigmas = function() {
  return /** @type{?proto.strategies.operations.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.update_payload, 6));
};


/**
 * @param {?proto.strategies.operations.update_payload|undefined} value
 * @return {!proto.strategies.operations.update_snapshot} returns this
*/
proto.strategies.operations.update_snapshot.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.update_snapshot} returns this
 */
proto.strategies.operations.update_snapshot.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_snapshot.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.end_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.end_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.end_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.end_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.end_snapshot}
 */
proto.strategies.operations.end_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.end_snapshot;
  return proto.strategies.operations.end_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.end_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.end_snapshot}
 */
proto.strategies.operations.end_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.end_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.end_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.end_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.end_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.strategies.operations.end_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.end_snapshot} returns this
 */
proto.strategies.operations.end_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.end_snapshot} returns this
 */
proto.strategies.operations.end_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.end_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string service = 2;
 * @return {string}
 */
proto.strategies.operations.end_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.strategies.operations.end_snapshot} returns this
 */
proto.strategies.operations.end_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.end_snapshot} returns this
 */
proto.strategies.operations.end_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.end_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.update_ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.update_ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.update_ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.update_ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    sequence: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.strategies.operations.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.strategies.operations.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.update_ticker}
 */
proto.strategies.operations.update_ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.update_ticker;
  return proto.strategies.operations.update_ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.update_ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.update_ticker}
 */
proto.strategies.operations.update_ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.strategies.operations.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSequence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = new proto.strategies.operations.tstamp;
      reader.readMessage(value,proto.strategies.operations.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 7:
      var value = new proto.strategies.operations.update_payload;
      reader.readMessage(value,proto.strategies.operations.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.update_ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.update_ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.update_ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.update_ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.strategies.operations.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.strategies.operations.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.strategies.operations.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.strategies.operations.update_ticker.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_ticker.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.strategies.operations.update_type}
 */
proto.strategies.operations.update_ticker.prototype.getUpdateType = function() {
  return /** @type {!proto.strategies.operations.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.strategies.operations.update_type} value
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_ticker.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.strategies.operations.update_ticker.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_ticker.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required sint64 sequence = 4;
 * @return {number}
 */
proto.strategies.operations.update_ticker.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.setSequence = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.clearSequence = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_ticker.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required string key = 5;
 * @return {string}
 */
proto.strategies.operations.update_ticker.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.clearKey = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_ticker.prototype.hasKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp event_ts = 6;
 * @return {!proto.strategies.operations.tstamp}
 */
proto.strategies.operations.update_ticker.prototype.getEventTs = function() {
  return /** @type{!proto.strategies.operations.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.tstamp, 6, 1));
};


/**
 * @param {!proto.strategies.operations.tstamp} value
 * @return {!proto.strategies.operations.update_ticker} returns this
*/
proto.strategies.operations.update_ticker.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_ticker.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional update_payload sigmas = 7;
 * @return {?proto.strategies.operations.update_payload}
 */
proto.strategies.operations.update_ticker.prototype.getSigmas = function() {
  return /** @type{?proto.strategies.operations.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.update_payload, 7));
};


/**
 * @param {?proto.strategies.operations.update_payload|undefined} value
 * @return {!proto.strategies.operations.update_ticker} returns this
*/
proto.strategies.operations.update_ticker.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.update_ticker} returns this
 */
proto.strategies.operations.update_ticker.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.update_ticker.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.alive.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.alive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.alive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.alive.toObject = function(includeInstance, msg) {
  var f, obj = {
    aliveId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.alive}
 */
proto.strategies.operations.alive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.alive;
  return proto.strategies.operations.alive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.alive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.alive}
 */
proto.strategies.operations.alive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setAliveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.alive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.alive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.alive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.alive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
};


/**
 * required sint64 alive_id = 1;
 * @return {number}
 */
proto.strategies.operations.alive.prototype.getAliveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.strategies.operations.alive} returns this
 */
proto.strategies.operations.alive.prototype.setAliveId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.strategies.operations.alive} returns this
 */
proto.strategies.operations.alive.prototype.clearAliveId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.alive.prototype.hasAliveId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.strategies.operations.feed_event.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.strategies.operations.feed_event.EventTypeCase = {
  EVENT_TYPE_NOT_SET: 0,
  SNAPSHOT: 1,
  TICKER: 2,
  END_SNAPSHOT: 3,
  ALIVE: 4
};

/**
 * @return {proto.strategies.operations.feed_event.EventTypeCase}
 */
proto.strategies.operations.feed_event.prototype.getEventTypeCase = function() {
  return /** @type {proto.strategies.operations.feed_event.EventTypeCase} */(jspb.Message.computeOneofCase(this, proto.strategies.operations.feed_event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.strategies.operations.feed_event.prototype.toObject = function(opt_includeInstance) {
  return proto.strategies.operations.feed_event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.strategies.operations.feed_event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.feed_event.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.strategies.operations.update_snapshot.toObject(includeInstance, f),
    ticker: (f = msg.getTicker()) && proto.strategies.operations.update_ticker.toObject(includeInstance, f),
    endSnapshot: (f = msg.getEndSnapshot()) && proto.strategies.operations.end_snapshot.toObject(includeInstance, f),
    alive: (f = msg.getAlive()) && proto.strategies.operations.alive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.strategies.operations.feed_event}
 */
proto.strategies.operations.feed_event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.strategies.operations.feed_event;
  return proto.strategies.operations.feed_event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.strategies.operations.feed_event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.strategies.operations.feed_event}
 */
proto.strategies.operations.feed_event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.strategies.operations.update_snapshot;
      reader.readMessage(value,proto.strategies.operations.update_snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 2:
      var value = new proto.strategies.operations.update_ticker;
      reader.readMessage(value,proto.strategies.operations.update_ticker.deserializeBinaryFromReader);
      msg.setTicker(value);
      break;
    case 3:
      var value = new proto.strategies.operations.end_snapshot;
      reader.readMessage(value,proto.strategies.operations.end_snapshot.deserializeBinaryFromReader);
      msg.setEndSnapshot(value);
      break;
    case 4:
      var value = new proto.strategies.operations.alive;
      reader.readMessage(value,proto.strategies.operations.alive.deserializeBinaryFromReader);
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.strategies.operations.feed_event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.strategies.operations.feed_event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.strategies.operations.feed_event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.strategies.operations.feed_event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.strategies.operations.update_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getTicker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.strategies.operations.update_ticker.serializeBinaryToWriter
    );
  }
  f = message.getEndSnapshot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.strategies.operations.end_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.strategies.operations.alive.serializeBinaryToWriter
    );
  }
};


/**
 * optional update_snapshot snapshot = 1;
 * @return {?proto.strategies.operations.update_snapshot}
 */
proto.strategies.operations.feed_event.prototype.getSnapshot = function() {
  return /** @type{?proto.strategies.operations.update_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.update_snapshot, 1));
};


/**
 * @param {?proto.strategies.operations.update_snapshot|undefined} value
 * @return {!proto.strategies.operations.feed_event} returns this
*/
proto.strategies.operations.feed_event.prototype.setSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.strategies.operations.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.feed_event} returns this
 */
proto.strategies.operations.feed_event.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.feed_event.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional update_ticker ticker = 2;
 * @return {?proto.strategies.operations.update_ticker}
 */
proto.strategies.operations.feed_event.prototype.getTicker = function() {
  return /** @type{?proto.strategies.operations.update_ticker} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.update_ticker, 2));
};


/**
 * @param {?proto.strategies.operations.update_ticker|undefined} value
 * @return {!proto.strategies.operations.feed_event} returns this
*/
proto.strategies.operations.feed_event.prototype.setTicker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.strategies.operations.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.feed_event} returns this
 */
proto.strategies.operations.feed_event.prototype.clearTicker = function() {
  return this.setTicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.feed_event.prototype.hasTicker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional end_snapshot end_snapshot = 3;
 * @return {?proto.strategies.operations.end_snapshot}
 */
proto.strategies.operations.feed_event.prototype.getEndSnapshot = function() {
  return /** @type{?proto.strategies.operations.end_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.end_snapshot, 3));
};


/**
 * @param {?proto.strategies.operations.end_snapshot|undefined} value
 * @return {!proto.strategies.operations.feed_event} returns this
*/
proto.strategies.operations.feed_event.prototype.setEndSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.strategies.operations.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.feed_event} returns this
 */
proto.strategies.operations.feed_event.prototype.clearEndSnapshot = function() {
  return this.setEndSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.feed_event.prototype.hasEndSnapshot = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional alive alive = 4;
 * @return {?proto.strategies.operations.alive}
 */
proto.strategies.operations.feed_event.prototype.getAlive = function() {
  return /** @type{?proto.strategies.operations.alive} */ (
    jspb.Message.getWrapperField(this, proto.strategies.operations.alive, 4));
};


/**
 * @param {?proto.strategies.operations.alive|undefined} value
 * @return {!proto.strategies.operations.feed_event} returns this
*/
proto.strategies.operations.feed_event.prototype.setAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.strategies.operations.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.strategies.operations.feed_event} returns this
 */
proto.strategies.operations.feed_event.prototype.clearAlive = function() {
  return this.setAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.strategies.operations.feed_event.prototype.hasAlive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.strategies.operations.update_type = {
  SNAPSHOT: 1,
  VARIATIONS: 2
};

goog.object.extend(exports, proto.strategies.operations);
