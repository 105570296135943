import { SessionType } from 'types/enums'
import { Feeds } from 'types/enums.api'

const homepage = process.env.REACT_APP_HOMEPAGE

export const apiProvider = function provider(
  baseUrl: string,
  socketBaseUrl: string,
  timeSeriesBaseUrl: string,
) {
  let language: string = null
  const mockUrl = '/jagged-island/mocks'

  return {
    socket: {
      assets: (sessionId: number, portfolioId: number): string =>
        `${socketBaseUrl}/operative-session/${sessionId}/portfolio/${portfolioId}/ws`,
      strategies: (sessionId: number, strategyId: number) =>
        `${socketBaseUrl}/operative-session/${sessionId}/strategy/${strategyId}/ws`,
      trace: (assetId: number, type: string, sessionId: number, modelType: string): string =>
        `${socketBaseUrl}/operative-session/${type}/${sessionId}/model/${modelType}/${assetId}/trace`,
      timeSeries: (
        feed: Feeds,
        entity: number,
        sigma: string,
        start: string,
        secondsInterval: number,
        to: string,
        sessionId: number,
        tsUrl?: string,
      ) =>
        `${tsUrl ?? timeSeriesBaseUrl}/service/${feed}/entity/${entity}/sigma/${sigma}/op_session_id/${sessionId}/time-series?start=${start}${
          secondsInterval ? `&seconds-interval=${secondsInterval}` : ''
        }&end=${to}`,
    },
    api: {
      //Vocabulary
      vocabulary: () => `${homepage}/translations/{{lng}}/labels.json`,
      downloadLabelsCSV: () => `${baseUrl}/api/config/label/csv/download`,
      uploadLabelsCSV: () => `${baseUrl}/api/config/label/csv/addupdate`,
      getVersion: () => `${homepage}/version_info.xml`,

      getGraphQl: () => `${baseUrl}/v1/graphql`,
      //strategy
      updateStrategy: (strategyId: number) => `${baseUrl}/api/strategies/strategy/update/${strategyId}`,
      createStrategy: () => `${baseUrl}/api/strategies/strategy/add`,
      deleteStrategy: (strategyId: number) => `${baseUrl}/api/strategies/strategy/delete/${strategyId}`,
      //portfolio
      updatePortfolio: (portfolioId: number) => `${baseUrl}/api/strategies/portfolio/update/${portfolioId}`,
      deletePortfolio: (portfolioId: number) => `${baseUrl}/api/strategies/portfolio/delete/${portfolioId}`,
      createPortfolio: () => `${baseUrl}/api/strategies/portfolio/add`,

      //session
      sessionAssets: (sessionId: number) => `${baseUrl}/api/sessions/${sessionId}/asset`,
      sessionAssetsChangeStatus: (sessionId: number) => `${baseUrl}/api/sessions/${sessionId}/asset/status`,
      sessionDelete: (sessionId: number) => `${baseUrl}/api/sessions/deleteall/${sessionId}`,
      cleanUpSession: (sessionId: number) => `${baseUrl}/api/sessions/cleanup/${sessionId}`,
      enabledSessionTypes: () => `${baseUrl}/orchestrator/config`,

      //operative session
      layout: () => `${baseUrl}/api/config/layout`,
      backtestRun: (sessionId: number) => `${baseUrl}/api/launch/backtest/run/${sessionId}`,
      runVirtualSession: (sessionId: number) => `${baseUrl}/api/launch/virtual/run/${sessionId}`,
      backtestStandby: (sessionId: number) => `${baseUrl}/api/launch/backtest/standby/${sessionId}`,
      backtestStop: (sessionId: number) => `${baseUrl}/api/launch/backtest/stop/${sessionId}`,
      stopVirtualSession: (sessionId: number) => `${baseUrl}/api/launch/virtual/stop/${sessionId}`,
      sessionUpdate: (id: number, type: SessionType) => `${baseUrl}/api/launch/${type}/update/${id}`,
      brokenEntities: (id: number) => `${baseUrl}/api/status/broken-entity/${id}`,
      sessionMetricsSocket: (id: number) => `${socketBaseUrl}/ws/metrics/session/${id}`,
      getSessionReport: (type: SessionType) => `${baseUrl}/report/${type}/export`,

      //assets
      createAsset: () => `${baseUrl}/api/strategies/asset/add`,
      createAssetCsv: () => `${baseUrl}/api/strategies/asset/csv/add`,
      updateAsset: (assetId: number) => `${baseUrl}/api/strategies/asset/update/${assetId}`,
      deleteAsset: (assetId: number) => `${baseUrl}/api/strategies/asset/delete/${assetId}`,

      // groups
      createGroup: () => `${baseUrl}/api/strategies/asset/group`,
      updateGroup: (groupId: number) => `${baseUrl}/api/strategies/asset/group/${groupId}`,
      deleteGroup: (groupId: number) => `${baseUrl}/api/strategies/asset/group/${groupId}`,
      listParamsToOverride: (groupId: number) => `${baseUrl}/api/strategies/asset/group/${groupId}/custom-parameters`,

      //user api
      login: () => `${baseUrl}/user/auth/login`,
      getUsers: () => `${baseUrl}/user`,
      createUser: () => `${baseUrl}/user/create`,
      user: (id: number) => `${baseUrl}/user/${id}`,
      getAuthenticatedUserDetails: () => `${baseUrl}/user/auth/introspect`,
      changePassword: (id: number) => `${baseUrl}/user/${id}/password`,
      resetPassword: () => `${baseUrl}/user/password/reset`,
      resetPasswordStep2: () => `${baseUrl}/user/password/reset/update`,
      refreshToken: () => `${baseUrl}/user/auth/token/refresh`,
      resetPasswordChange: () => `${baseUrl}/user/password/change`,

      // historical sessions statistics
      historicalDistributionStatistics: () => `${baseUrl}/report/data/statistics/distribution`,
      historicalIndicatorsStatistics: () => `${baseUrl}/report/data/statistics/indicators`,
      generateBackTestReport: () => `${baseUrl}/report/backtest/export`,
      summaryPageLink: (sessionId: number) => `${baseUrl}/report/backtest/${sessionId}/summary`,
      operationsPageLink: (sessionId: number) => `${baseUrl}/report/backtest/${sessionId}/operations`,

      //data api
      getBubbleView: () => `${mockUrl}/uk.json`,
      getCandleData: () => `${mockUrl}/aapl-2.json`,
      getHistogramData: () => `${mockUrl}/histogram.json`,
      getMasterSecurities: () => `${mockUrl}/masterSecurities.json`,
      getExchanges: () => `${mockUrl}/exchanges.json`,
      getModelClasses: () => `${mockUrl}/modelClass.json`,
      getMockUsers: () => `${mockUrl}/users.json`,

      //barChart candleStick
      getOperationsBarchartIndicators: (
        assetId: number,
        sessionId: number,
        startIso: string,
        endIso: string,
      ) =>
        `${baseUrl}/service/assets/entity/${assetId}/sigma/asset_tdy_op_indicators/op_session_id/${sessionId}/time-series?start=${startIso}&end=${endIso}`,
      getOperationsBarchart: (assetId: number, sessionId: number, startIso: string, endIso: string) =>
        `${baseUrl}/service/assets/entity/${assetId}/sigma/asset_num_tdy_op/op_session_id/${sessionId}/time-series?start=${startIso}&end=${endIso}`,

      // metrics
      getAssetsMetrics: (session: number, interval: number, startTime: string, endTime: string) =>
        `${baseUrl}/metrics-data/assets?start=${startTime}&end=${endTime}&seconds-interval=${interval}&op_session_id=${session}`,
      getSessionMetrics: (session: number, interval: number, startTime: string, endTime: string) =>
        `${baseUrl}/metrics-data/components?start=${startTime}&end=${endTime}&seconds-interval=${interval}&op_session_id=${session}`,

      getAssetsMetricsRealtime: (session: number) =>
        `${socketBaseUrl}/realtime-metrics-data/operative-session/${session}/analysis-model`,
      getSessionMetricsRealtime: (session: number) =>
        `${socketBaseUrl}/realtime-metrics-data/operative-session/${session}`,
      getBroken: (session: number) => `${socketBaseUrl}/ws/metrics/entity/${session}`,
    },
    getLanguage: () => language,
    setLanguage: (lng: string): void => {
      language = lng
    },
  }
}
