import React, { useEffect, useRef, useState } from 'react'
import Debug from 'debug'
import AssetForm from 'components/StrategyViewer/forms/AssetForm'
import { useDispatch, useSelector } from 'react-redux'
import { selectAssetsState } from 'store/resources/selectors'
import { CrudOperation, LoadingState } from 'types/enums'
import * as Api from 'types/api'
import { createAsset, updateAsset } from 'store/resources/assets/actions'
import { jsonToAssets, mapToAssetRest } from 'services/store/mapService'
import * as Store from 'types/store'
import { getAsset } from 'api/assets'

const debug = Debug('Frontend')

interface Params {
  portfolioId: number
  asset: Store.Asset
  operation: CrudOperation
  onClose: (asset: Store.Asset) => void
}

const AssetFormContainer: React.FC<Params> = ({ portfolioId, asset, operation, onClose }) => {
  const dispatch = useDispatch()
  const loadingState = useSelector(selectAssetsState)
  const [updatedAsset, setUpdatedAsset] = useState<Store.Asset>(null)
  const isMounted = useRef(false)
  const isLoading = operation === CrudOperation.Update ? LoadingState.Updating : LoadingState.Creating

  const closeEdit = () => onClose(updatedAsset ?? asset)

  useEffect(() => {
    isMounted.current = true
    // Fetch new asset data to pass onClose
    if (!asset) {
      return
    }
    const updateAsset = async () => {
      const res = await getAsset(asset.id)
      const json = ((res as Api.AssetsResponse).data.application_asset)
      const assets = jsonToAssets(json)
      setUpdatedAsset(assets[0])
    }
    
    if ([LoadingState.Created, LoadingState.Updated].includes(loadingState)) {
      updateAsset()
    }

    return () => {
      isMounted.current = false
    }
  }, [loadingState, asset])

  useEffect(() => {
    if (updatedAsset) {
      closeEdit()
    }
  }, [updatedAsset])

  const onSave = (data: Store.AssetForm) => {
    const mappedData = mapToAssetRest(data, portfolioId)

    if (operation === CrudOperation.Create) {
      dispatch(createAsset(mappedData))
    } else {
      mappedData.id = asset.id
      dispatch(updateAsset(mappedData))
    }

    closeEdit()
  }

  return (
    <AssetForm
      asset={asset}
      loading={loadingState === isLoading}
      onFinish={onSave}
      onClose={closeEdit}
      mode={operation}
    />
  )
}

export default AssetFormContainer
