import * as Api from 'types/api'
import * as Store from 'types/store'

export function jsonToPortfolioTable(json: Api.HistoricalPortfolioTable[]): Store.PortfolioTable[] {
  return json.map((table) => ({
    assetsNum: table.assets_num,
    baseCurrency: table.base_currency,
    portfolioName: table.portfolio_name,
    strategyName: table.strategy_name,
    tdyAllRetBc: table.all_ret_bc_tdy,
    tdyExchRetBc: table.exch_ret_bc_tdy,
    tdyNegativeOp: table.negative_op_tdy,
    tdyPositiveOp: table.positive_op_tdy,
    tdyTotalOp: table.total_op_tdy,
    tdyTradingRetBc: table.trading_ret_bc_tdy,
    value: table.countervalue_bc,
    valueLong: table.countervalue_bc_long,
    valueShort: table.countervalue_bc_short,
    ytdAllRetBc: table.all_ret_bc_ytd,
    ytdExchRetBc: table.exch_ret_bc_ytd,
    ytdNegativeOp: table.negative_op_ytd,
    ytdPositiveOp: table.positive_op_ytd,
    ytdTotalOp: table.total_op_ytd,
    ytdTradingRetBc: table.trading_ret_bc_ytd,
  }))
}

export function jsonToAssetTable(json: Api.HistoricalAssetTable[]): Store.PortfolioListAssetsTable[] {
  return json.map((table) => ({
    modelVersion: table.model_version,
    assetName: table.asset_name,
    instrumentIsin: table.instrument_isin,
    lastPosition: table.position_sign,
    groupName: table.group_name,
    lastPrice: table.price,
    modelName: table.model_name,
    modelType: table.model_type,
    modelStatus: table.model_status,
    opTdyPercRet: table.tdy_perc_ret,
    opTdyTradRetBc: table.tdy_trading_ret,
    quantity: table.position_quantity,
    tdyPercRet: table.perc_ret_tdy,
    ytdPercRet: table.perc_ret_ytd,
    tdyNegativeOp: table.negative_op_tdy,
    tdyPositiveOp: table.positive_op_tdy,
    tdyTotalOp: table.total_op_tdy,
    tdyTradingRetBc: table.trading_ret_bc_tdy,
    value: table.countervalue_bc,
    ytdAllRetBc: table.all_ret_bc_ytd,
    allRetBcFees: table.all_ret_bc_fees,
    ytdExchRetBc: table.exch_ret_bc_ytd,
    ytdNegativeOp: table.negative_op_ytd,
    ytdPositiveOp: table.positive_op_ytd,
    ytdTotalOp: table.total_op_ytd,
    ytdTradingRetBc: table.trading_ret_bc_ytd,
  }))
}

export function jsonToStrategyTable(json: Api.HistoricalStrategyTable[]): Store.StrategyTable[] {
  return json.map((table) => ({
    assetsNum: table.assets_num,
    baseCurrency: table.base_currency,
    portfoliosNum: table.portfolios_num,
    tdyAllRetBc: table.all_ret_bc_tdy,
    tdyExchRetBc: table.exch_ret_bc_tdy,
    tdyNegativeOp: table.negative_op__tdy,
    tdyPositiveOp: table.positive_op_tdy,
    tdyTotalOp: table.total_op_tdy,
    tdyTradingRetBc: table.trading_ret_bc_tdy,
    value: table.countervalue_bc,
    valueLong: table.countervalue_bc_long,
    valueShort: table.countervalue_bc_short,
    ytdAllRetBc: table.all_ret_bc_ytd,
    ytdExchRetBc: table.exch_ret_bc_ytd,
    ytdNegativeOp: table.negative_op__ytd,
    ytdPositiveOp: table.positive_op_ytd,
    ytdTotalOp: table.total_op_ytd,
    ytdTradingRetBc: table.trading_ret_bc_ytd,
    strategyName: table.strategy_name,
    strategyTarget: table.strategy_target,
  }))
}

function mapToModelSignal(json: Api.ModelSignal[], asset_id): Store.ModelSignal {
  const modelSignal = json.find((modelSignal) => modelSignal.asset_id === asset_id)
  return {
    key: asset_id,
    price: modelSignal?.price,
    quantity: modelSignal?.quantity,
    sign: modelSignal?.sign,
    time: modelSignal?.time && new Date(modelSignal.time),
  }
}
function mapToSecurityRefData(json: Api.SecurityRefData[], asset_id): Store.SecurityRefData {
  const securityRefData = json.find((securityRefData) => securityRefData.asset_id === asset_id)
  return {
    key: asset_id,
    description: securityRefData?.description,
    instrumentClass: securityRefData?.instrument_class,
    instrumentCode: securityRefData?.instrument_code,
    instrumentIsin: securityRefData?.instrument_isin,
    marketCode: securityRefData?.market_code,
    symbol: securityRefData?.symbol,
    tradingCurrency: securityRefData?.trading_currency,
  }
}

function mapToModelOrder(json: Api.ModelOrder[], asset_id): Store.ModelOrder {
  const modelOrder = json.find((modelOrder) => modelOrder.asset_id === asset_id)
  return {
    key: asset_id,
    price: modelOrder?.model_order_price,
    quantity: modelOrder?.model_order_qty,
    sign: modelOrder?.model_order_sign,
    time: modelOrder?.model_order_time && new Date(modelOrder.model_order_time),
  }
}

export function jsonToSignals(json: Api.HistoricalSignals): Store.SignalsData[] {
  return json.data.historical_data_asset_ref_data_v.map(({ asset_name, asset_id }) => {
    return {
      key: asset_id,
      name: asset_name,
      modelSignal: mapToModelSignal(json.data.historical_data_model_signal_v, asset_id),
      securityRefData: mapToSecurityRefData(json.data.historical_data_asset_security_ref_data_v, asset_id),
      // modelOrder: mapTotheoreticalOrder(json.data.historical_data_model_order_histo_v, asset_id),
      modelOrder: mapToModelOrder(json.data.historical_data_signals_table_v, asset_id),
    }
  })
}
