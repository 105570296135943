import routes from 'config/routes'
import { matchPath } from 'react-router-dom'
import * as Store from 'types/store'
import { AssetStatus, LoadingState, SessionStatus, TimeFrame } from 'types/enums'
import colors from 'config/colors'
import { ResponseCode } from 'types/enums.api'
import { FormInstance, notification } from 'antd'
import { t } from 'core/i18n'
import moment from 'moment'
import { getResponseMessage } from './responseHelper'
import { ExtendedDataNode } from 'types/ui'

declare let document

function getQueryString(pars: Array<string>): string {
  let q = ''
  let value: string

  Object.keys(pars).map((key) => {
    value = pars[key]
    if (!(value === null)) {
      q += `&${key}=${encodeURIComponent(value)}`
    }
    return null
  })

  if (q.length > 0) {
    //remove first
    q = q.substr(1)
  }

  return q
}

export const getStatusColor = (status: AssetStatus) => {
  switch (status) {
    case AssetStatus.Automatic:
      return colors.automatic
    case AssetStatus.Freeze:
      return colors.freeze
    case AssetStatus.StandByLong:
      return colors.standByLong
    case AssetStatus.StandByShort:
      return colors.standByShort
    case AssetStatus.StandByFlat:
      return colors.standByFlat
    case AssetStatus.Broken:
      return colors.broken
    case AssetStatus.Quit:
      return colors.quit
    case AssetStatus.Updating:
      return colors.updating
    case AssetStatus.Terminating:
      return colors.terminating
    default:
      return colors.notActivable
  }
}

export const getSpinnerMessage = (status) => {
  switch (status) {
    case SessionStatus.Running:
      return t('operativeSession.isRunning')
    case SessionStatus.Updating:
      return t('operativeSession.isUpdating')
    case SessionStatus.Terminating:
      return t('operativeSession.isTerminating')
    default:
      return ''
  }
}

function splitQueryString(q: string) {
  const pars = q.split('&')
  const qq = {}

  for (let i = 0; i < pars.length; i += 1) {
    const ret = pars[i].toString().split('=')
    qq[ret[0]] = decodeURIComponent(ret[1])
  }
  return qq
}

export const getStatusIcon = (status: AssetStatus) => {
  switch (status) {
    case AssetStatus.Automatic:
      return 'icon-status-automatic'
    case AssetStatus.Freeze:
      return 'icon-status-freeze'
    case AssetStatus.StandByLong:
      return 'icon-status-standby-long'
    case AssetStatus.StandByShort:
      return 'icon-status-standby-short'
    case AssetStatus.StandByFlat:
      return 'icon-status-standby-flat'
    case AssetStatus.NotDefined:
      return 'icon-status-empty'
    default:
      return 'icon-status-empty'
  }
}

export function getQueryParameters(): QueryParameters {
  const { search } = document.location
  if (!search) {
    //no search pars
    return {}
  }

  //normalize to lowercase
  const q = search.toLowerCase().substr(1) as string
  return splitQueryString(q) as QueryParameters
}

export function getHashParameters() {
  const { hash } = document.location
  if (!hash) {
    //no hash pars
    return {}
  }

  //normalize to lowercase
  const q = hash.toLowerCase().substr(1)
  return splitQueryString(q)
}

export function setHashParameters(pars) {
  const h = `#${getQueryString(pars)}`
  document.location.hash = h
}

export function removeNull(obj) {
  Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})
  return obj
}

export const getCurrentRoute = (locationPath: string): Route => {
  const currentRouteKey = Object.keys(routes).find((route) => {
    const match = matchPath(routes[route].url, locationPath)
    return match != null
  })
  return routes[currentRouteKey]
}

//https://gist.github.com/JamieMason/0566f8412af9fe6a1d470aa1e089a752
export const groupBy =
  (key: any) =>
  <T = any>(array: Array<T>): Json<T[]> =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {})

//https://stackoverflow.com/questions/23247859/better-way-to-sum-a-property-value-in-an-array
export const sumBy =
  (key: any) =>
  <T = any>(array: Array<T>): number =>
    array.reduce((a, b) => a + (b[key] || 0), 0)

export const sortResources = (data: Store.Resource[]): Store.Resource[] => {
  return data.sort((item1, item2) => {
    if (item1.name < item2.name) {
      return -1
    }
    if (item1.name > item2.name) {
      return 1
    }
    return 0
  })
}

export const sortResourcesNodes = (data: ExtendedDataNode[]): ExtendedDataNode[] => {
  return data.sort((item1, item2) => {
    return (item1.resource.name).localeCompare(item2.resource.name)
  })
}

export const isStringifiedObject = (value) => {
  if (typeof value !== 'string') {
    return false
  }
  try {
    const parsed = JSON.parse(value)
    return parsed !== null && typeof parsed === 'object' && !Array.isArray(parsed)
  } catch (e) {
    return false
  }
}

export const renderAvailableData = (data: any) => {
  if (data || data === 0) {
    return data
  } else {
    return t('general.noData')
  }
}

export const getAssetStatusLabel = (status: AssetStatus) => {
  switch (status) {
    case AssetStatus.Freeze:
      return t('assets.status.freeze')
    case AssetStatus.Automatic:
      return t('assets.status.automatic')
    case AssetStatus.StandByLong:
      return t('assets.status.standByLong')
    case AssetStatus.StandByShort:
      return t('assets.status.standByShort')
    case AssetStatus.StandByFlat:
      return t('assets.status.standByShort')
  }
}

export const showError = (error: ResponseCode) => {
  const errorMessage = getResponseMessage(error)
  notification.error({
    message: t('notification.type.error'),
    description: errorMessage,
    duration: 5,
  })
}

export const showDetailedError = (errorCode: ResponseCode, errorDescription: string) => {
  notification.error({
    message: errorCode,
    description: errorDescription,
    duration: 6,
  })
}

export const showSuccess = (message: string, description: string) => {
  notification.success({
    message,
    description,
    duration: 5,
  })
}

export const displayNotification = (loadingState: LoadingState, errorCode: ResponseCode, errorDescription?: string) => {
  switch (loadingState) {
    case LoadingState.Created:
      showSuccess(t('notification.type.success'), t('notification.message.createSuccess'))
      break
    case LoadingState.Updated:
      showSuccess(t('notification.type.success'), t('notification.message.updateSuccess'))
      break
    case LoadingState.Deleted:
      showSuccess(t('notification.type.success'), t('notification.message.deletionSuccess'))
      break
    case LoadingState.CreateFailure:
    case LoadingState.UpdateFailure:
    case LoadingState.DeleteFailed:
    case LoadingState.LoadFailure:
      errorDescription
        ? showDetailedError(errorCode, errorDescription)
        : showError(errorCode)
      break
    default:
      break
  }
}

export const checkSubmitButtonBeDisabled = (form: FormInstance) => {
  const result = Object.keys(form.getFieldsValue())
  // will return false
  return !result
}

export const parseNumber = (text?: string): number | null => {
  try {
    if (text === null) {
      return null
    }
    return parseInt(text, 10)
  } catch (e) {
    return null
  }
}

export const containsSomeId = (sourceArray: number[], targetArray: number[]): boolean => {
  return targetArray.some(element => sourceArray.includes(element))
}

export const getDate = (text?: string): Date | null => {
  try {
    if (text === null) {
      return null
    }
    return new Date(text)
  } catch (e) {
    return null
  }
}

export const getDateLimits = (timeFrame: TimeFrame, session: Store.Session) => {
  const defaultScaleDays = 60
  if (timeFrame === TimeFrame.TOT) {
    const { startTime, endTime } = session.periodParams as Store.BacktestParams
    if (startTime && endTime) {
      return [
        new Date(startTime.clone().subtract(1, 'day').utc().format('YYYY-MM-DDT12:00:00')),
        endTime.clone().hour(12).toDate(),
      ]
    }
  }

  if (session?.isHistorical) {
    const { startTime, endTime } = session

    if (startTime && endTime) {
      if (timeFrame === TimeFrame.TDY) {
        return [moment(endTime).startOf('day').toDate(), endTime]
      }

      const newStartTime = new Date(
        moment().subtract(defaultScaleDays, 'day').utc().format('YYYY-MM-DDT12:00:00'),
      )

      const start =
        startTime > newStartTime
          ? newStartTime
          : new Date(moment(startTime).subtract(defaultScaleDays, 'day').utc().format('YYYY-MM-DDT12:00:00'))
      const end = moment(endTime).hour(12).toDate()

      return [start, end]
    }
  }

  if (timeFrame === TimeFrame.YTD) {
    let startTime = new Date(moment().subtract(1, 'year').utc().format('YYYY-12-31T12:00:00'))
    const endTime = moment().startOf('day').hour(12).toDate()
    if (session.startTime) {
      const newStartTime = new Date(
        moment().subtract(defaultScaleDays, 'day').utc().format('YYYY-MM-DDT12:00:00'),
      )
      startTime =
        session.startTime > newStartTime
          ? newStartTime
          : new Date(moment(session.startTime).subtract(1, 'day').utc().format('YYYY-MM-DDT12:00:00'))
    }
    return [startTime, endTime]
  }

  const startTime = new Date(moment().utc().format('YYYY-MM-DDT00:00:00'))
  const endTime = new Date()

  return [startTime, endTime]
}

export const getTimeFrameLabel = (timeFrame: TimeFrame) => {
  switch (timeFrame) {
    case TimeFrame.TDY:
      return t('general.tdy')
    case TimeFrame.YTD:
      return t('general.ytd')
    case TimeFrame.TOT:
      return t('general.total')
  }
}

export const selectPopupContainer = (trigger: HTMLElement, isFullscreen: boolean): HTMLElement => {
  if (isFullscreen) {
    return trigger.parentElement
  }

  return document.querySelector('.draggable-grid-section')
}

export const hasEmptyData = (elements: Store.ChartData[]): boolean => {
  if (!elements) {
    return false
  }

  if (elements.length === 0) {
    return false
  }

  return true
}

export const filterEmptyData = (elements: Store.ChartData[]): Store.ChartData[] => {
  if (!elements) {
    return elements
  }
  return elements.filter((element: Store.EmptyData) => {
    return !element?.empty
  })
}
