import React, { useEffect, useState } from 'react'
import Debug from 'debug'
// import * as Api from 'types/api'
// import * as api from 'api/candleStick'
import * as socket from 'api/socket'
import { useSelector } from 'react-redux'
import { selectOperativeSessionAssetById, selectOperativeSessionSession } from 'store/pages/selectors'
import { config } from 'core'
import { useTranslation } from 'react-i18next'
import { Button, Skeleton } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { TraceSubscriptionParams } from 'types/api'
import * as Store from 'types/store'

// import { downloadAssetTrace } from '../../../../api/candleStick'

const debug = Debug('trace')

// const getModelInstanceId = async (sessionId: number, assetId: number) => {
//   const response = await api.getAssetInstanceId(sessionId, assetId)

//   if (isApiError(response)) {
//     debug('ERROR getModelInstanceId')
//     return null
//   }

//   const { data } = response as Api.AssetModelInstanceResponse

//   if (!data.application_asset_model_instance.length) {
//     return null
//   }
//   return data.application_asset_model_instance[0].instance_id
// }

interface UnsubscribeObject {
  unsubscribe: () => void
  destroyed: boolean
}
interface Props {
  asset: Store.Asset
  isMulti: boolean
  visible: boolean
}

const Trace: React.FC<Props> = ({ asset, visible, isMulti }) => {
  const [messages, setMessage] = useState([])
  const [noTraceData, setNoTraceData] = useState<boolean>(false)
  const session = useSelector(selectOperativeSessionSession)

  //We need to retrieve asset from redux, to get an updated version. We cannot use the data in the frame
  const currentAsset = useSelector(selectOperativeSessionAssetById(asset.id))
  const { t } = useTranslation()

  const onMessage = (message: MessageEvent) => {
    const { data } = message
    setMessage((messages) => {
      const message = data as string
      return [message, ...messages].slice(0, 200)
    })
    debug(data)
  }

  const noTrace = () => {
    return <section className="trace-list-container no-trace">{t('chart.noTrace')}</section>
  }

  const resourceId = currentAsset?.group?.id ?? currentAsset.id
  const modelClass = 'analysis-model'
  const modelType = isMulti ? 'multi-asset' : 'single-asset'

  const subscribeToSocket = async (unsubscribeObject: UnsubscribeObject) => {
    setMessage([])
    if (resourceId && !unsubscribeObject.destroyed) {
      setNoTraceData(false)

      const traceSubParams: TraceSubscriptionParams = {
        assetOrGroupId: resourceId,
        type: session.type,
        sessionId: session.id,
        onMessage: onMessage,
        modelType: modelType,
        // modelClass: modelClass,
      }

      unsubscribeObject.unsubscribe = socket.subscribeToTrace(traceSubParams)
    } else {
      setNoTraceData(true)
      setMessage([t('error.noData')])
    }
  }

  useEffect(() => {
    const unsubscribeObject: UnsubscribeObject = {
      unsubscribe: null,
      destroyed: false,
    }
    if (visible) {
      subscribeToSocket(unsubscribeObject)
    }
    return () => {
      unsubscribeObject.destroyed = true
      unsubscribeObject.unsubscribe && unsubscribeObject.unsubscribe()
    }
  }, [resourceId, currentAsset?.status, visible])

  if (!visible) {
    // TODO: check if this case verifies
    return <Skeleton />
  }

  if (!messages || messages.length === 0) {
    return noTrace()
  }

  return (
    <>
      <section className="trace-list-container">
        {messages.map((message, i) => (
          <p key={i}>{message}</p>
        ))}
      </section>
      <Button
        type="link"
        size="small"
        disabled={noTraceData}
        icon={<DownloadOutlined />}
        className="trace-download-btn"
        href={`${config.apiBaseUrl}/operative-session/${session.type}/${session.id}/model/${modelType}/${resourceId}/trace/download`}
      >
        {/* {t('chart.downloadTraceBtn')} JI-TRANSLATE */}
        Download Trace
      </Button>
    </>
  )
}

export default Trace
