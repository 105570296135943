import React from 'react'
import { Row, Col, Typography, Divider, Rate } from 'antd'
import AppContentCard from 'components/AppContentCard'
import * as Store from 'types/store'
import { useTranslation } from 'react-i18next'
import AssetModelParameters from 'containers/StrategyViewer/AssetForm/AssetModelParameters'
import { CrudOperation } from 'types/enums'

import { formatNumber } from 'core/formats'

const { Title, Paragraph } = Typography

interface Props {
  asset: Store.Asset
}

const ReadAsset: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation()

  return (
    <>
      <AppContentCard fullHeight>
        <Row align="middle" className="col-title">
          <Col span={20}>
            <Title>Asset {t('general.parameters')}</Title>
          </Col>
        </Row>

        <section className="inner-content">
          <Row>
            <Col span={24}>
              <Title level={2} className="primary-color mb-10">
                {t('general.description')}
              </Title>
            </Col>
            <Col span={9}>
              <Paragraph>{t('general.name')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph>{asset.name} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={9}>
              <Paragraph>{t('assets.symbol')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph> {asset.symbol} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={9}>
              <Paragraph>{t('assets.mic')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph> {asset.mic} </Paragraph>
            </Col>
          </Row>

          <Divider type="horizontal" />
          <Row>
            <Col span={9}>
              <Paragraph>{t('assets.market')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph> {asset.market} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={9}>
              <Paragraph>{t('assets.tradingCurrency')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph> {asset.currency} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={9}>
              <Paragraph>{t('assets.descriptionField')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph> {asset.description.descriptionField} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={9}>
              <Paragraph>{t('assets.contractSize')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph> {asset.description.contractSize} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={9}>
              <Paragraph>{t('assets.instrumentClass')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph> {asset.description.instrumentClass} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={9}>
              <Paragraph>{t('assets.lotMultiplier')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph> {asset.description.lotMultiplier} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={9}>
              <Paragraph>{t('assets.bankAccount')}</Paragraph>
            </Col>
            <Col span={15} className="align-right">
              <Paragraph> {asset.bankAccount} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />

          <Row className="mt-40 mb-10">
            <Col>
              <Title level={2} className="primary-color">
                {t('general.management')}
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/* <Paragraph>{t('assets.brokerAccount')}</Paragraph> */}
              <span>Broker Accounts:</span>
            </Col>
          </Row>
          {
            asset.brokerAccounts.map(brokerAccount => (
              <Col key={brokerAccount.account}>
                <Row>
                  <Paragraph>{brokerAccount.name}&nbsp;({brokerAccount.account})</Paragraph>
                </Row>
                <Row align="middle">
                  <Paragraph style={{fontSize: 12}}>Rank: &nbsp;</Paragraph>
                  <Rate
                    value={brokerAccount.priority}
                    style={{ color: '#6bbffb', fontSize: 16 }}
                    character="◉"
                    disabled
                    count={5}
                  />
                </Row>
              </Col>
            ))
          }
          {asset.modelClass && (
            <>
              <Divider type="horizontal" />
              <Row>
                <Col span={14}>
                  <Paragraph>{t('assets.modelName')}</Paragraph>
                </Col>
                <Col span={10} className="align-right">
                  <Paragraph> {asset.modelClass.name} </Paragraph>
                </Col>
              </Row>
              <Divider type="horizontal" />
              <Row>
                <Col span={14}>
                  {/* <Paragraph>{t('table.column.version')}</Paragraph> JI-TRANSLATE */}
                  <Paragraph>Model Version</Paragraph>
                </Col>
                <Col span={10} className="align-right">
                  <Paragraph> {asset.modelClass.version} </Paragraph>
                </Col>
              </Row>

              <Divider type="horizontal" />
              <Row>
                <Col span={14}>
                  <Paragraph>{t('assets.modelType')}</Paragraph>
                </Col>
                <Col span={10} className="align-right">
                  <Paragraph> {asset.modelClass.type} </Paragraph>
                </Col>
              </Row>
              <Divider type="horizontal" />
              <Row>
                <Col span={14}>
                  <Paragraph>{t('assets.modelLanguage')}</Paragraph>
                </Col>
                <Col span={10} className="align-right">
                  <Paragraph> {asset.modelClass.language} </Paragraph>
                </Col>
              </Row>
              <Divider type="horizontal" />
              <Row className="mb-12">
                <Col span={14}>
                  <Paragraph>{t('assets.modelDescription')}</Paragraph>
                </Col>
                <Col span={10} className="align-right">
                  <Paragraph ellipsis={{ rows: 1, tooltip: asset.modelClass.description }}>
                    {asset.modelClass.description}
                  </Paragraph>
                </Col>
              </Row>
              <AssetModelParameters
                modelClassId={asset.modelClassId}
                assetModel={asset.modelClassId}
                mode={CrudOperation.Read}
                params={asset.modelAssetParameters}
              />
            </>
          )}

          {asset.group &&
            <>
              <Divider type="horizontal" />
              <Row>
                <Col span={14}>
                  {/* <Paragraph>{t('assets.group')}</Paragraph> JI-TRANSLATE */}
                  <Paragraph>Group</Paragraph>
                </Col>
                <Col span={10} className="align-right">
                  <Paragraph> {asset.group?.name}</Paragraph>
                </Col>
              </Row>
            </>
          }

          <Divider type="horizontal" />
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.longShortField')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph> {asset.longShortText} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.qtyFCounterValue')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph> {asset.qtyFCountervalue ? t('general.yes') : t('general.no')} </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.sizeVariable')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph> {asset.sizeVariable ? t('general.yes') : t('general.no')} </Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.refQuantity')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph>{formatNumber(asset.refQuantity)}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.refQuantityMax')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph>{formatNumber(asset.refQuantityMax)}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.refQuantityMin')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph>{formatNumber(asset.refQuantityMin)}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.minOrderQuantity')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph>{formatNumber(asset.minOrderQty)}</Paragraph>
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.refCounterValue')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph>{formatNumber(asset.refCounterValue)}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.refCounterValueMax')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph>{formatNumber(asset.refCounterValueMax)}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.refCounterValueMin')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph>{formatNumber(asset.refCounterValueMin)}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Paragraph>{t('assets.minOrderCounterValue')}</Paragraph>
            </Col>
            <Col span={10} className="align-right">
              <Paragraph>{formatNumber(asset.minOrderCountervalue)}</Paragraph>
            </Col>
          </Row>
        </section>
      </AppContentCard>
    </>
  )
}

export default ReadAsset
