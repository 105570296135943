import React, { useEffect, useState } from 'react'
import StrategyTable from 'v2/components/frames/StrategyTable'
import * as Store from 'types/store'
import { useDispatch, useSelector } from 'react-redux'
import { removeFrame } from 'store/dashboard/actions'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { GridItemActions } from 'types/ui'
import AddFrameModalContainer from 'v2/containers/settings/modals/AddFrameModal'
import { selectStrategies } from 'store/resources/selectors'
import Unavaliable from 'v2/components/frames/Unavailable/Unavaliable'
import SelectColumnsModal from 'v2/components/settings/modals/SelectColumnsModal'
import * as api from 'api/historicalTables'
import { isApiError, isGraphQlError } from 'core'
import * as Api from 'types/api'
import { jsonToPortfolioTable, jsonToStrategyTable } from 'services/store/mapHistoricalTables'
import Spinner from 'components/Spinner'
import { ColumnSelector } from 'v2/components/tables/JITable/ColumnSelector'

const getStrategyTableData = async (id: number, sessionId: number) => {
  const response = await api.getStrategyTable(id, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }
  const {
    data: { historical_data_strategy_table_stg_v },
  } = response as Api.HistoricalStrategyTableResponse

  const data = jsonToStrategyTable(historical_data_strategy_table_stg_v)
  return data[0]
}

const getPortfolioTableData = async (id: number, sessionId: number) => {
  const response = await api.getStrategyTablePortfolios(id, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }
  const {
    data: { historical_data_portfolio_table_prt_v },
  } = response as Api.HistoricalPortfolioTableResponse

  const data = jsonToPortfolioTable(historical_data_portfolio_table_prt_v)
  return data
}

interface Props {
  frame: Store.Frame
}

const StrategyTableContainer: React.FC<Props> = ({ frame }) => {
  const dispatch = useDispatch()
  const resource = frame.selectedResources[0].resource as Store.Strategy
  const { columnKeys, columnsConfigs } = frame.options
  const { id: sessionId } = useSelector(selectOperativeSessionSession)
  const strategies = useSelector(selectStrategies)
  const [isModalVisible, setModalVisible] = useState(false)
  const [isSelectColumnsModalVisible, setSelectColumnsModalVisible] = useState(false)
  const [strategyData, setStrategyData] = useState<Store.StrategyTable>()
  const [portfolioData, setPortfolioData] = useState<Store.PortfolioTable[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const getHasMissingResource = () => {
    const strategy = strategies.find((strategy) => strategy.id === resource?.id)
    if (!strategy) {
      return true
    }

    return false
  }

  const getData = async () => {
    const strategyData = await getStrategyTableData(resource.id, sessionId)
    const portfolioData = await getPortfolioTableData(resource.id, sessionId)
    setStrategyData(strategyData)
    setPortfolioData(portfolioData)
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const onClose = () => {
    setModalVisible(false)
  }

  const onSelectColumnsModalClose = () => {
    setSelectColumnsModalVisible(false)
  }

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
      case GridItemActions.ColumnSelect:
        setSelectColumnsModalVisible(true)
    }
  }

  if (getHasMissingResource()) {
    return <Unavaliable onItemClick={onItemClick} title={resource.name} />
  }

  if (!loading) {
    return (
      <>
        <StrategyTable
          portfolioData={portfolioData}
          strategyData={[strategyData]}
          columnKeys={columnKeys}
          columnsConfigs={columnsConfigs}
          onItemClick={onItemClick}
          isOffline={false}
          frame={frame}
        />
        <AddFrameModalContainer
          initialFrame={frame}
          visible={isModalVisible}
          onClose={onClose}
          initialX={0}
          initialY={0}
        />
        {/* <SelectColumnsModal
          frame={frame}
          visible={isSelectColumnsModalVisible}
          onClose={onSelectColumnsModalClose}
          tableTypes={['strategy', 'strategy-portfolios']}
        /> */}
        <ColumnSelector
          open={isSelectColumnsModalVisible}
          frame={frame}
          tableTypes={['strategy', 'strategy-portfolios']}
          onClose={onSelectColumnsModalClose}
        />
      </>
    )
  }


  return <Spinner />
}

export default StrategyTableContainer
