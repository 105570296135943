import { config } from 'core'
import * as Api from 'types/api'
import { FlowControlSpeed, SessionType, TSInterval } from 'types/enums'
import { Feeds, Sigma } from 'types/enums.api'
import { getThrottling, getHandshake, getToken } from 'helpers/socketHelper'
import SocketConnection from './socketConnection'
import { isStrategyFeed } from 'helpers/feedHelper'

const requestMessage = (json: Api.SubscribeSocketObject): string => {
  return JSON.stringify(json).concat('\n')
}

export const subscribeToTicker = (
  entityId: number,
  lastSequence: number,
  onMessage: (message) => void,
  sigmas: Sigma[],
  sessionId: number,
  portfolioId: number,
  feed: Feeds,
  interval: TSInterval,
  speed: FlowControlSpeed,
) => {
  const token = getToken()

  const handshake = getHandshake(token)

  const key = interval ? `${entityId}.${interval}` : `${entityId}`

  const subscribe: Api.SubscribeSocketObject = {
    '@type': 'subscribe_ticker',
    token: token,
    subscription_id: 1,
    feed_svc: feed,
    sub_key: {
      key: key,
      is_regex: false,
    },
    ticker_depth: {
      '@type': 'ticker_depth',
      sequence: lastSequence,
    },
    sigma_list: sigmas,
  }

  const throttling = getThrottling(token, feed, speed)
  const url = isStrategyFeed(feed)
    ? config.socket.strategies(sessionId, entityId)
    : config.socket.assets(sessionId, portfolioId)

  const { close } = new SocketConnection(
    url,
    (client) => {
      client.send(handshake)
      client.send(throttling)
      client.send(requestMessage(subscribe))
    },
    onMessage,
  )

  const unsubscribe = () => {
    close()
  }

  return unsubscribe
}

export const subscribeToSnapshotsWithInterval = (
  entityId: string,
  onMessage: (message) => void,
  sessionId: number,
  portfolioId: number,
  feed: Feeds,
  sigmas: Sigma[],
  interval: TSInterval,
  isRegex: boolean,
  speed: FlowControlSpeed,
  webSocketUrl?: string,
) => {

  const token = getToken()
  const handshake = getHandshake(token)
  const key = interval ? `${entityId}.${interval}` : entityId

  const subscribe: Api.SubscribeSocketObject = {
    '@type': 'subscribe_snapshot',
    token: token,
    feed_svc: feed,
    subscription_id: 1,
    sub_key: {
      key,
      is_regex: isRegex,
    },
    snap_mode: 'incremental',
    sigma_list: sigmas,
  }

  const throttling = getThrottling(token, feed, speed)

  let url = ''

  if (webSocketUrl) {
    url = isStrategyFeed(feed)
      ? `${webSocketUrl}/operative-session/${sessionId}/strategy/${entityId}/ws`
      : `${webSocketUrl}/operative-session/${sessionId}/portfolio/${portfolioId}/ws`
  } else {
    url = isStrategyFeed(feed)
      ? config.socket.strategies(sessionId, parseInt(entityId))
      : config.socket.assets(sessionId, portfolioId)
  }

  const { close } = new SocketConnection(
    url,
    (client) => {
      client.send(handshake)
      client.send(throttling)
      client.send(requestMessage(subscribe))
    },
    onMessage,
  )

  const closeSocket = () => {
    close()
  }

  return closeSocket
}

export const subscribeToSnapshots = (
  entityId: number,
  onMessage: (message) => void,
  sessionId: number,
  portfolioId: number,
  service: Feeds,
  sigmas: Sigma[],
  speed: FlowControlSpeed,
) => {
  return subscribeToSnapshotsWithInterval(
    entityId.toString(),
    onMessage,
    sessionId,
    portfolioId,
    service,
    sigmas,
    null,
    false,
    speed,
  )
}

export const subscribeToTrace = (params: Api.TraceSubscriptionParams) => {
  const {assetOrGroupId, type, sessionId, onMessage, modelType} = params
  const { close } = new SocketConnection(
    config.socket.trace(assetOrGroupId, type.toLowerCase(), sessionId, modelType),
    () => null,
    onMessage,
  )
  const unsubscribe = () => {
    close()
  }

  return unsubscribe
}
