import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { LoadingState, SessionStatus } from 'types/enums'
import DashboardPlayer from 'v2/components/dashboard-players/BacktestPlayer/BacktestPlayer'
import * as Forms from 'types/form'
import { useTranslation } from 'react-i18next'
import {
  freezeBackTestOperation,
  launchBackTestOperation,
  loadFinishedById,
  stopBackTestOperation,
} from 'store/pages/operative-session/actions'

import { useSelector } from 'react-redux'
import { selectBacktestProgress, selectOperativeSessionSession, selectOperativeSessionState } from 'store/pages/selectors'
import { useInterval } from 'react-use'
import * as api from 'api/sessions'
import * as Api from 'types/api'
import { isApiError, isGraphQlError } from 'core'
import * as Store from 'types/store'
import moment from 'moment'

const DELAY = 30000

const checkSessionHasEnded = async (id: number): Promise<boolean> => {
  const response = await api.getSessionEndTime(id)

  if (isApiError(response) || isGraphQlError(response)) {
    return false
  }

  const session = (response as Api.SessionsEndTimeResponse).data.application_operative_session[0]
  
  return session?.end_time ? true : false
}

// const isValidDate = (date: Date) => {
//   return date && Object.prototype.toString.call(date) === "[object Date]"
// }

const BacktestPlayerContainer: React.FC = () => {
  const { t } = useTranslation()
  const [showParams, setShowParams] = useState(true)
  const session = useSelector(selectOperativeSessionSession)
  const loadingState = useSelector(selectOperativeSessionState)
  const progressWS = useSelector(selectBacktestProgress)

  let displayProgress = {...progressWS}
  
  const { periodParams } = session
  const { endDate, startDate, speedup, tickByTick, dataFrequency } = periodParams as Store.BacktestParams
  const dispatch = useDispatch()

  const dataFrequencyUnit = dataFrequency ? dataFrequency.match(/[a-z]/g) : '' // Extract unit (chars)
  const dataFrequencyValue = dataFrequency ? Number(dataFrequency.match(/\d+/g)) : null // Extract value (numbers)

  const { control, getValues, clearErrors, setError, formState } = useForm<Forms.BacktestPlayer>({
    mode: 'onSubmit',
    defaultValues: {
      date: [startDate?.toDate(), endDate?.toDate()],
      speedup,
      tickByTick,
      dataFrequencyUnit: 's', // TODO: this is due to WIP, decisions still needs to be made for BKT params
      dataFrequencyValue
    },
  })


  useEffect(() => {
    // If the user starts filling the field after an error
    // clear the error on these field
    const errorFields = Object.keys(formState.errors)
    const dirtyFields = Object.keys(formState.dirtyFields)

    if (errorFields.length) {
      errorFields.forEach(error => {
        const errorToRemove = dirtyFields.find(field => field === error)

        if (errorToRemove) {
          // console.log('error to remove', errorToRemove)
          clearErrors([errorToRemove as any])
        }
      })
    }
  }, [formState])

  const checkIfSessionHasEnded = async () => {
    const isEnded = await checkSessionHasEnded(session.id)

    if (isEnded) {
      dispatch(loadFinishedById(session.id))
    }
  }

  useInterval(
    () => {
      checkIfSessionHasEnded()
    },
    session.status === SessionStatus.Running ? DELAY : null,
  )

  const toggleParamsVisibility = () => setShowParams((state) => !state)

  const showErrors = (startDate, endDate, speedup, dataFrequencyUnit, dataFrequencyValue, tickByTick) => {
    if (!startDate || !endDate) {
      setError('date', { message: t('error.requiredField') })
    }
    if (!speedup) {
      setError('speedup', { message: t('error.requiredField') })
    }
    if (!tickByTick && !dataFrequencyUnit) {
      setError('dataFrequencyUnit', { message: t('error.requiredField') })
    }
    if (!tickByTick && !dataFrequencyValue) {
      setError('dataFrequencyValue', { message: t('error.requiredField') })
    }
  }

  const onClick = (status: SessionStatus) => {
    clearErrors()

    switch (status) {
      case SessionStatus.Running: {
        const { date, speedup, dataFrequencyUnit, dataFrequencyValue, tickByTick } = getValues()
        const [startDate, endDate] = date || []
        const dataFrequency = `${dataFrequencyValue}${dataFrequencyUnit}`

        const payload = {
          sessionStatus: status,
          start: startDate,
          stop: endDate,
          speedup,
          tickByTick,
          dataFrequency,
        }

        if (!startDate || !endDate || !speedup || !tickByTick && dataFrequencyValue === null) {
          showErrors(startDate, endDate, speedup, dataFrequencyUnit, dataFrequencyValue, tickByTick)
          setShowParams(true)
          return
        }

        dispatch(launchBackTestOperation(payload))
        break
      }
      case SessionStatus.StandBy:
        dispatch(freezeBackTestOperation())
        break
      case SessionStatus.Stopped:
        dispatch(stopBackTestOperation())
        break
    }
  }

  if (session.status === SessionStatus.Completed) {
    displayProgress.percentage = 100
    displayProgress.time = session.lastEvt
  }

  if (session.status === SessionStatus.Stopped || session.status === SessionStatus.Broken || session.status === SessionStatus.Terminating) {
    displayProgress.percentage = 0
    displayProgress.time = session.lastEvt
  }

  return (
    <DashboardPlayer
      control={control}
      onClick={onClick}
      showParams={showParams}
      toggleParamsVisibility={toggleParamsVisibility}
      loading={loadingState === LoadingState.Updating}
      progress={displayProgress.percentage ?? 'loading...'}
      backtestTime={displayProgress.time ? moment(displayProgress.time).format('DD-MM-YYYY HH:mm:ss') : 'loading...'}
    />
  )
}

export default BacktestPlayerContainer
