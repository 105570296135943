import { BaseSyntheticEvent, SyntheticEvent, useCallback, useState, useRef, useMemo, useEffect } from "react"
import {
  Column,
  ColumnFiltersState,
  ColumnResizeDirection,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  RowData,
  useReactTable,
} from "@tanstack/react-table"
import { useVirtualizer } from '@tanstack/react-virtual'
import styles from './jiTable.module.scss'
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, FilterFilled, SearchOutlined } from "@ant-design/icons"
import { ColumnKeys, TableConfig } from "types/store"

type JITableProps = {
  data: any[]
  columns: any[]
  // visibleColumns: string[]
  columnsConfigs: TableConfig
  classname?: string
  scrollHeight?: number
}

declare module '@tanstack/react-table' {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select' | 'date'
  }
}

const swapTableColumns = (columnOrder: string[], movingColumnKey: string, targetColumnKey: string) => {
  console.log('columnOrder', movingColumnKey, targetColumnKey)
  if (!movingColumnKey || !targetColumnKey || movingColumnKey === targetColumnKey) {
    return columnOrder
  }

  const movingColumnIndex = columnOrder.indexOf(movingColumnKey)
  const targetColumnIndex = columnOrder.indexOf(targetColumnKey)

  const newColumnOrder = [...columnOrder]
  newColumnOrder[movingColumnIndex] = targetColumnKey
  newColumnOrder[targetColumnIndex] = movingColumnKey

  return newColumnOrder
}

const ColumnResizer = ({ header, tableInstance, columnResizeMode }) => {
  const resizeHandler = header.getResizeHandler()

  const onMouseDown = (e) => {
    e.stopPropagation()
    resizeHandler(e)
  }

  const onTouchStart = (e) => {
    e.stopPropagation()
    resizeHandler(e)
  }

  return (
    <div
      onDoubleClick={() => header.column.resetSize()}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      className={`${styles['resizer']}
        ${tableInstance.options.columnResizeDirection}
        ${header.column.getIsResizing() ? styles['isResizing'] : ''}`
      }
      style={{
        transform:
          columnResizeMode === 'onEnd' &&
          header.column.getIsResizing()
            ? `translateX(${
                (tableInstance.options.columnResizeDirection ===
                'rtl'
                  ? -1
                  : 1) *
                (tableInstance.getState().columnSizingInfo
                  .deltaOffset ?? 0)
              }px)`
            : '',
      }}
    >
    </div>
  )
}

const scrollRowsNumber = (scrollHeight: number) => {
  return Math.floor(scrollHeight / 50)
}

const Filter = ({ column, setFilterOpen }: { column: Column<any, unknown>, setFilterOpen: any }) => {
  const { filterVariant } = column.columnDef.meta ?? {}

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = useMemo(
    () =>
      filterVariant === 'range'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys())
            .sort()
            // .slice(0, 5000)
            ,
    [column.getFacetedUniqueValues(), filterVariant]
  )

  const renderFilter = () => {
    switch (filterVariant) {
      case 'range':
        return (
          <>
            <DebouncedInput
              type="number"
              min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
              max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
              value={(columnFilterValue as [number, number])?.[0] ?? ''}
              onChange={(value) =>
                column.setFilterValue((old: [number, number]) => [value, old?.[1]])
              }
              placeholder={`Min ${
                // eslint-disable-next-line no-undefined
                column.getFacetedMinMaxValues()?.[0] !== undefined
                  ? `(${column.getFacetedMinMaxValues()?.[0]})`
                  : ''
              }`}
              className="filter-number-input"
            />
            <DebouncedInput
              type="number"
              min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
              max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
              value={(columnFilterValue as [number, number])?.[1] ?? ''}
              onChange={(value) =>
                column.setFilterValue((old: [number, number]) => [old?.[0], value])
              }
              placeholder={`Max ${
                column.getFacetedMinMaxValues()?.[1]
                  ? `(${column.getFacetedMinMaxValues()?.[1]})`
                  : ''
              }`}
              className="filter-number-input"
            />
          </>
        )
      case 'select':
        return (
          <>
            <select
              className="filter-select-input"
              onChange={(e) => {
                // console.log('event', e.target.value, column)
                column.setFilterValue(e.target.value)
              }}
              value={columnFilterValue?.toString()}
            >
              <option value="">All</option>
              {sortedUniqueValues.map((value: any) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            <div className={styles['close-btn']} onClick={() => setFilterOpen(null)}>
              <CloseOutlined />
            </div>
          </>
        )
      case 'text':
        return (
          <>
            <datalist id={column.id + 'list'}>
              {sortedUniqueValues.map((value: any) => (
                <option value={value} key={value} />
              ))}
            </datalist>
            <DebouncedInput
              type="text"
              value={(columnFilterValue ?? '') as string}
              onChange={(value) => column.setFilterValue(value)}
              placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
              className="filter-text-input"
              list={column.id + 'list'}
            />
            <div className={styles['close-btn']} onClick={() => setFilterOpen(null)}>
              <CloseOutlined />
            </div>
          </>
        )
      default:
        return null
    }
  }

  return renderFilter()
}

// Debounced input
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={e => setValue(e.target.value)} />

}

export const JITable: React.FC<JITableProps> = ({ data, columns, classname, scrollHeight, columnsConfigs }) => {
  const [columnOrder, setColumnOrder] = useState<string[]>(columns.map((col) => col.accessorKey))
  const [movingColumnKey, setMovingColumnKey] = useState<string | null>(null)
  const [targetColumnKey, setTargetColumnKey] = useState<string | null>(null)
  const [columnResizeMode, setColumnResizeMode] = useState<any>('onChange')
  const [columnResizeDirection, setColumnResizeDirection] = useState<ColumnResizeDirection>('ltr')
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState({})
  const [filterOpen, setFilterOpen] = useState<any>(null)
  const [clickedRow, setClickedRow] = useState<string | null>(null)

  const parentRef = useRef<HTMLDivElement>(null)

  // const rowVirtualizer = useVirtualizer({
  //   count: data.length,
  //   getScrollElement: () => parentRef.current,
  //   estimateSize: () => scrollRowsNumber(scrollHeight), // Adjust for row height
  // })

  // const columnVirtualizer = useVirtualizer({
  //   horizontal: true,
  //   count: columns.length,
  //   getScrollElement: () => parentRef.current,
  //   estimateSize: () => 100,
  //   overscan: 5,
  // })

  // const virtualRows = rowVirtualizer.getVirtualItems()

  // console.log('virtualRows', virtualRows)

  // const visibleColumnsKeys = useMemo(() => visibleColumns, [visibleColumns])

  // useEffect(() => {
  //   console.log('visibleColumnsKeys', visibleColumnsKeys)
  // }, [visibleColumnsKeys])

  useEffect(() => {
    const obj = {}
    columnsConfigs?.columns.forEach((c) => {
      obj[c.key] = !c.hidden
    })
    // console.log('visibleColumnsKeys', obj)

    setColumnVisibility(obj)
  }, [columnsConfigs])

  const handleRowClick = useCallback((rowId) => {
    if (clickedRow === rowId) {
      setClickedRow(null)
      return
    }
    setClickedRow(rowId)
  }, [clickedRow])

  const handleFilterOpen = useCallback((column) => {
    if (filterOpen && filterOpen === column) {
      // console.log('close filter')
      setFilterOpen(null)
      return
    }
    setFilterOpen(column)
  }, [filterOpen])

  const rows = useMemo(() => data, [data])
  const cols = useMemo(() => columns, [columns])

  const fixedLeftColumns = cols.filter((col) => {
    if (col.fixed === 'left') {
      return col
    }
  }).map((col) => col.accessorKey)

  const fixedRightColumns = cols.filter((col) => {
    if (col.fixed === 'right') {
      return col
    }
  }).map((col) => col.accessorKey)

  const tableInstance = useReactTable({
    data: rows ?? [],
    columns: cols,
    defaultColumn: {
      // size: "auto" as any,
      minSize: 40,
      maxSize: 800,
    },
    state: { // TODO: save table state in local storage OR within the layout
      columnOrder: columnOrder,
      columnVisibility: columnVisibility,
      columnPinning: {
        left: fixedLeftColumns,
        right: fixedRightColumns,
      },
      columnFilters: columnFilters,
    },
    columnResizeMode: columnResizeMode,
    columnResizeDirection: columnResizeDirection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  })

  const handleDragStart = useCallback((e: BaseSyntheticEvent) => {
    e.stopPropagation()
    console.log('drag start', e.target, e.target.dataset)
    if (e.target.dataset.columnKey) {
      setMovingColumnKey(e.target.dataset.columnKey)
    }
    // Add visual feedback
    e.target.classList.add(styles['dragging'])
  }, [])
  
  const handleDragOver = useCallback((e: BaseSyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault() // Necessary for drop to work
    // Add hover effect
    e.target.classList.add(styles['drag-over'])
    // Add hover effect to all cells in the same
    // TODO: use const column = table.getColumn('firstName') instead of this
    // and let user place the header also in the body to enhance the UX
    const tableBodyCells = Array.from(document.querySelectorAll(`.${styles['ji-table']} tbody td`))
    tableBodyCells.map((td: any) => {
      if (td.dataset.columnKey === e.target.dataset.columnKey) {
        td.classList.add(styles['drag-over'])
      }
    })
  }, [])
  
  const handleDrop = useCallback((e: BaseSyntheticEvent) => {
    e.stopPropagation()
    if (e.target.dataset.columnKey) {
      setTargetColumnKey(e.target.dataset.columnKey)
    }
    // Remove visual feedback
    e.target.classList.remove(styles['drag-over'])
    e.target.classList.remove(styles['dragging'])
  }, [])
  
  const handleDragEnd = useCallback((e: BaseSyntheticEvent) => {
    e.stopPropagation()
    // Reorder columns
    const reordereColumns = swapTableColumns(columnOrder, movingColumnKey, targetColumnKey)
    setColumnOrder(reordereColumns)
    // Clean up visual feedback
    const hoveredCells = Array.from(document.querySelectorAll(`.${styles['ji-table']} .${styles['drag-over']}`))
    // Cleanup hover effect for all the body cells
    hoveredCells.map((td: any) => {
      td.classList.remove(styles['drag-over'])
    })
    e.target.classList.remove(styles['dragging'])
  }, [movingColumnKey, targetColumnKey, columnOrder])

  const handleDragLeave = useCallback((e: BaseSyntheticEvent) => {
    e.stopPropagation()
    // Remove hover effect
    const hoveredCells = Array.from(document.querySelectorAll(`.${styles['ji-table']} .${styles['drag-over']}`))
    // Cleanup hover effect for all the body cells
    hoveredCells.map((td: any) => {
      td.classList.remove(styles['drag-over'])
    })
    e.target.classList.remove(styles['drag-over'])
    e.target.classList.remove(styles['dragging'])
  }, [])


  return (
    <div className={`${styles['ji-table-container']} nonDraggable`} style={{height: `${scrollHeight}px`}} ref={parentRef}>
      <table
        className={`${styles['ji-table']} ji-table ${classname ?? ''}`}
        style={{width: tableInstance.getCenterTotalSize()}}
      >
        {/* <colgroup>
          {tableInstance.getHeaderGroups().map(headerGroup => {
            return headerGroup.headers.map(header => {
              return (
                <col key={headerGroup.id} style={{width: `${header.getSize()}px`}} />
              )
            })
          })}
        </colgroup> */}
        {/* {`TESt: ${JSON.stringify(tableInstance.getState().columnFilters)}`} */}
        <thead>
          {tableInstance.getHeaderGroups().map(headerGroup => {

            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, i) => {
                  const isFixed = (header as any).column.getIsPinned()
                  const colWidth = header.getSize()

                  const left = isFixed === 'left'
                    ? i > 0
                      ? `${colWidth}px`
                      : 0
                    : null

                  const right = isFixed === 'right'
                    ? i < headerGroup.headers.length - 1
                      ? `${colWidth}px`
                      : 0
                    : null

                  const classname = isFixed
                    ? `${styles['fixed-column']} ${styles['col-container']}`
                    : `${styles['col-container']}`

                  return (
                    <th
                      key={header.id}
                      style={{
                        width: isNaN(colWidth) ? 'auto' : `${colWidth}px`,
                        left: left,
                        right: right,
                      }}
                      className={classname}
                      data-column-key={(header as any).column.columnDef.accessorKey}
                    >
                      <div className={styles['col-header']}>
                        <div
                          className="title"
                          draggable={!isFixed}
                          onDragStart={handleDragStart}
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          onDragEnd={handleDragEnd}
                          onDragLeave={handleDragLeave}
                          data-column-key={(header as any).column.columnDef.accessorKey}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                          }
                        </div>
                        <div className={`${styles['col-actions']}`}>
                          {/* <div className={`${styles['grab-handle']}`}>
                            {!isFixed &&
                              <span>🟰</span>
                            }
                          </div> */}
                          <div
                            className={
                              `${styles['sorter']}
                              ${header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : ''}`
                            }
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {
                              header.column.getCanSort()
                                ? header.column.getNextSortingOrder() === 'asc'
                                  ? <CaretUpOutlined style={{color: "#bfbfbf"}} />
                                  : header.column.getNextSortingOrder() === 'desc'
                                    ? <CaretDownOutlined style={{color: "#bfbfbf"}} />
                                    : <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <CaretUpOutlined style={{color: "#bfbfbf"}} />
                                        <CaretDownOutlined style={{color: "#bfbfbf"}} />
                                      </div>
                                : null
                            }
                          </div>
                          <div className={styles['filter']}>
                            {header.column.getCanFilter() &&
                              header.column.columnDef.meta?.filterVariant
                              ? header.column.columnDef.meta?.filterVariant === 'select'
                                ?
                                  (<>
                                    <FilterFilled style={{color: header.column.getIsFiltered() ? "#1890ff" : "#bfbfbf"}} onClick={() => handleFilterOpen(header.column)} />
                                    {filterOpen === header.column
                                      ?
                                        <div className={styles['filter-panel']}>
                                          <Filter column={filterOpen} setFilterOpen={setFilterOpen} />
                                        </div>
                                      : null
                                    }
                                  </>)
                                : (<>
                                    <SearchOutlined style={{color: header.column.getIsFiltered() ? "#1890ff" : "#bfbfbf"}} onClick={() => handleFilterOpen(header.column)} />
                                    {filterOpen === header.column
                                      ?
                                        <div className={styles['filter-panel']}>
                                          <Filter column={filterOpen} setFilterOpen={setFilterOpen} />
                                        </div>
                                      : null
                                    }
                                  </>)
                              : null
                              // flexRender(
                              //   header.column.columnDef.filter,
                              //   header.getContext()
                              // )
                            }
                          </div>
                          {header.column.getCanResize() &&
                            <ColumnResizer header={header} tableInstance={tableInstance} columnResizeMode={columnResizeMode} />
                          }
                        </div>
                      </div>
                    </th>
                )})}
              </tr>
            )
          })}
        </thead>
        <tbody>
          {tableInstance.getRowModel().rows.map(row => {
          {/* {virtualRows.map(r => {
            const row = tableInstance.getRowModel().rows[r.index] */}
            const visibleCells = row.getVisibleCells()
            // const isRowClicked = clickedRows.has(row.id)
            const isRowClicked = clickedRow === row.id

            return (
              <tr
                key={row.id}
                className={isRowClicked ? styles['row-clicked'] : ''}
                onClick={() => handleRowClick(row.id)}
              >
                {visibleCells.map((cell, i) => {
                  // console.log('column filter value', cell.column.getFilterValue())
                  const isFixed = (cell as any).column.getIsPinned()
                  const colWidth = (cell as any).column.getSize()

                  const left = isFixed === 'left'
                    ? i > 0
                      ? `${colWidth}px`
                      : 0
                    : null

                  const right = isFixed === 'right'
                    ? i < columns.length - 1
                      ? `${colWidth}px`
                      : 0
                    : null

                  return (
                    <td
                      key={cell.id}
                      // data-index={r.index}
                      data-column-key={(cell.column as any).columnDef.accessorKey}
                      style={{
                        width: isNaN(colWidth) ? 'auto' : `${colWidth}px`,
                        left: left,
                        right: right,
                      }}
                      className={isFixed ? styles['fixed-column'] : ''}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </tr>
          )})}
        </tbody>
      </table>
    </div>
  )
}
