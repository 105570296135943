import React, { Key, useCallback, useEffect, useState } from 'react'
import { Avatar, Col, Input, Row, Tree } from 'antd'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
import styles from './styles.module.scss'
import IconFont from 'core/IconFont'

import classNames from 'classnames'
import { getInitialExpansion } from 'helpers/transfer/core'
import { ExtendedDataNode, TransferType } from 'types/ui'
import ContextItem from './ContextItem'
import {
  buildTree,
  expandNodes,
  normalizeTree,
  transferItem,
  updateTreeFrom,
} from 'helpers/transfer/strategyTransfer'
import SplitPane, { Pane } from 'react-split-pane'
import { TransferLayout, TransferContext } from 'types/enums'
import debounce from 'debounce'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

import MoveIcon from 'assets/icon/move-icon.svg'

interface Props {
  treeData: ExtendedDataNode[]
  onDataChanged: (data: ExtendedDataNode[]) => void
  layout?: TransferLayout
  context?: TransferContext
  onNodeClicked?: (node: ExtendedDataNode) => void
  defaultSelected?: Array<string>
  onFilterChange: (value: string) => void
  isReadOnly?: boolean
  disableAddGroup?: boolean
  loading?: boolean
}

export const StrategyTransfer: React.FC<Props> = ({
  treeData,
  onDataChanged,
  layout = TransferLayout.Horizontal,
  context = TransferContext.All,
  onNodeClicked = () => null,
  defaultSelected = [],
  onFilterChange,
  isReadOnly,
  disableAddGroup,
  loading,
}) => {
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const initialDataFrom = updateTreeFrom(treeData, defaultSelected)
  const initialDataTo = normalizeTree(buildTree(initialDataFrom, defaultSelected))
  const initialExpandedFrom = getInitialExpansion(initialDataFrom)
  const initialExpandedTo = getInitialExpansion(initialDataTo)
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(defaultSelected)

  const [dataFrom, setDataFrom] = useState<ExtendedDataNode[]>(initialDataFrom)
  const [dataTo, setDataTo] = useState<ExtendedDataNode[]>(initialDataTo)
  const [expandedTo, setExpandedTo] = useState<React.Key[]>(initialExpandedTo)

  const { t } = useTranslation()
  const transferClass = classNames(styles['strategy-transfer'], themeStyles['theme'])

  const generateExpandedTree = (treeData: ExtendedDataNode[], selectedKeys: Key[]) => {
    const data = updateTreeFrom(treeData, selectedKeys)
    const tree = normalizeTree(buildTree(data, selectedKeys))
    setDataFrom(data)
    setDataTo(tree)
    setSelectedKeys(selectedKeys)

    return tree
  }

  useEffect(() => {
    generateExpandedTree(treeData, selectedKeys)
  }, [treeData, selectedKeys])

  const onItemClick = (node: ExtendedDataNode, type: TransferType) => {
    transferItem(node, type, selectedKeys, context)

    const tree = generateExpandedTree(dataFrom, selectedKeys)

    //Expand
    const expanded = [...expandedTo]
    let expandChildren = false
    if (
      type === TransferType.AddAssets ||
      type === TransferType.AddGroups ||
      type === TransferType.AddPortfolios ||
      type === TransferType.AddStrategies
    ) {
      expandChildren = true
    }
    expandNodes(tree, expanded, node, expandChildren)
    onDataChanged(tree)
    setExpandedTo(expanded)
  }

  const getContextItem = (node: ExtendedDataNode, type: TransferType) => {
    return (
      <ContextItem
        node={node}
        onClick={onItemClick}
        type={isReadOnly ? TransferType.Disabled : type}
        context={context}
        disableAddGroup
      />
    )
  }

  const handleFilterChange = useCallback(
    debounce((filter: string) => onFilterChange(filter), 300),
    [onFilterChange],
  )

  const renderFilter = () => {
    const filterClass = classNames('transfer-search', {
      'ant-col-10': layout === TransferLayout.Horizontal,
    })

    return (
      <Input
        className={filterClass}
        placeholder={t('table.filter.searchAssets')}
        onChange={(e) => {
          handleFilterChange(e.target.value)
        }}
        suffix={<IconFont type="icon-magnifier" />}
      />
    )
  }

  const renderVertical = () => {
    return (
      <div data-augmented-ui-reset className={transferClass}>
        <SplitPane className="app-split-pane" split="horizontal" defaultSize={300}>
          <Pane className="top-pane">
            <AppContentCard fullHeight>
              <Tree
                disabled={loading}
                blockNode
                autoExpandParent={true}
                titleRender={(node: ExtendedDataNode) => getContextItem(node, TransferType.Add)}
                defaultExpandedKeys={initialExpandedFrom}
                showLine={false}
                treeData={dataFrom}
                // checkable
                className="tree-source"
                onSelect={(selectedKeys, { node }) => {
                  onNodeClicked(node as any)
                }}
              />
            </AppContentCard>
          </Pane>
          <Pane className="bottom-pane">
            <AppContentCard fullHeight>
              <Tree
                disabled={loading}
                blockNode
                autoExpandParent={true}
                titleRender={(node: ExtendedDataNode) => getContextItem(node, TransferType.Remove)}
                onExpand={(keys) => {
                  setExpandedTo(keys)
                }}
                defaultExpandedKeys={expandedTo}
                expandedKeys={expandedTo}
                showLine={false}
                treeData={dataTo}
                // checkable
                className="tree-destination"
              />
            </AppContentCard>
          </Pane>
        </SplitPane>
      </div>
    )
  }

  const renderHorizontal = () => {
    return (
      <Row className={transferClass}>
        <Col span={24}>
          <Row>
            <Col span={10} className="col-source">
              <AppContentCard fullHeight>
                <Tree
                  blockNode
                  autoExpandParent={true}
                  titleRender={(node: ExtendedDataNode) => getContextItem(node, TransferType.Add)}
                  defaultExpandedKeys={initialExpandedFrom}
                  showLine={false}
                  treeData={dataFrom}
                  className="tree-source"
                />
              </AppContentCard>
              <Avatar className="moving-icon" shape="square" src={MoveIcon} />
            </Col>
            <Col span={4} />
            <Col span={10} className="col-destination">
              <Avatar
                className="moving-icon"
                shape="square"
                src={MoveIcon}
                style={{ transform: 'rotate(180deg)' }}
              />
              <AppContentCard fullHeight>
                <Tree
                  blockNode
                  autoExpandParent={true}
                  titleRender={(node: ExtendedDataNode) => getContextItem(node, TransferType.Remove)}
                  onExpand={(keys) => {
                    setExpandedTo(keys)
                  }}
                  defaultExpandedKeys={expandedTo}
                  expandedKeys={expandedTo}
                  showLine={false}
                  treeData={dataTo}
                  className="tree-destination"
                />
              </AppContentCard>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
  return (
    <>
      {renderFilter()}
      {layout === TransferLayout.Horizontal ? renderHorizontal() : renderVertical()}
    </>
  )
}

export default StrategyTransfer
