import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Table, Tooltip } from 'antd'
import styles from './styles.module.scss'
import * as Store from 'types/store'
import { formatNumber } from 'core/formats'
import { ColumnsType } from 'antd/lib/table'
import { JITable } from '../JITable'
import { map } from 'lodash'
import { mapOldColumnsToNew } from 'services/api/mapService'

interface Props {
  data: Store.StrategyTable[]
  columnKeys: string[]
  // frame: Store.Frame
  baseCurrency: string
  columnsConfigs: Store.TableConfig
}

const StrategyTable: React.FC<Props> = ({ data: tableData, columnKeys = [], columnsConfigs, baseCurrency = '' }) => {
  const { t } = useTranslation()

  const data = useMemo(() => tableData, [tableData])

  // console.log('DATA', data)

  // const TABLE_COLUMNS: ColumnsType<Store.StrategyTable> = [
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.strategyName')}>Strategy</Tooltip>,
  //     dataIndex: 'strategyName',
  //     width: 199,
  //     fixed: 'left',
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.baseCurrency')}>Ref. Ccy</Tooltip>,
  //     dataIndex: 'baseCurrency',
  //     width: 60,
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.assets')}># Ast</Tooltip>,
  //     dataIndex: 'assetsNum',
  //     width: 40,
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.portfolios')}># Prt</Tooltip>,
  //     dataIndex: 'portfoliosNum',
  //     width: 40,
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.countervalue', { baseCurrency: baseCurrency })}>Ctv</Tooltip>,
  //     dataIndex: 'value',
  //     width: 40,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.longCountervalue')}>LNG Ctv</Tooltip>,
  //     dataIndex: 'valueLong',
  //     width: 60,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.shortCountervalue')}>SHT Ctv</Tooltip>,
  //     dataIndex: 'valueShort',
  //     width: 60,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTradingReturn')}>TDY Trd Ret</Tooltip>,
  //     dataIndex: 'tdyTradingRetBc',
  //     width: 80,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyExchangeRatesReturn')}>TDY Exch Rate Ret</Tooltip>,
  //     dataIndex: 'tdyExchRetBc',
  //     width: 115,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTotalReturn')}>TDY Tot Ret</Tooltip>,
  //     dataIndex: 'tdyAllRetBc',
  //     width: 80,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTradingReturn')}>YTD Trd Ret</Tooltip>,
  //     dataIndex: 'ytdTradingRetBc',
  //     width: 80,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdExchangeRatesReturn')}>YTD Exch Rate Ret</Tooltip>,
  //     dataIndex: 'ytdExchRetBc',
  //     width: 115,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTotalReturn')}>YTD Tot Ret</Tooltip>,
  //     dataIndex: 'ytdAllRetBc',
  //     width: 75,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTotalOperations')}>TDY Tot Op</Tooltip>,
  //     dataIndex: 'tdyTotalOp',
  //     width: 75,
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyPositiveOperations')}>TDY Pos Op</Tooltip>,
  //     dataIndex: 'tdyPositiveOp',
  //     width: 80,
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyNegativeOperations')}>TDY Neg Op</Tooltip>,
  //     dataIndex: 'tdyNegativeOp',
  //     width: 80,
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTotalOperations')}>YTD Tot Op</Tooltip>,
  //     dataIndex: 'ytdTotalOp',
  //     width: 80,
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdPositiveOperations')}>YTD Pos Op</Tooltip>,
  //     dataIndex: 'ytdPositiveOp',
  //     width: 80,
  //   },
  //   {
  //     title: <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdNegativeOperations')}>YTD Neg Op</Tooltip>,
  //     dataIndex: 'ytdNegativeOp',
  //     width: 80,
  //   },
  // ]
  /* eslint-enable */

  // const getColumns = (
  //   defaultColumns: ColumnsType<Store.StrategyTable>,
  //   selectedColumnsKeys,
  // ): ColumnsType<Store.StrategyTable> => {
  //   let generatedCols = []

  //   if (selectedColumnsKeys.length) {
  //     const selectedColumns = defaultColumns.filter((col: any) => selectedColumnsKeys.includes(col.dataIndex))

  //     generatedCols = [
  //       {
  //         title: t('table.column.strategyName'),
  //         dataIndex: 'strategyName',
  //         width: 199,
  //         fixed: true,
  //       },
  //       ...selectedColumns,
  //     ]
  //   } else {
  //     generatedCols = defaultColumns
  //   }

  //   return generatedCols
  // }

  const tableColumns = useMemo(() => [
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.strategyName')}>Strategy</Tooltip>,
      accessorKey: 'strategyName',
      id: 'strategyName',
      size: 199,
      enableResizing: false,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      fixed: 'left',
      meta: {
        filterVariant: 'text'
      },
      sorter: 'includesString' as any,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.baseCurrency')}>Ref. Ccy</Tooltip>,
      accessorKey: 'baseCurrency',
      id: 'baseCurrency',
      size: 60,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.assets')}># Ast</Tooltip>,
      accessorKey: 'assetsNum',
      id: 'assetsNum',
      size: 40,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.portfolios')}># Prt</Tooltip>,
      accessorKey: 'portfoliosNum',
      id: 'portfoliosNum',
      size: 40,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.countervalue', { baseCurrency: baseCurrency })}>{`Ctv ${baseCurrency}`}</Tooltip>,
      accessorKey: 'value',
      id: 'value',
      size: 70,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => {
        return <div className="num-right">{formatNumber(value.getValue())}</div>
      },
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.longCountervalue')}>LNG Ctv</Tooltip>,
      accessorKey: 'valueLong',
      id: 'valueLong',
      size: 70,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => {
        return <div className="num-right">{formatNumber(value.getValue())}</div>
      },
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.shortCountervalue')}>SHT Ctv</Tooltip>,
      accessorKey: 'valueShort',
      id: 'valueShort',
      size: 70,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => {
        return <div className="num-right">{formatNumber(value.getValue())}</div>
      },
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTradingReturn')}>TDY Trd Ret</Tooltip>,
      accessorKey: 'tdyTradingRetBc',
      id: 'tdyTradingRetBc',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => {
        return <div className="num-right">{formatNumber(value.getValue())}</div>
      },
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyExchangeRatesReturn')}>TDY Exch Rate Ret</Tooltip>,
      accessorKey: 'tdyExchRetBc',
      id: 'tdyExchRetBc',
      size: 115,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => {
        return <div className="num-right">{formatNumber(value.getValue())}</div>
      },
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTotalReturn')}>TDY Tot Ret</Tooltip>,
      accessorKey: 'tdyAllRetBc',
      id: 'tdyAllRetBc',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => {
        return <div className="num-right">{formatNumber(value.getValue())}</div>
      },
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdExchangeRatesReturn')}>YTD Exch Rate Ret</Tooltip>,
      accessorKey: 'ytdExchRetBc',
      id: 'ytdExchRetBc',
      size: 115,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => {
        return <div className="num-right">{formatNumber(value.getValue())}</div>
      },
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTotalReturn')}>YTD Tot Ret</Tooltip>,
      accessorKey: 'ytdAllRetBc',
      id: 'ytdAllRetBc',
      size: 78,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => {
        return <div className="num-right">{formatNumber(value.getValue())}</div>
      },
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTotalOperations')}>TDY Tot Op</Tooltip>,
      accessorKey: 'tdyTotalOp',
      id: 'tdyTotalOp',
      size: 75,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyPositiveOperations')}>TDY Pos Op</Tooltip>,
      accessorKey: 'tdyPositiveOp',
      id: 'tdyPositiveOp',
      size: 80,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyNegativeOperations')}>TDY Neg Op</Tooltip>,
      accessorKey: 'tdyNegativeOp',
      id: 'tdyNegativeOp',
      size: 80,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTotalOperations')}>YTD Tot Op</Tooltip>,
      accessorKey: 'ytdTotalOp',
      id: 'ytdTotalOp',
      size: 80,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdPositiveOperations')}>YTD Pos Op</Tooltip>,
      accessorKey: 'ytdPositiveOp',
      id: 'ytdPositiveOp',
      size: 80,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdNegativeOperations')}>YTD Neg Op</Tooltip>,
      accessorKey: 'ytdNegativeOp',
      id: 'ytdNegativeOp',
      size: 80,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
  ], [])

  // const cols = useMemo(() => mapOldColumnsToNew(TABLE_COLUMNS, data.length), [data]) 

  return (
    <section className={`${styles['strategy-table']} strategy-table nonDraggable`}>
      {/* <Table
        rowKey="strategyName"
        columns={getColumns(TABLE_COLUMNS, columnKeys)}
        dataSource={data}
        pagination={false}
        scroll={{ x: 200, y: 60 }}
      /> */}
      <JITable
        columns={tableColumns}
        data={data}
        scrollHeight={60}
        // visibleColumns={columnKeys}
        columnsConfigs={columnsConfigs}
      />
    </section>
  )
}

export default StrategyTable
