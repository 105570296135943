import CsvModal from 'components/StrategyViewer/CsvModal'
import { isApiError } from 'core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAssets } from 'store/resources/assets/actions'
import CsvModalError from 'components/StrategyViewer/CsvModalError'
import { uploadCsv } from 'api/csv'
import { fetchGroups } from 'store/resources/groups/actions'

interface Props {
  visible: boolean
  onClose: () => void
}
const CsvModalContainer: React.FC<Props> = ({ visible, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>(null)

  const dispatch = useDispatch()

  const onErrorClose = () => {
    setError(null)
  }

  const onUpload = async (options: Json) => {
    try {
      setLoading(true)
      const { file } = options

      const data = new FormData()
      data.append('file', file)
      const response = await uploadCsv(data)
      // console.log('DATA', response)
      if (isApiError(response)) {
        const resp = response as unknown as ApiError
        setError(resp.data.result.description)
        return
      }
    } finally {
      dispatch(fetchAssets())
      dispatch(fetchGroups())
      onClose()
      setLoading(false)
    }
  }

  return (
    <>
      {visible && <CsvModal onClose={onClose} onUpload={onUpload} loading={loading} />}
      {error && <CsvModalError onClose={onErrorClose} error={error} />}
    </>
  )
}

export default CsvModalContainer
