import * as Api from 'types/api'
import { getSessionStatus, getSessionType } from '../enumConverter'
import * as Store from 'types/store'
import Debug from 'debug'
import { getDate, parseNumber, sortResourcesNodes, isStringifiedObject } from 'core/helpers'
import { UserRoles } from 'types/enums.api'
import moment from 'moment'
import { SessionType, TimeUnit } from 'types/enums'
import { ExtendedDataNode, NodeType } from 'types/ui'

const debug = Debug('Frontend')

interface TreeAsset extends Store.Asset {
  disabled?: boolean
}

export const removeUndefinedFields = <T>(obj, removeEmptyArray?: boolean) => {
  return Object.keys(obj).reduce((newObj, key) => {
    if (typeof obj[key] !== 'undefined' && obj[key] !== null) {
      if (removeEmptyArray && Array.isArray(obj[key])) {
        if (obj[key].length > 0) {
          newObj[key] = obj[key]
        }
      } else {
        newObj[key] = obj[key]
      }
    }
    return newObj
  }, {}) as T
}

export function jsonToStrategies(json: Array<Api.Strategy>): Array<Store.Strategy> {
  return json.map((strategy) => ({
    id: strategy.id,
    baseCurrency: strategy.base_currency,
    creationUserid: strategy.creation_userid,
    lastModUserid: strategy.last_mod_userid,
    ownerId: strategy.owner_id,
    target: strategy.target,
    name: strategy.name,
    lastModDate: new Date(strategy.last_mod_date),
    creationDate: new Date(strategy.creation_date),
  }))
}

export function jsonToPortfolios(json: Array<Api.Porfolio>): Array<Store.Portfolio> {
  return json.map((portfolio) => ({
    id: portfolio.id,
    strategyId: portfolio.strategy_id,
    name: portfolio.name,
    creationDate: new Date(portfolio.creation_date),
    creationUserId: portfolio.creation_userid,
    lastModDate: new Date(portfolio.last_mod_date),
    maxLong: parseNumber(portfolio.max_long),
    maxNetHedging: parseNumber(portfolio.max_net_hedging),
    minNetHedging: parseNumber(portfolio.min_net_hedging),
    maxShort: parseNumber(portfolio.max_short),
  }))
}

export function jsonToAssets(json: Array<Api.Asset>): Array<Store.Asset> {
  return json
    .map((asset) => {
      const modelClass = asset.model_class
      const masterSecurity = asset.master_security
      const group = asset.asset_groups
      try {
        return {
          bankAccount: asset.bank_account,
          lastModUserid: asset.last_mod_userid,
          longShort: asset.e_long_short.code,
          longShortText: asset.e_long_short.description,
          name: asset.name,
          id: asset.id,
          associationUserId: asset.association_userid,
          securityId: asset.security_id,
          portfolioId: asset.portfolio_id,
          qtyFCountervalue: asset.qty_f_countervalue === 'true',
          sizeVariable: asset.size_variable === 'true',
          modelClassId: asset.model_class_id,
          lastModDate: new Date(asset.last_mod_date),
          minOrderQty: parseNumber(asset.min_order_qty),
          minOrderCountervalue: parseNumber(asset.min_order_countervalue),
          mic: masterSecurity.exchangeByExchange.mic,
          brokerAccounts: asset.asset_brokers.map((b) => ({
            name: b.brokerAccountByBrokerAccount.broker_name,
            account: b.broker_account,
            priority: b.rank_broker_account,
          })),
          refCounterValue: asset.ref_countervalue,
          refCounterValueMax: asset.ref_countervalue_max,
          refCounterValueMin: asset.ref_countervalue_min,
          refQuantity: asset.ref_qty,
          refQuantityMax: asset.ref_qty_max,
          refQuantityMin: asset.ref_qty_min,
          symbol: masterSecurity.symbol,
          market: masterSecurity.exchangeByExchange.custom_code,
          currency: masterSecurity.trading_currency,
          baseCurrency: asset.portfolio.strategy.base_currency,
          instrumentCode: masterSecurity.instrument_code,
          description: {
            contractSize: masterSecurity.contract_size,
            descriptionField: masterSecurity.description,
            instrumentClass: masterSecurity.e_instrument_class.description,
            lotMultiplier: masterSecurity.lot_multiplier,
          },
          group: group[0] && {
            id: group[0].group.id,
            name: group[0].group.name,
          },
          modelClass: modelClass && {
            name: modelClass.model_name,
            description: modelClass.model_description,
            language: modelClass.model_language,
            type: modelClass.model_type,
            version: modelClass.version_number,
          },
          modelAssetParameters: asset.model_asset_parameters.map((p) => ({
            type: p.type,
            value: p.value,
            name: p.name,
            modelClass: p.model_class,
          })),
        }
      } catch (error) {
        console.log(`error mapping asset ${asset.id}-${asset.name}`, error)
        return null
      }
    })
    .filter((el) => el !== null)
}

export function jsonToGroups(json: Array<Api.Group>): Array<Store.Group> {
  return json
    .map((group) => {
      try {
        return {
          id: group.id,
          name: group.name,
          modelId: group.analysis_model_id,
          executionModelId: group.execution_model_id,
          deletionTS: group.deletion_timestamp,
          portfolioId: group.portfolio_id,
          userId: group.portfolio.creation_userid,
          assetIds: group.asset_groups.length
            ? group.asset_groups.map(a => a.asset.id)
            : [],
          assets: group.asset_groups.length
            ? group.asset_groups.map(a => {
              return {
                id: a.asset.id,
                name: a.asset.name,
              }
            })
            : [],
          modelClass: group.asset_groups.length && group.asset_groups[0].group.model_class
          ? {
            id: group.asset_groups[0].group.model_class.id,
            modelName: group.asset_groups[0].group.model_class.model_name,
            modelType: group.asset_groups[0].group.model_class.model_type,
            modelDescription: group.asset_groups[0].group.model_class.model_description,
            version: group.asset_groups[0].group.model_class.version_number,
          }
          : {
            id: null,
            modelName: null,
            modelType: null,
            modelDescription: null,
            version: null,
          },
        }
      } catch (err) {
        throw err
      }
    })
}

export const mergeGroupsAndAssets = (groups: Store.Group[], assets: TreeAsset[], usedResources?: Store.UsedResources): ExtendedDataNode[] => {
  let pureAssetsNotInGroups: TreeAsset[] = []
  let assetsInGroups = []
  let formattedGroups: ExtendedDataNode[] = []

  if (groups.length) {
    formattedGroups = groups.map((group: Store.Group, index: number): ExtendedDataNode => {
      return {
        resource: group,
        key: `group-${group.id}`,
        title: group.name,
        type: NodeType.Group,
        isDeletable: usedResources && !usedResources.groups.includes(group.id),
        children: group.assetIds.map((asset: number) => {
          const child = assets
            .filter((a) => {
              if (a.id === asset) {
                return a
              } else {
                !pureAssetsNotInGroups.includes(a) && pureAssetsNotInGroups.push(a)
              }
            })
            .map((a: TreeAsset) => {
              return {
                resource: a,
                id: a.id,
                key: `asset-${a.id}`,
                title: a.name,
                type: NodeType.Asset,
                disabled: a?.disabled,
                isDeletable: usedResources && !usedResources.assets.includes(a.id),
                children: null,
              }
            })
          assetsInGroups.push(child)
          return child
        }).flat()
      }
    })
  }

  const assetsToProcess = pureAssetsNotInGroups.length ? pureAssetsNotInGroups : assets

  const assetsNotInGroups = assetsToProcess.map((a, i) => {
    return {
      resource: a,
      id: a.id,
      key: `asset-${a.id}`,
      title: a.name,
      type: NodeType.Asset,
      children: null,
      disabled: a?.disabled,
      isDeletable: usedResources && !usedResources.assets.includes(a.id)
    }
  })

  const formattedAssets = assetsNotInGroups.filter(a => !assetsInGroups.flat().find(b => a.id === b.id))

  const groupedAssets = formattedGroups.concat(formattedAssets)

  return sortResourcesNodes(groupedAssets)
}

const getWithUTCTimeZone = (date: string) => {
  return date.endsWith('Z') ? date : `${date}Z`
}

export function getBacktestSessionParams(session: Api.Session): Store.BacktestParams {
  const sessionParams = session.operative_session_parameters

  const startDate = sessionParams?.find((item) => item.name === 'SESSION_BEGIN_DATE') || null
  const backTestStartDateTime = sessionParams?.find((item) => item.name === 'SESSION_RUN_REQUEST_TIMESTAMP') ?? null
  const endDate = sessionParams?.find((item) => item.name === 'SESSION_END_DATE') || null
  const startTime = sessionParams?.find((item) => item.name === 'SESSION_BEGIN_TS') || null
  const endTime = sessionParams?.find((item) => item.name === 'SESSION_END_TS') || null
  const speedup = sessionParams?.find((item) => item.name === 'SESSION_SPEEDUP_RATE') || null
  const delay = sessionParams?.find((item) => item.name === 'SESSION_START_SECONDS_DELAY') || null
  const dataFrequency = sessionParams?.find((item) => item.name === 'SESSION_DATA_GENERATION_FREQUENCY') || null
  const tickByTick = sessionParams?.find((item) => item.name === 'SESSION_DATA_GENERATION_TICK_BY_TICK') || false // Tick by tick default value is false

  return {
    startTime: startTime && moment(getWithUTCTimeZone(startTime.value)),
    endTime: endTime && moment(getWithUTCTimeZone(endTime.value)),
    speedup: speedup && Number(speedup.value),
    endDate: endDate && moment(getWithUTCTimeZone(endDate.value)),
    startDate: startDate && moment(getWithUTCTimeZone(startDate.value)),
    dataFrequency: dataFrequency && dataFrequency.value,
    tickByTick: tickByTick && tickByTick.value === "true",
    delay: delay && parseInt(delay.value),
    backTestStartDateTime: backTestStartDateTime && moment(getWithUTCTimeZone(backTestStartDateTime.value)),
  }
}

export function getVirtualSessionParams(session: Api.Session): Store.VirtualParams {
  const sessionParams = session.operative_session_parameters

  const marketOpen = sessionParams?.find((item) => item.name === 'SESSION_MKT_OPEN_TIME') || null
  const marketClose = sessionParams?.find((item) => item.name === 'SESSION_MKT_CLOSE_TIME') || null
  const maxSize = sessionParams?.find((item) => item.name === 'SESSION_RND_VOLUME_MAX') || null
  const minSize = sessionParams?.find((item) => item.name === 'SESSION_RND_VOLUME_MIN') || null
  const maxVarTradePrice = sessionParams?.find((item) => item.name === 'SESSION_RND_PRICE_VAR') || null
  const tradesConfirmationPercentage = sessionParams?.find((item) => item.name === 'SESSION_OP_PROB') || null
  const rateString = sessionParams?.find((item) => item.name === 'SESSION_EVENT_GEN_DELAY') || null
  const sessionEndTimer =
    sessionParams?.find((item) => item.name === 'SESSION_SCHEDULED_END_TIMESTAMP') || null

  return {
    marketClose: marketClose?.value,
    marketOpen: marketOpen?.value,
    maxSize: maxSize && Number(maxSize.value),
    minSize: minSize && Number(minSize.value),
    maxVarTradePrice: maxVarTradePrice && Number(maxVarTradePrice.value),
    tradesConfirmationPercentage: tradesConfirmationPercentage && Number(tradesConfirmationPercentage.value),
    rate: rateString && Number(rateString.value.match(/\d+/)?.[0]),
    rateUnit: rateString && (rateString.value.match(/[a-zA-Z]+/)?.[0] as TimeUnit),
    sessionEndTimer: sessionEndTimer && moment(sessionEndTimer.value),
  }
}

export function getSessionParams(session: Api.Session): Store.BacktestParams | Store.VirtualParams {
  const type = getSessionType(session.type)
  switch (type) {
    case SessionType.BackTest:
      return getBacktestSessionParams(session)
    case SessionType.Virtual:
      return getVirtualSessionParams(session)

    default:
      return null
  }
}

export function jsonToSessions(json: Array<Api.Session>): Array<Store.Session> {
  return json.map((session) => {
    return {
      id: session.id,
      startTime: getDate(session.start_time),
      endTime: getDate(session.end_time),
      author: session?.userByExecutionUserId?.email_address || '',
      description: session.description,
      ownerId: session?.owner_id,
      ownerEmail: session?.user?.email_address || '',
      name: session.name,
      status: getSessionStatus(session.status),
      type: getSessionType(session.type),
      isHistorical: getDate(session.end_time) ? true : false,
      periodParams: getSessionParams(session),
      lastEvt: session.last_evt_ts && new Date(session.last_evt_ts),
    }
  })
}

export function jsonToLayouts(json: Array<Api.Layout>): Array<Store.Layout> {
  return json.map((layout) => {
    return {
      id: layout.layout_id,
      name: layout.layout_name,
      configuration: JSON.parse(layout.configuration_json),
      creationDate: getDate(layout.creation_date),
      creationUser: layout.userByCreationUser.email_address,
      lastUpdateDate: getDate(layout.last_update_date),
      lastUpdateUser: layout.user.email_address,
    }
  })
}

export function jsonUserToSystemUser(user: Api.User): Store.SystemUser {
  return {
    id: user.id,
    active: user.active,
    blocked: user.email_verified,
    email: user.email,
    name: user.name,
    roles: user.roles as UserRoles[],
    surname: user.surname,
  }
}

export function jsonToSystemUsers(json: Array<Api.SystemUser>): Array<Store.SystemUser> {
  return json.map((user) => ({
    id: user.id,
    active: user.active,
    blocked: user.blocked,
    email: user.email,
    name: user.name,
    roles: null,
    surname: user.surname,
  }))
}

export function createUserFromResponse(formData: Store.SystemUserForm): Store.SystemUser {
  return {
    id: formData.id,
    active: formData.active,
    blocked: formData.active,
    email: formData.email,
    name: formData.name,
    surname: formData.surname,
    roles: formData.roles,
  }
}

export function jsonToSessionAsset(json: Array<Api.SessionAsset>): Array<Store.SessionAsset> {
  return json.map((node) => ({
    id: node.asset_id,
    status: node.status,
    groupId: node?.asset?.asset_groups.length ? node.asset.asset_groups[0].group.id : null, // asset_group can be populated with one obj only
  }))
}

export function jsonToMasterSecurity(json: Array<Api.MasterSecurity>): Array<Store.MasterSecurity> {
  return json.map((item) => ({
    id: item.symbol,
    symbol: item.symbol,
    tradingCurrency: item.trading_currency,
  }))
}

export function jsonToModelClass(json: Array<Api.ModelClass>): Array<Store.ModelClass> {
  return json.map((item) => ({
    id: item.id,
    modelDescription: item.model_description,
    modelLanguage: item.model_language,
    modelName: item.model_name,
    modelType: item.model_type,
    date: new Date(item.commit_timestamp),
    user: item.creation_username,
    version: item.version_number,
  }))
}

export function jsonToBrokerSecurity(json: Array<Api.BrokerSecurity>): Array<Store.BrokerSecurity> {
  return json.map((item) => ({
    brokerName: item.broker_name,
    securityId: item.broker_name,
  }))
}

export function jsonToBrokerAccounts(json: Array<Api.BrokerAccount>): Array<Store.BrokerAccount> {
  return json.map((item) => {
    return ({
      name: item.broker_name,
      account: item.account,
    })
  })
}

export function jsonToModelParameters(json: Array<Api.ModelParameter>): Array<Store.ModelParameter> {
  return json.map((item) => ({
    name: item.name,
    type: item.type,
    value: item.default_value,
    modelClass: item.model_class
  }))
}

export function jsonToGroupModelParameters(json: Array<Api.GroupModelParameter>): Array<Store.ModelParameter> {
  return json.map((item) => ({
    name: item.name,
    type: item.type,
    value: item.value,
    modelClass: item.model_class
  }))
}

export function jsonHistoricalToModelParameters(
  json: Array<Api.ModelParameterHistorical>,
): Array<Store.ModelParameter> {
  return json.map((item) => ({
    name: item.name,
    type: item.type,
    value: item.value,
    modelClass: item.model_class
  }))
}

export function mapToAssetRest(data: Store.AssetForm, portfolioId: number): Api.AssetRest {
  const assetRest = {
    bankAccount: data.bankAccount,
    brokerAccounts: data.brokerAccounts
      ? data.brokerAccounts.map((b) =>  ({
        name: b.account,
        priority: b.priority,
      }))
      : [],
    portfolioId,
    mic: data.mic,
    currency: data.currency,
    longShort: data.longShort,
    name: data.name,
    modelClassId: data.model,
    securityId: data.symbol,
    refQuantity: data.refQuantity,
    refCounterValue: data.refCounterValue,
    refCounterValueMax: data.refCounterValueMax,
    refCounterValueMin: data.refCounterValueMin,
    minOrderCounterValue: data.minOrderCounterValue,
    minOrderQuantity: data.minOrderQuantity,
    refQuantityMax: data.refQuantityMax,
    refQuantityMin: data.refQuantityMin,
    sizeVariable: !!data.sizeVariable,
    qtyFCounterValue: !!data.qtyFCounterValue,
    parameters: Object.keys(data)
      .filter((key) => isStringifiedObject(key))
      .map((key) => {
        const parsedKey = JSON.parse(key)
        return {
          name: parsedKey.name,
          value: data[key],
          type: parsedKey.type,
          modelClassId: data.model,
          modelClass: parsedKey.modelClass, // ANALYSIS or EXECUTION
        }
      }),
  }
  if (assetRest.parameters.length === 0) {
    assetRest.parameters = null
  }

  return removeUndefinedFields(assetRest)
}

export function mapToGroupRest(data: Store.MultiAssetForm, portfolioId: number): Api.GroupRest {
  const GroupRest = {
    portfolioId,
    name: data.name,
    assetIds: data.assetIds,
    analysisModel: {
      id: data.modelId,
      parameters: Object.keys(data)
        .filter((key) => isStringifiedObject(key))
        .map((key) => {
          const parsedKey = JSON.parse(key)
          return {
            name: parsedKey.name,
            value: data[key],
            type: parsedKey.type,
            modelClass: parsedKey.modelClass, // ANALYSIS or EXECUTION
          }
        })
    },
  }
  if (GroupRest.analysisModel.parameters.length === 0) {
    GroupRest.analysisModel.parameters = null
  }

  return removeUndefinedFields(GroupRest)
}


export function mapAssetToAssetForm(asset: Store.Asset): Store.AssetForm {
  return {
    bankAccount: asset.bankAccount,
    brokerAccounts: asset.brokerAccounts.map((b) => ({
      name: b.account,
      priority: b.priority,
    })),
    longShort: asset.longShort,
    mic: asset.mic,
    minOrderCounterValue: asset.minOrderCountervalue,
    currency: asset.currency,
    minOrderQuantity: asset.minOrderQty,
    model: asset.modelClassId,
    name: asset.name,
    symbol: asset.symbol,
    refCounterValue: asset.refCounterValue,
    refCounterValueMax: asset.refCounterValueMax,
    refCounterValueMin: asset.refCounterValueMin,
    refQuantity: asset.refQuantity,
    refQuantityMax: asset.refQuantityMax,
    refQuantityMin: asset.refQuantityMin,
    qtyFCounterValue: asset.qtyFCountervalue,
    sizeVariable: asset.sizeVariable,
    modelParam: asset.modelAssetParameters,
    market: asset.market,
  }
}

export function mapJsonToExchanges(exchanges: Api.Exchange[]): Store.Exchange[] {
  return exchanges.map((exchange) => ({
    code: exchange.code,
    customCode: exchange.custom_code,
    mic: exchange.mic,
  }))
}

const mapNormalDistributionData = (
  json: Api.FrequenceNormalDistribution[],
): Store.FrequenceNormalDistribution[] => {
  if (!json) {
    return null
  }

  return json
    .sort((a, b) => a.bin.lowerLimit - b.bin.lowerLimit)
    .map((item) => {
      const {
        bin: { lowerLimit, upperLimit },
        f,
        nd,
      } = item
      return {
        bin: {
          lowerLimit,
          upperLimit,
        },
        frequence: f,
        normalDistribution: nd,
      }
    })
}

const mapCumulativeDistributionData = (
  json: Api.CumulativeFrequenceNormalDistribution[],
): Store.FrequenceNormalDistribution[] => {
  if (!json) {
    return null
  }

  return json
    .sort((a, b) => a.bin.lowerLimit - b.bin.lowerLimit)
    .map((item) => {
      const {
        bin: { lowerLimit, upperLimit },
        cd,
        cf,
      } = item

      return {
        bin: {
          lowerLimit,
          upperLimit,
        },
        frequence: cf,
        normalDistribution: cd,
      }
    })
}

export const jsonToDistributionStatistics = (
  json: Api.DistributionStatistics,
): Store.DistributionStatistics => {
  const {
    content: {
      binAmplitude,
      cumulativeDistributionAndFrequencyData,
      normalDistributionAndFrequencyData,
      synthesisTable,
    },
  } = json

  const normalDistributionData = mapNormalDistributionData(normalDistributionAndFrequencyData)

  const cumulativeDistributionData = mapCumulativeDistributionData(cumulativeDistributionAndFrequencyData)

  return {
    cumulativeDistributionData,
    normalDistributionData,
    binAmplitude,
    synthesisTable,
  }
}

export const jsonToIndicatorStatistics = (json: Api.IndicatorStatistics): Store.IndicatorStatistics => {
  const { content } = json

  return {
    mean: content?.mean,
    negativeOperations: content?.negativeOperations,
    positiveOperations: content?.positiveOperations,
    sharpeRatio: content?.sharpeRatio,
    standardDeviation: content?.standardDeviation,
    totalOperations: content?.totalOperations,
    maxDrawdown: content?.maxDrawdown,
    totalRetFees: content?.totalRetFees,
    totalRet: content?.totalRet,
    totalTradingRet: content?.totalTradingRet,
  }
}
