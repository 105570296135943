// @ts-ignore
import { Message, Metric } from 'sockets/metrics_report_pb'
import * as Store from 'types/store'
import * as Api from 'types/api'

export const mapUint8ArrayMessage = (metric: Uint8Array): Message.AsObject => {
  const data = Message.deserializeBinary(metric)
  return data.toObject()
}

export const mapUint8ArrayMetric = (metric: Uint8Array): Metric.AsObject => {
  const data = Metric.deserializeBinary(metric)
  return Metric.toObject(false, data)
}

export const mapUint8ArrayToAssetMetrics = (metrics: Uint8Array[]): Metric.AsObject[] => {
  return metrics.map(mapUint8ArrayMetric)
}
export const mapJsonToAssetMetrics = (
  json: Api.AssetMetric[],
  metrics: Metric.AsObject[],
): Store.AssetMetric[] => {
  return json.map(
    ({
      analysisModelName,
      analysisModelVersion,
      assetName,
      portfolioName,
      sessionName,
      strategyName,
      cmpKey,
      status,
    }) => {
      const metric: Metric.AsObject = metrics
        .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
        .find((metric) => metric.key === cmpKey)
      return {
        analysisModelName,
        analysisModelVersion,
        assetName,
        portfolioName,
        sessionName,
        cmpKey,
        strategyName,
        ram: metric?.resource?.ram,
        cpu: metric?.resource?.cpu,
        status: status,
      }
    },
  )
}

export const mapJsonToSessionMetrics = ({
  aggregateMetrics,
  countActiveModels,
  countAsset,
  countPortfolios,
  countStrategies,
  sessionName,
  status,
}: Api.SessionMetricsResponse): Store.SessionMetrics => {
  return {
    sessionName,
    countActiveModels,
    countAsset,
    countPortfolios,
    countStrategies,
    status,
    aggregateMetrics: aggregateMetrics.map((metric) => {
      const data = Metric.deserializeBinary(metric)
      return Metric.toObject(false, data)
    }),
  }
}
