import React, { useEffect, useState } from 'react'
import * as Store from 'types/store'
import { useDispatch, useSelector } from 'react-redux'
import { createFrame, layoutChanged, setDragging, setResizing } from 'store/dashboard/actions'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { FrameType, SessionType, TimeFrame } from 'types/enums'
import RGL from 'react-grid-layout'
import Debug from 'debug'
import { v4 as uuidv4 } from 'uuid'

import FramesPanel from 'v2/components/FramesPanel'
import AddFrameModalContainer from 'v2/containers/settings/modals/AddFrameModal'
import { generateFramesFromLayouts, generateLayout } from 'core/layoutHelper'
import { FrameTemplate } from 'types/ui'
import { selectDashboardId } from 'store/dashboard/selectors'
import { getViewTypeByFrameType } from 'core/frameHelper'

const debug = new Debug('Frontend')

interface Props {
  frames: Store.Frame[]
}

const emptyFrameTemplate: FrameTemplate = {
  frameType: FrameType.Undefined,
  x: 0,
  y: 0,
  width: 1,
  height: 1,
}

const FramesPanelContainer: React.FC<Props> = ({ frames }) => {
  const dispatch = useDispatch()
  const dashboardId = useSelector(selectDashboardId)
  const { type: sessionType } = useSelector(selectOperativeSessionSession)
  const [addFrameVisible, setAddFrameVisibile] = useState(false)
  const timeFrame = sessionType === SessionType.BackTest ? TimeFrame.TOT : TimeFrame.TDY
  const [currentDashboard, setCurrentDashboard] = useState<number>(null)
  const [frameTemplate, setFrameTemplate] = useState(emptyFrameTemplate)

  useEffect(() => {
    if (!dashboardId) {
      return
    }
    setCurrentDashboard(dashboardId)
  }, [dashboardId])

  const onLayoutChanged = (layouts: RGL.Layout[]) => {
    if (layouts.length === 0) {
      setCurrentDashboard(null)
      return
    }
    if (dashboardId !== currentDashboard) {
      //The load of new Dashboard triggers a onLayoutChange, but it's not a real change, so we want to avoid
      return
    }
    if (currentDashboard !== null && currentDashboard !== dashboardId) {
      // Avoid to update layout after a clear dashboard button press
      return
    }
    const newFrames = generateFramesFromLayouts(layouts, frames)

    debug('LAYOUT: ', layouts)
    dispatch(layoutChanged(newFrames))
  }

  const getFrame = (template: FrameTemplate): Store.Frame => {
    const id = uuidv4() as string
    const { frameType, x, y } = template
    return {
      selectedResources: null,
      sessionType: sessionType,
      frameType,
      options: {
        timeFrame,
        viewType: getViewTypeByFrameType(frameType),
      },
      id: uuidv4(),
      layout: generateLayout(id, frameType, x, y),
    }
  }

  const onAddFrame = (template: FrameTemplate) => {
    setFrameTemplate(template)
    const { frameType } = template
    switch (frameType) {
      case FrameType.Custom:
        setAddFrameVisibile(true)
        break
      default:
        const frame = getFrame(template)
        dispatch(createFrame(frame))
        break
    }
  }

  const onDragging = (status: boolean) => {
    dispatch(setDragging(status))
  }

  const onResizing = (frameId: string) => {
    dispatch(setResizing(frameId))
  }

  return (
    <>
      <FramesPanel
        frames={frames}
        onAdd={onAddFrame}
        onLayoutChanged={onLayoutChanged}
        onDragging={onDragging}
        onResizing={onResizing}
      />
      <AddFrameModalContainer
        initialFrame={null}
        visible={addFrameVisible}
        initialX={frameTemplate.x}
        initialY={frameTemplate.x}
        onClose={() => setAddFrameVisibile(false)}
      />
    </>
  )
}

export default FramesPanelContainer
