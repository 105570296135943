import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader, getFetchAuthenticationHeader } from './common'
import * as Store from 'types/store'
const axios = getAxios()

const groupFields = `{
  id
  name
  analysis_model_id
  execution_model_id
  deletion_timestamp
  portfolio_id
  portfolio {
    creation_userid
  }
  asset_groups {
    asset {
      id
      name
    }
    group {
      id
      name
      model_class {
        id
        model_name
        model_type
        model_description
        version_number
        assets {
          id
          name
        }
      }
    }
  }
}`

export async function getGroups(): Promise<Api.GroupsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `{
      application_group(where: {deletion_timestamp: {_is_null: true}}) ${groupFields}
    }`,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function createGroup(data: Api.GroupRest): Promise<Api.GroupCreate | ApiError> {
  const header = await getAuthenticationHeader()

  return axios
    .post(config.api.createGroup(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function deleteGroup(group: Store.Group): Promise<Api.GroupDelete | ApiError> {
  const headers = await getFetchAuthenticationHeader()

  // const payload = {
  //   header,
  //   data: { assetIds: group.assetIds },
  // }

  // return axios
  //   .delete(config.api.deleteGroup(group.id), payload)
  //   .then((response) => response.data)
  //   .catch((error) => getApiError(error))

  // Avoiding 401 error BE was throwing with old Axios call
  return fetch(config.api.deleteGroup(group.id),
    {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify({ assetIds: group.assetIds }),
    })
    .then((response) => {
      const res = response.json()
      return res
    })
    .catch((error) => getApiError(error))
}

export async function getGroup(groupId: number): Promise<Api.GroupsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    _id: groupId,
  }
  const data: GraphQlQuery = {
    query: `query ($_id: Int) {
      application_group(where: {id: {_eq: $_id}}) ${groupFields}
    }`,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function updateGroup(data: Api.GroupRest): Promise<Api.GroupCreate | ApiError> {
  const header = await getAuthenticationHeader()

  const groupId = data.id
  const payload = {...data, reduxHelperId: groupId}
  // const payload = data
  delete payload.id

  return axios
    .put(config.api.updateGroup(groupId), payload, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function fetchAssetsToOverride(groupId: number): Promise<Api.AssetsToOverride | ApiError> {
  const header = await getAuthenticationHeader()

  return axios
    .get(config.api.listParamsToOverride(groupId), header)
    .then((response) => response.data)
    .catch(error => getApiError(error))
}

export async function getGroupModelParameters(id: number): Promise<Api.ModelGroupParametersResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    id,
  }

  const data: GraphQlQuery = {
    query: `query ($id: Int) {
      application_model_group_parameter(where: {group_id: {_eq: $id}}) {
        name
        type
        value
        model_class
      }
    }`,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

