import React, { useMemo } from 'react'
import Debug from 'debug'
import { Collapse, Form, Input, InputNumber, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { ColumnsType } from 'antd/lib/table'
import { CrudOperation } from 'types/enums'

const debug = new Debug('Frontend')
interface Props {
  params: Store.ModelParameter[]
  mode: CrudOperation
}

const ModelParameters: React.FC<Props> = ({ params, mode }) => {
  const { t } = useTranslation()

  const mappedAndSortedParams = useMemo(() => params.map((p, index) => {
    return {
      ...p,
      key: `param_${p.name}_${p.value}_${index}`
    }
  }).sort((a,b) => a.name > b.name ? 1 : -1), [params])

  const analysisParams = useMemo(() => mappedAndSortedParams.filter((p) => p.modelClass === 'ANALYSIS'), [mappedAndSortedParams])
  const executionParams = useMemo(() => mappedAndSortedParams.filter((p) => p.modelClass === 'EXECUTION'), [mappedAndSortedParams])

  const columns: ColumnsType<Store.ModelParameter> = useMemo(() => [
    {
      title: t('table.column.type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('table.column.name'),
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: ((_, {name}) => {
        return <Tooltip title={name}>{name}</Tooltip>
      })
    },
    {
      title: t('table.column.value'),
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (value, { type, name, modelClass }) => {
        if (mode === CrudOperation.Read) {
          return <Tooltip title={value}>{value}</Tooltip>
        }

        const fieldName = {
          name: name,
          type: type,
          modelClass: modelClass,
        }

        const stringifiedFieldName = JSON.stringify(fieldName)

        if (type === 'Integer') {
          return (
            <Form.Item
              initialValue={Number(value)}
              rules={[{ type: 'number' }]}
              name={stringifiedFieldName}
            >
              <InputNumber type={type} />
            </Form.Item>
          )
        }
        if (type === 'List' || type === 'Dictionary') {
          return (
            <Form.Item
              name={stringifiedFieldName}
              initialValue={value}
            >
              <Input type='text' />
            </Form.Item>
          )
        }
        return (
          <Form.Item
            initialValue={value}
            name={stringifiedFieldName}
          >
            <Input type={type} />
          </Form.Item>
        )
      },
    },
  ], [params, mode])

  return (
    <>
      {
        analysisParams.length > 0 && (
          <Collapse expandIconPosition={'end'}>
            <Collapse.Panel header="Model Analysis Parameters" key="analysis-parameters" forceRender>
              <Table dataSource={analysisParams} columns={columns} pagination={false} />
            </Collapse.Panel>
          </Collapse>
        )
      }
      {
        executionParams.length > 0 && (
          <Collapse expandIconPosition={'end'}>
            <Collapse.Panel header="Model Execution Parameters" key="execution-parameters" forceRender>
              <Table dataSource={executionParams} columns={columns} pagination={false} />
            </Collapse.Panel>
          </Collapse>
        )
      }
    </>
  )
}

export default ModelParameters
