// @ts-ignore
import { update_snapshot, update_ticker } from 'sockets/assets/asset_models_pb'
import * as Store from 'types/store'
import { convertToFloat, formatSigmaDate } from '../common'

export const mapAssetModelsSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.AssetSigmas => {
  const { sigmas, key } = feed
  if (!sigmas) {
    return null
  }

  const { modelAlgo, modelSignal, modelOrder } = sigmas

  return {
    modelAlgo: modelAlgo && {
      modelType: modelAlgo?.modelType,
      groupName: modelAlgo?.groupName,
      modelDescription: modelAlgo.modelDescription,
      modelId: modelAlgo.modelId,
      modelName: modelAlgo.modelName,
      modelStatus: modelAlgo.modelStatus,
      modelVersion: modelAlgo.modelVersion,
    },
    modelSignal: modelSignal && {
      key: parseInt(key),
      price: convertToFloat(modelSignal?.price),
      quantity: convertToFloat(modelSignal?.quantity) || 0,
      sign: modelSignal?.sign,
      time: formatSigmaDate(modelSignal?.time),
    },
    modelOrder: modelOrder && {
      key: parseInt(key),
      price: convertToFloat(modelOrder.price),
      quantity: convertToFloat(modelOrder.quantity) || 0,
      sign: modelOrder.sign,
      time: formatSigmaDate(modelOrder.time),
    },
  } as Store.AssetSigmas
}
