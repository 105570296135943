import React from 'react'
import { SessionStatus } from 'types/enums'
import BacktestSessionParameters from 'v2/components/dashboard-players/BacktestPlayer/Parameters'
import DahsboardPlayerControls from 'v2/components/dashboard-players/BacktestPlayer/BacktestPlayerControls'
import * as Forms from 'types/form'
import { Control } from 'react-hook-form'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'

import styles from './styles.module.scss'
import { Progress, Button, Avatar, Tooltip, Typography, Row } from 'antd'
import ExpandIcon from 'assets/icon/expand-icon.svg'
import CollapseIcon from 'assets/icon/collapse-icon.svg'
import { useTranslation } from 'react-i18next'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'
import { getSessionStatusLabel } from 'helpers/sessionStatusHelper'

interface Props {
  showParams: boolean
  control: Control<Forms.BacktestPlayer>
  onClick: (status: SessionStatus) => void
  toggleParamsVisibility: () => void
  loading: boolean
  progress: number | string
  backtestTime: string
}

const DashboardPlayerContainer: React.FC<Props> = ({
  showParams,
  control,
  onClick,
  toggleParamsVisibility,
  loading,
  progress,
  backtestTime,
}) => {
  const session = useSelector(selectOperativeSessionSession)
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const playerClass = classNames(styles['dashboard-player'], themeStyles['theme'])

  return (
    <section className={playerClass}>
      {showParams && <BacktestSessionParameters session={session} control={control} loading={loading} />}

      <div className="controllers-container">
        {(session.status === SessionStatus.Running || session.isHistorical) && (
          <div className="progress-bar-wrapper">
            <Row justify={'space-between'} align={'middle'}>
              <Typography.Paragraph>
                {progress === 100
                  ? 'Backtest Completed at:'
                  : session.isHistorical
                    ? `Backtest ${getSessionStatusLabel(session.status)} at:`
                    : 'Current Backtest Time:'
                }
              </Typography.Paragraph>
              <Typography.Text>{backtestTime}</Typography.Text>
            </Row>
            <Progress
              strokeWidth={5}
              trailColor={'#989eac'}
              strokeColor={'#00ffb1'}
              percent={progress as number}
              showInfo={session.status !== SessionStatus.Broken && session.status !== SessionStatus.Stopped}
              status={progress === 100 && session.status === SessionStatus.Completed
                ? 'success'
                : session.isHistorical && progress === 0
                  ? 'normal'
                  : 'active'
              }
            />
          </div>
        )}

        <DahsboardPlayerControls onClick={onClick} session={session} loading={loading} />

        <Button
          className={classNames('toggle-btn', { 'toggle-btn--expanded': showParams })}
          onClick={toggleParamsVisibility}
        >
          <Avatar shape="square" src={showParams ? CollapseIcon : ExpandIcon} size={15} />
        </Button>
      </div>
    </section>
  )
}

export default DashboardPlayerContainer
