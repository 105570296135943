import i18n from 'i18next'
import { TableType } from 'types/store'

export const getColumns = (tableType: TableType, bc?: string) => {
  const placeholderBc = bc // This empty placeholder is needed to save i18n infinite console warning
  switch (tableType) {
    case 'strategy':
      return [
        { title: i18n.t('table.column.baseCurrency'), key: 'baseCurrency', shortName: 'Ref. Ccy' },
        { title: i18n.t('table.column.assets'), key: 'assetsNum', shortName: '# Ast' },
        { title: i18n.t('table.column.portfolios'), key: 'portfoliosNum', shortName: '# Prt' },
        { title: i18n.t('table.column.countervalue', { baseCurrency: placeholderBc }), key: 'value', shortName: 'Ctv' },
        { title: i18n.t('table.column.longCountervalue'), key: 'valueLong', shortName: 'LNG Ctv' },
        { title: i18n.t('table.column.shortCountervalue'), key: 'valueShort', shortName: 'SHT Ctv' },
        { title: i18n.t('table.column.tdyTradingReturn'), key: 'tdyTradingRetBc', shortName: `TDY Trd Ret` },
        { title: i18n.t('table.column.tdyExchangeRatesReturn'), key: 'tdyExchRetBc', shortName: `TDY Exch Rate Ret` },
        { title: i18n.t('table.column.tdyTotalReturn'), key: 'tdyAllRetBc', shortName: `TDY Tot Ret` },
        { title: i18n.t('table.column.ytdTradingReturn'), key: 'ytdTradingRetBc', shortName: `YTD Trd Ret` },
        { title: i18n.t('table.column.ytdExchangeRatesReturn'), key: 'ytdExchRetBc', shortName: `YTD Exch Rate Ret` },
        { title: i18n.t('table.column.ytdTotalReturn'), key: 'ytdAllRetBc', shortName: `TDY Tot Ret` },
        { title: i18n.t('table.column.tdyPositiveOperations'), key: 'tdyPositiveOp', shortName: 'TDY Pos Op' },
        { title: i18n.t('table.column.tdyNegativeOperations'), key: 'tdyNegativeOp', shortName: 'TDY Neg Op' },
        { title: i18n.t('table.column.ytdTotalOperations'), key: 'tdyTotalOp', shortName: 'YTD Tot Op' },
        { title: i18n.t('table.column.ytdPositiveOperations'), key: 'ytdPositiveOp', shortName: 'YTD Pos Op' },
        { title: i18n.t('table.column.ytdNegativeOperations'), key: 'ytdNegativeOp', shortName: 'YTD Neg Op' },
      ]
    case 'portfolio':
    case 'strategy-portfolios':
      return [
        tableType === 'portfolio' && { title: i18n.t('table.column.strategyName'), key: 'strategyName', shortName: 'Strategy' },
        tableType === 'portfolio' && { title: i18n.t('table.column.baseCurrency'), key: 'baseCurrency', shortName: 'Ref. Ccy' },
        { title: i18n.t('table.column.assets'), key: 'assetsNum', shortName: '# Ast' },
        { title: i18n.t('table.column.countervalue', { baseCurrency: placeholderBc }), key: 'value', shortName: `Ctv ${placeholderBc}` },
        { title: i18n.t('table.column.longCountervalue'), key: 'valueLong', shortName: 'LNG Ctv' },
        { title: i18n.t('table.column.shortCountervalue'), key: 'valueShort', shortName: 'SHT Ctv' },
        { title: i18n.t('table.column.tdyTradingReturn'), key: 'tdyTradingRetBc', shortName: `TDY Trd Ret` },
        { title: i18n.t('table.column.tdyExchangeRatesReturn'), key: 'tdyExchRetBc', shortName: `TDY Exch Rate Ret` },
        { title: i18n.t('table.column.tdyTotalReturn'), key: 'tdyAllRetBc', shortName: `TDY Tot Ret` },
        { title: i18n.t('table.column.ytdTradingReturn'), key: 'ytdTradingRetBc', shortName: `YTD Trd Ret` },
        { title: i18n.t('table.column.ytdExchangeRatesReturn'), key: 'ytdExchRetBc', shortName: `YTD Exch Rate Ret` },
        { title: i18n.t('table.column.ytdTotalReturn'), key: 'ytdAllRetBc', shortName: `TDY Tot Ret` },
        { title: i18n.t('table.column.tdyPositiveOperations'), key: 'tdyPositiveOp', shortName: 'TDY Pos Op' },
        { title: i18n.t('table.column.tdyNegativeOperations'), key: 'tdyNegativeOp', shortName: 'TDY Neg Op' },
        { title: i18n.t('table.column.ytdTotalOperations'), key: 'tdyTotalOp', shortName: 'YTD Tot Op' },
        { title: i18n.t('table.column.ytdPositiveOperations'), key: 'ytdPositiveOp', shortName: 'YTD Pos Op' },
        { title: i18n.t('table.column.ytdNegativeOperations'), key: 'ytdNegativeOp', shortName: 'YTD Neg Op' },
      ].filter((column) => column)
    case 'asset':
      return [
        { title: i18n.t('table.column.lastPrice'), key: 'lastPrice', shortName: 'Last' },
        { title: i18n.t('table.column.instrumentIsin'), key: 'instrumentIsin', shortName: 'Isin' },
        { title: 'Group', key: 'groupName', shortName: 'Group' }, // JI-TRANSLATE
        { title: i18n.t('table.column.model'), key: 'modelName', shortName: 'Model' },
        { title: 'Model Version', key: 'modelVersion', shortName: 'M Version' }, // JI-TRANSLATE
        { title: i18n.t('table.column.modelStatus'), key: 'modelStatus', shortName: 'M Status' },
        { title: 'Model Type', key: 'modelType', shortName: 'M Type' }, // JI-TRANSLATE
        { title: i18n.t('table.column.lastPosition'), key: 'lastPosition', shortName: 'Pos Sign' },
        { title: i18n.t('table.column.quantity'), key: 'quantity', shortName: 'Qty' },
        { title: i18n.t('table.column.countervalue', { baseCurrency: placeholderBc }), key: 'value', shortName: `Ctv ${placeholderBc}` },
        { title: i18n.t('table.column.tdyTradingReturn'), key: 'tdyTradingRetBc', shortName: `TDY Trd Ret` },
        { title: i18n.t('table.column.tdyTradingReturnPercentage'), key: 'tdyPercRet', shortName: 'TDY Trd Ret %' },
        { title: i18n.t('table.column.ytdTradingReturn'), key: 'ytdTradingRetBc', shortName: `YTD Trd Ret` },
        { title: i18n.t('table.column.ytdExchangeRatesReturn'), key: 'ytdExchRetBc', shortName: `YTD Exch Rate Ret` },
        { title: i18n.t('table.column.ytdTotalReturn'), key: 'ytdAllRetBc', shortName: `YTD Tot Ret` },
        { title: `YTD Total Ret Fees ${placeholderBc}`, key: 'allRetBcFees', shortName: `YTD Tot Ret Fees ${placeholderBc}` }, // JI-TRANSLATE
        { title: i18n.t('table.column.ytdTradingReturnPercentage'), key: 'ytdPercRet', shortName: 'YTD Trd Ret %' },
        { title: i18n.t('table.column.lastOpenTdyTradingReturn'), key: 'opTdyTradRetBc', shortName: `Open Op TDY Trd Ret` },
        { title: i18n.t('table.column.lastOpenTdyReturnPercetage'), key: 'opTdyPercRet', shortName: 'Open Op YTD Trd Ret %' },
        { title: i18n.t('table.column.tdyTotalOperations'), key: 'tdyTotalOp', shortName: 'TDY Tot Op' },
        { title: i18n.t('table.column.tdyPositiveOperations'), key: 'tdyPositiveOp', shortName: 'TDY Pos Op' },
        { title: i18n.t('table.column.tdyNegativeOperations'), key: 'tdyNegativeOp', shortName: 'TDY Neg Op' },
        { title: i18n.t('table.column.ytdTotalOperations'), key: 'ytdTotalOp', shortName: 'YTD Tot Op' },
        { title: i18n.t('table.column.ytdPositiveOperations'), key: 'ytdPositiveOp', shortName: 'YTD Pos Op' },
        { title: i18n.t('table.column.ytdNegativeOperations'), key: 'ytdNegativeOp', shortName: 'YTD Neg Op' },
      ]
  }
}
