import React, { useCallback } from 'react'
import ReactGridLayout, { Layout, DragOverEvent } from 'react-grid-layout'
import * as Store from 'types/store'
import RGL from 'react-grid-layout'
import classNames from 'classnames'

import styles from './styles.module.scss'
import EmptyGridItem from 'v2/containers/frames/EmptyGridItem'
import { FrameType } from 'types/enums'
import MapView from 'v2/containers/frames/MapView'
import { FrameTemplate, NodeType } from 'types/ui'
import { CELL_HEIGHT, CELL_WIDTH } from 'services/dashboardService'
import ForwardAnalysis from 'v2/containers/frames/ForwardAnalysis'
import ScrollContainer from 'react-indiana-drag-scroll'
import Statistics from 'v2/containers/frames/Statistics'
import StatisticsChartless from 'v2/short-term/containers/Statistics'
import AssetCandleStickBySession from 'v2/containers/Wrapper/AssetCandleStickFrame/AssetCandleStickBySession'
import { selectHeight } from 'store/dashboard/selectors'
import { useSelector } from 'react-redux'
import StrategyCandleStickBySession from 'v2/containers/Wrapper/StrategyCandleStickFrame/CandleStickFrameView'
import PortfolioCandleStick from 'v2/containers/Wrapper/PortfolioCandleStickFrame/FrameView'
import TechnicalChart from 'v2/containers/frames/TechnicalChart'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'
import PositionMonitoringFrame from 'v2/containers/Wrapper/PositionMonitoringFrame'
import ReturnAnalysisFrame from 'v2/containers/Wrapper/ReturnPositionFrame'
import PortfolioTableFrame from 'v2/containers/Wrapper/PortfolioTableFrame'
import StrategyTableFrame from 'v2/containers/Wrapper/StrategyTableFrame'
import SignalsTableFrame from 'v2/containers/Wrapper/SignalsTableFrame'
import Debug from 'debug'

const debug = new Debug('frontend')

interface Props {
  frames: Store.Frame[]
  onLayoutChanged: (layouts: RGL.Layout[]) => void
  onAdd: (item: FrameTemplate) => void
  onDragging: (status: boolean) => void
  onResizing: (frameId: string) => void
}

const FramesPanel: React.FC<Props> = ({ frames, onLayoutChanged, onAdd, onDragging, onResizing }) => {
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const COL_NUMBER = 100
  const height = useSelector(selectHeight)

  const panelClass = classNames(styles['frames-panel'], themeStyles['theme'])

  const renderItem = useCallback((frame: Store.Frame, layout: RGL.Layout) => {
    // console.log('FramesPanel', frame, layout)
    const { selectedResources, frameType } = frame

    switch (frameType) {
      case FrameType.MapView:
        return <MapView frame={frame} />
      case FrameType.Chart:
        const { type } = selectedResources[0]
        if (type === NodeType.Asset) {
          return <AssetCandleStickBySession frame={frame} />
        }
        if (type === NodeType.Portfolio) {
          return <PortfolioCandleStick frame={frame} />
        }
        if (type === NodeType.Strategy) {
          return <StrategyCandleStickBySession frame={frame} />
        }
        return null
      case FrameType.Table: {
        const { type } = selectedResources[0]
        if (type === NodeType.Portfolio) {
          return <PortfolioTableFrame frame={frame} />
        }
        if (type === NodeType.Strategy) {
          return <StrategyTableFrame frame={frame} />
        }
        break
      }
      case FrameType.TechnicalChart:
        return <TechnicalChart frame={frame} />
      case FrameType.SignalsTable:
        return <SignalsTableFrame frame={frame} />
      case FrameType.PositionMonitoring:
        return <PositionMonitoringFrame frame={frame} />
      case FrameType.ReturnAnalysis:
        return <ReturnAnalysisFrame frame={frame} />
      case FrameType.ForwardAnalysis:
        return <ForwardAnalysis frame={frame} />
      case FrameType.Statistics:
        return <Statistics frame={frame} />
      case FrameType.StatisticsChartless:
        return <StatisticsChartless frame={frame} />
      default:
        return <EmptyGridItem frame={frame} layout={layout} />
    }
  }, [])

  const grid = React.useMemo(() => {
    return frames.map((frame) => {
      const { layout } = frame

      return (
        <div key={frame.id} className={`frame`} data-grid={layout}>
          {renderItem(frame, layout)}
        </div>
      )
    })
  }, [frames])

  // *************************
  // a click trigger a dragStart but it never fires the dragStop
  // https://github.com/react-grid-layout/react-grid-layout/issues/1259
  const handleDragStart = useCallback(() => {
    window.addEventListener('mouseup', handleDragEnd)
    onDragging(true)
  }, [])

  const handleDragEnd = useCallback(() => {
    window.removeEventListener('mouseup', handleDragEnd)
    onDragging(false)
  }, [])
  // *************************

  return (
    <section className={panelClass}>
      <ScrollContainer
        // FH: 08/09/2022 - Do not change the classname as antd flying dropdowns were fixed by binding them to this container.
        className="draggable-grid-section JI-RENDER-frames-grid"
        style={{ height: height ?? '100vh', width: '100vw' }}
        hideScrollbars={false}
        ignoreElements=".react-grid-item"
      >
        <ReactGridLayout
          draggableCancel=".nonDraggable"
          className="layout"
          cols={COL_NUMBER}
          rowHeight={CELL_HEIGHT}
          isBounded={true}
          compactType={'vertical'}
          droppingItem={{ i: 'default', w: 6, h: 6 }}
          onDragStart={handleDragStart}
          onDragStop={() => onDragging(false)}
          onDrop={(layout, item, e) => {
            try {
              const { dataTransfer } = e as DragEvent
              const dummyFrame = JSON.parse(dataTransfer.types[0]) as FrameTemplate
              const template = { ...dummyFrame, ...{ x: item.x, y: item.y } }
              onAdd(template)
              onDragging(false)
            } catch {
              debug('Drop error')
            }
          }}
          onDropDragOver={(e: DragOverEvent) => {
            try {
              const { dataTransfer } = e as any
              const dummyFrame = JSON.parse(dataTransfer.types[0]) as FrameTemplate
              return { w: dummyFrame.width, h: dummyFrame.height }
            } catch {
              debug('DragOver error')
              return { w: 0, h: 0 }
            }
          }}
          onResize={(layout: Layout[], item: Layout) => onResizing(item.i)}
          onResizeStop={() => onResizing(null)}
          preventCollision={false}
          width={CELL_WIDTH * COL_NUMBER}
          margin={[5, 5]}
          resizeHandles={['se']}
          // resizeHandles={['se', 'e', 's']}
          onLayoutChange={onLayoutChanged}
          isDroppable={true}
          style={{ minHeight: '100%' }}
        >
          {grid}
        </ReactGridLayout>
      </ScrollContainer>
    </section>
  )
}

export default FramesPanel
