import React from 'react'
import { Row, Col, Typography, Divider, Space, Table } from 'antd'
import AppContentCard from 'components/AppContentCard'
import * as Store from 'types/store'
import { useTranslation } from 'react-i18next'
import { GroupModelParametersContainer } from 'containers/StrategyViewer/AssetForm/GroupModelParameters'
import { CrudOperation } from 'types/enums'

const { Title, Paragraph } = Typography

type Props = {
	group: Store.Group,
}

export const ReadGroup: React.FC<Props> = ({ group }) => {

  return (
    <AppContentCard fullHeight>
      <Row align="middle" className="col-title">
        <Col span={24}>
          <Title>Group Parameters</Title> {/* JI-TRANSLATE */}
        </Col>
      </Row>

      <section className="inner-content">
        <Row align="middle" gutter={[5, 0]} justify="space-between">
          <Col span={12}>
            <Paragraph>Name</Paragraph> {/* JI-TRANSLATE */}
          </Col>
          <Col span={12} className="align-right">
            <Paragraph>{group.name}</Paragraph>
          </Col>
        </Row>
        <Divider />

        <Row align="middle" gutter={[5, 0]} justify="space-between">
          <Col span={12}>
            <Paragraph>Model Name</Paragraph> {/* JI-TRANSLATE */}
          </Col>
          <Col span={12} className="align-right">
            <Paragraph>{group.modelClass.modelName}</Paragraph>
          </Col>
        </Row>
        <Divider />

        <Row align="middle" gutter={[5, 0]} justify="space-between">
          <Col span={12}>
            <Paragraph>Model Version</Paragraph> {/* JI-TRANSLATE */}
          </Col>
          <Col span={12} className="align-right">
            <Paragraph>{group.modelClass.version}</Paragraph>
          </Col>
        </Row>
        <Divider />

        <Row align="middle" gutter={[5, 0]} justify="space-between">
          <Col span={12}>
            <Paragraph>Model Type</Paragraph> {/* JI-TRANSLATE */}
          </Col>
          <Col span={12} className="align-right">
            <Paragraph>{group.modelClass.modelType}</Paragraph>
          </Col>
        </Row>
        <Divider />

        <Row align="middle" gutter={[5, 0]} justify="space-between">
          <Col span={12}>
            <Paragraph>Model Description</Paragraph> {/* JI-TRANSLATE */}
          </Col>
          <Col span={12} className="align-right">
            <Paragraph>{group.modelClass.modelDescription}</Paragraph>
          </Col>
        </Row>
        <Divider />

        <GroupModelParametersContainer
          groupId={group.id}
          mode={CrudOperation.Read}
        />

        <Title level={3}>Group's Assets</Title> {/* JI-TRANSLATE */}
        <Row style={{ height: 300, overflow: 'scroll' }}>
          <Table
            size="small"
            columns={[
              {title: '', dataIndex: 'name', key: 'name'},
              // {title: 'Asset Symbol', dataIndex: 'symbol', key: 'symbol'},
            ]}
            dataSource={group.assets.map(a => {
              return {
                ...a,
                key: `group-asset-${a.id}`
              }
            })}
            pagination={false}
            className="table-assets-list"
          />
        </Row>
      </section>
    </AppContentCard>
	)
}
