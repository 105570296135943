import React, { memo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import DashboardSelector from 'v2/components/DashboardSelector'
import { selectLayouts } from 'store/layouts/selectors'
import { Versions } from 'types/enums'
import { filterbySessionType, filterByVersion } from 'services/dashboardService'
import * as Store from 'types/store'
import { clearLayout, loadLayout } from 'store/dashboard/actions'
import { deleteLayout } from 'store/layouts/actions'

type Props = {
  session: Store.Session
}

const DashboardSelectorContainer: React.FC<Props> = ({ session }) => {
  const dispatch = useDispatch()
  const { data: layouts } = useSelector(selectLayouts)

  if (!session) {
    return null
  }

  const onApplyLayout = (layout: Store.Layout) => {
    if (!layout) {
      dispatch(clearLayout())
      return
    }
    dispatch(loadLayout(layout))
  }

  const onDeleteLayout = (id: number) => {
    dispatch(deleteLayout(id))
  }

  //Ensure display only layout for current version
  const filteredLayoutsbyVersion = filterByVersion(layouts, Versions.V2)
  //Ensure display only layout for current session type
  const filteredLayoutsbySessionType = filterbySessionType(filteredLayoutsbyVersion, session.type)

  return (
    <DashboardSelector
      layouts={filteredLayoutsbySessionType}
      onSelect={onApplyLayout}
      onDelete={onDeleteLayout}
    />
  )
}

DashboardSelectorContainer.displayName = 'DashboardSelectorContainer'

export default memo(DashboardSelectorContainer)
