import * as Store from 'types/store'

import { getAxios } from './apiService'
import { config } from 'core'
import { ReportResponse } from 'types/api/reports'
import { getApiError, getAuthenticationHeader } from './common'
import { SessionType, TimeFrame } from 'types/enums'

const axios = getAxios()

export async function getSessionReport(
  session: Store.Session,
  timeFrame: TimeFrame,
  timezone: string
): Promise<ReportResponse | ApiError> {
  const header = await getAuthenticationHeader()
  header.responseType = 'blob'

  const payload = {
    sessionId: session.id,
    period: timeFrame,
    timeZone: timezone,
  }

  return axios
    .post(config.api.getSessionReport(session.type), payload, header)
    .then((response) => {
      const filenameHeader = response.headers['content-disposition'] ?? `filename="report-${session.type}-${new Date().toISOString()}.pdf"`
      // Filename is the string within the double quotes
      const filename = filenameHeader.match(/(?<=")(.*?)(?=")/g)
      return {
        data: response.data,
        filename: filename[0],
      }
    })
    .catch((error) => getApiError(error))
}

export async function getVirtualSessionReport(
  sessionId: number,
  assetIds: number[],
  timezone: string
): Promise<ReportResponse | ApiError> {
  const header = await getAuthenticationHeader()
  header.responseType = 'blob'

  const payload = {
    sessionId: sessionId,
    assetIds: assetIds,
    timeZone: timezone,
  }

  return axios
    .post(config.api.getSessionReport('virtual' as SessionType), payload, header)
    .then((response) => {
      const filenameHeader = response.headers['content-disposition'] ?? `filename="report-virtual-${new Date().toISOString()}.pdf"`
      // Filename is the string within the double quotes
      const filename = filenameHeader.match(/(?<=")(.*?)(?=")/g)
      return {
        data: response.data,
        filename: filename[0],
      }
    })
    .catch((error) => getApiError(error))
}