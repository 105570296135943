import { Button, Select } from 'antd'
import { getSessionReport } from 'api/reports'
import { isApiError } from 'core'
import { showDetailedError } from 'core/helpers'
import styles from './styles.module.scss'
import FileDownload from 'js-file-download'
import React, { useState } from 'react'
import { TimeFrame } from 'types/enums'
import * as Api from 'types/api'
import * as Store from 'types/store'


type Props = {
  session: Store.Session
}

export const DashboardReportButton: React.FC<Props> = ({ session }) => {
  const [loading, setLoading] = useState(false)
  const [timeFrame, setTimeFrame] = useState(TimeFrame.TDY)

  const options = [
    { label: 'TDY', value: TimeFrame.TDY },
    { label: 'YTD', value: TimeFrame.YTD },
  ]

  const onDownloadClick = async () => {
    setLoading(true)
    const res = await getSessionReport(session, timeFrame, "CET")

    if (isApiError(res)) {
      showDetailedError(
        res?.data?.result?.responseEnum ?? 'BAD Request',
        res?.data?.result?.description ?? 'Error downloading report'
      )
      setLoading(false)
      return
    }

    FileDownload(res.data as Blob, (res as Api.ReportResponse).filename)
    setLoading(false)
  }

  return (
    <div className={`${styles['export-report-button']}`}>
      <Button onClick={onDownloadClick} size="small" disabled={loading}>Export Report</Button>
      <Select value={timeFrame} onChange={(e) => setTimeFrame(e)} options={options} loading={loading} size="small"></Select>
    </div>
  )
}
