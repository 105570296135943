import React, { useState } from 'react'
import { Avatar, Button, Menu, Popover } from 'antd'
import ActionBtnIcon from 'assets/icon/actions-icon.svg'
import ActionBtnActiveIcon from 'assets/icon/actions-icon-active.svg'
import { GridItemActions } from 'types/ui'
import { selectPopupContainer } from 'core/helpers'

type DropdownItem = {
  key: GridItemActions
  label: string
  icon?: React.ReactNode
  disabled?: boolean
}

interface Props {
  onMenuItemClick: (action: GridItemActions) => void
  items: DropdownItem[]
  isFullscreen: boolean
}

const ContextMenu: React.FC<Props> = ({ onMenuItemClick, items, isFullscreen }) => {
  const [isActive, setIsActive] = useState<boolean>(false)

  const menu = items.map(
    (item) =>
      item && {
        key: item.key,
        label: item.label,
        // icon: item.icon,
        onClick: () => {
          onMenuItemClick(item.key)
          setIsActive(false)
        },
        disabled: item.disabled,
      }
  )

  const OptionsList = (
    <Menu items={menu}>
    </Menu>
  )

  return (
    <Popover
      overlayClassName="options-popover nonDraggable"
      placement="rightTop"
      content={OptionsList}
      trigger="click"
      getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
      onOpenChange={() => setIsActive(!isActive)}
      open={isActive}
      zIndex={1000}
    >
      <Button type="ghost" className="action-btn nonDraggable">
        <Avatar shape="square" src={isActive ? ActionBtnActiveIcon : ActionBtnIcon} size={20} />
      </Button>
    </Popover>
  )
}
export default ContextMenu
