import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'

import { Table, Typography, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { OpenOperationType } from 'types/enums'

import colors from 'config/colors'
import { FULL_DATE_FORMAT } from 'core/formats'
import { translateInstruments, translateOrderSign } from 'helpers/common'
import { compareByDate } from 'helpers/tableHelpers'
import { withVisible } from 'components/hoc'
import { JIVirtualTable } from 'v2/components/tables/JITable'

const TOP_OFFSET = 105

interface Props {
  data: Array<Store.SignalsData>
  height: number
  width?: number
  frame?: Store.Frame
}

const SignalsTable: React.FC<Props> = ({ data, height, width, frame }) => {
  const { t } = useTranslation()
  const tableHeight = height - TOP_OFFSET

  const getSignal = useCallback((status: OpenOperationType) => {
    switch (status) {
      case OpenOperationType.Flat: {
        return <Tag color={colors.standByFlat}>{t('assets.flat')}</Tag>
      }
      case OpenOperationType.Long: {
        return <Tag color={colors.standByLong}>{t('assets.long')}</Tag>
      }
      case OpenOperationType.Short: {
        return <Tag color={colors.standByShort}>{t('assets.short')}</Tag>
      }
      default:
        return null
    }
  }, [])

  // const customizeRenderEmpty = () => (
  //   <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('error.noData')} />
  // )

  // const TABLE_COLUMNS: ColumnsType<Store.SignalsData> = [
  //   {
  //     title: '',
  //     children: [
  //       {
  //         title: t('table.column.assetName'),
  //         dataIndex: 'name',
  //         width: 199,
  //         fixed: 'left',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Model Position',
  //     className: 'signal-col',

  //     children: [
  //       {
  //         title: t('table.column.sign'),
  //         dataIndex: ['modelSignal', 'sign'],
  //         width: 140,
  //         render: (signal) => getSignal(signal),
  //       },
  //       {
  //         title: t('table.column.quantity'),
  //         dataIndex: ['modelSignal', 'quantity'],
  //         width: 140,
  //       },
  //       {
  //         title: t('table.column.price'),
  //         dataIndex: ['modelSignal', 'price'],
  //         width: 140,
  //         render: (price) => price,
  //       },
  //       {
  //         title: t('table.column.date'),
  //         dataIndex: ['modelSignal', 'time'],
  //         width: 230,
  //         defaultSortOrder: 'descend',
  //         sorter: (a, b) => compareByDate(a.modelSignal?.time, b.modelSignal?.time),
  //         // eslint-disable-next-line react/display-name
  //         render: (signalDate) => (
  //           <Typography.Text>{signalDate && moment(signalDate).format(FULL_DATE_FORMAT)}</Typography.Text>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Model Order',
  //     className: 'order-col',
  //     children: [
  //       {
  //         title: t('table.column.sign'),
  //         dataIndex: ['modelOrder', 'sign'],
  //         width: 140,
  //         render: (text) => translateOrderSign(text),
  //       },
  //       {
  //         title: t('table.column.quantity'),
  //         dataIndex: ['modelOrder', 'quantity'],
  //         width: 140,
  //       },
  //       {
  //         title: t('table.column.price'),
  //         dataIndex: ['modelOrder', 'price'],
  //         width: 140,
  //         render: (value: number) => value,
  //       },
  //       {
  //         title: t('table.column.date'),
  //         dataIndex: ['modelOrder', 'time'],
  //         width: 230,
  //         // eslint-disable-next-line react/display-name
  //         render: (signalDate) => (
  //           <Typography.Text>{signalDate && moment(signalDate).format(FULL_DATE_FORMAT)}</Typography.Text>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     title: '',
  //     children: [
  //       {
  //         title: t('table.column.isinCode'),
  //         dataIndex: ['securityRefData', 'instrumentIsin'],
  //         width: 230,
  //       },
  //       {
  //         title: t('table.column.currency'),
  //         dataIndex: ['securityRefData', 'tradingCurrency'],
  //         width: 140,
  //       },
  //       {
  //         title: t('table.column.market'),
  //         dataIndex: ['securityRefData', 'marketCode'],
  //         width: 140,
  //       },
  //       {
  //         title: t('table.column.assetClass'),
  //         dataIndex: ['securityRefData', 'instrumentClass'],
  //         width: 140,
  //         render: (text) => translateInstruments(text),
  //       },
  //     ],
  //   },
  // ]

  // Convert Table Columns to Tanstack Columns
  const columns = useMemo(() => [
    // {
    //   id: 'emptyGroup1',
    //   header: 'emtpy group',
    //   columns: [
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.assetName')}>Asset</Tooltip>,
          accessorKey: 'name',
          id: 'assetName',
          size: 199,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          // fixed: 'left',
          meta: {
            filterVariant: 'text'
          },
          sorter: 'includesString' as any,
        },
    //   ]
    // },
    // {
    //   id: 'emptyGroup2',
    //   title: 'Model Position',
    //   columns: [
        // Model Signal
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.sign')}>M Sign</Tooltip>,
          accessorKey: 'modelSignal.sign',
          accessorFn: (row) => row.modelSignal?.sign ?? null,
          id: 'modelSignalsign',
          size: 90,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          meta: {
            filterVariant: 'select',
          },
          sorter: 'includesString' as any,  
          cell: (signal) => getSignal(signal.getValue()),
        },
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.quantity')}>M Qty</Tooltip>,
          accessorKey: 'modelSignal.quantity',
          accessorFn: (row) => row.modelSignal?.quantity ?? null,
          id: 'modelSignalquantity',
          size: 60,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          cell: (value) => <div className="num-right">{value.getValue()}</div>
        },
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.price')}>M Price</Tooltip>,
          accessorKey: 'modelSignal.price',
          accessorFn: (row) => row.modelSignal?.price ?? null,
          id: 'modelSignalprice',
          size: 65,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          cell: (value) => <div className="num-right">{value.getValue()}</div>
        },
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.date')}>Last Modify Date</Tooltip>,
          accessorKey: 'modelSignal.time',
          accessorFn: (row) => row.modelSignal?.time ?? null,
          id: 'modelSignaltime',
          size: 135,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          cell: (signalDate) => (
            <Typography.Text>{signalDate.getValue() && moment(signalDate.getValue()).format(FULL_DATE_FORMAT)}</Typography.Text>
          ),
        },
        // Model Order
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.sign')}>Sign</Tooltip>,
          accessorKey: 'modelOrder.sign',
          accessorFn: (row) => row.modelOrder?.sign ?? null,
          id: 'modelOrdersign',
          size: 55,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          // meta: {
          //   filterVariant: 'select',
          // },
          // sorter: 'includesString' as any,  
          cell: (signal) => translateOrderSign(signal.getValue()),
        },
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.quantity')}>Qty</Tooltip>,
          accessorKey: 'modelOrder.quantity',
          accessorFn: (row) => row.modelOrder?.quantity ?? null,
          id: 'modelOrderquantity',
          enableResizing: false,
          size: 50,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          cell: (value) => <div className="num-right">{value.getValue()}</div>
        },
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.price')}>Price</Tooltip>,
          accessorKey: 'modelOrder.price',
          accessorFn: (row) => row.modelOrder?.price ?? null,
          id: 'pmodelOrderprice',
          size: 65,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          cell: (value) => <div className="num-right">{value.getValue()}</div>
        },
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.date')}>Last Modify Date</Tooltip>,
          accessorKey: 'modelOrder.time',
          accessorFn: (row) => row.modelOrder?.time ?? null,
          id: 'modelOrdertime',
          size: 135,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          cell: (signalDate) => (
            <Typography.Text>{signalDate.getValue() && moment(signalDate.getValue()).format(FULL_DATE_FORMAT)}</Typography.Text>
          ),
        },
        // Others
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.isinCode')}>Isin</Tooltip>,
          accessorKey: 'securityRefData.instrumentIsin',
          accessorFn: (row) => row.securityRefData?.instrumentIsin ?? null,
          id: 'securityRefDataIsin',
          size: 110,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
        },
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.currency')}>Ccy</Tooltip>,
          accessorKey: 'securityRefData.tradingCurrency',
          accessorFn: (row) => row.securityRefData?.tradingCurrency ?? null,
          id: 'securityRefDataCurrency',
          size: 50,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
        },
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.market')}>Market</Tooltip>,
          accessorKey: 'securityRefData.marketCode',
          accessorFn: (row) => row.securityRefData?.marketCode ?? null,
          id: 'securityRefDataMarket',
          size: 60,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
        },
        {
          header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.assetClass')}>Ast Class</Tooltip>,
          accessorKey: 'securityRefData.instrumentClass',
          accessorFn: (row) => row.securityRefData?.instrumentClass ?? null,
          id: 'securityRefDataInstrument',
          size: 80,
          enableResizing: false,
          enableSorting: data.length > 1,
          enableColumnFilter: data.length > 1,
          cell: (text) => translateInstruments(text.getValue()),
        },
    //   ]
    // }
  ], [data.length])

  return (
    <section className="signals-table nonDraggable">
      {/* <Table
        dataSource={data}
        columns={TABLE_COLUMNS}
        pagination={false}
        scroll={{ x: 200, y: tableHeight }}
      /> */}
      <div style={{ display: 'flex', height: 26, backgroundColor: '#131a2c', alignItems: 'center', fontSize: 13, width: '100%' }}>
        <div style={{ width: 200, textAlign: 'center', position: 'fixed' }}></div>
        <div style={{ width: 343, marginLeft: 200, textAlign: 'center', borderBottom: '1px solid #c3ba66', color: '#c3ba66' }}>Model Position</div>
        {/* <div style={{position: 'absolute', height: `${height - 48}px`, top: 56, left: 526, width: 1, backgroundColor: '#c3ba66', zIndex: 3}}></div> */}
        <div style={{ width: 307, textAlign: 'center', borderBottom: '1px solid #a04cca', color: '#a04cca' }}>Model Order</div>
        {/* <div style={{position: 'absolute', height: `${height - 48}px`, top: 56, right: 16, width: 1, backgroundColor: '#a04cca', zIndex: 3}}></div> */}
        {/* <div style={{ width: 200, textAlign: 'center' }}></div> */}
      </div>
      <JIVirtualTable
        data={data}
        columns={columns}
        width={width}
        scrollHeight={height - 38}
        frame={frame}
      />
    </section>
  )
}

export default withVisible(SignalsTable)
