import { Button, Row, Space, Switch, Typography } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingState } from 'types/enums'
import * as Store from 'types/store'
import { selectGroupsState } from 'store/resources/selectors'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
import { deleteGroup } from 'store/resources/groups/actions'

interface Props {
  group: Store.Group
  onClose: () => void
}

const DeleteGroup: React.FC<Props> = ({ group, onClose }) => {
  const dispatch = useDispatch()
  const loadingState = useSelector(selectGroupsState)
  const { t } = useTranslation()

  const deleting = loadingState === LoadingState.Deleting

  const [deleteAssets, setDeleteAssets] = useState<boolean>(false)

  const onCancel = () => {
    onClose()
  }

  const onConfirm = () => {
    dispatch(deleteGroup({ ...group, assetIds: deleteAssets ? group.assetIds : [] }))
    onClose()
  }

  return (
    <Modal centered open title={null} footer={null} closable={false}>
      <AppContentCard>
        <Typography.Text>{t('notification.message.deleteConfirm', { p0: group.name })}</Typography.Text>
        {group.assetIds.length > 0 ? (
          <Row justify="start">
            <Switch onClick={() => setDeleteAssets(!deleteAssets)} checked={deleteAssets} />
            <Typography.Text>Delete all the group's assets also</Typography.Text>
          </Row>
        ) : (
          <Row justify="start"><Typography.Text type='secondary'>There are no assets in this group</Typography.Text></Row>
        )}
        <Row justify="end" className="mt-30">
          <Space>
            <Button type="primary" onClick={onCancel} disabled={deleting}>
              {t('general.cancel')}
            </Button>
            <Button type="dashed" onClick={onConfirm} loading={deleting} disabled={deleting}>
              {t('general.ok')}
            </Button>
          </Space>
        </Row>
      </AppContentCard>
    </Modal>
  )
}

export default DeleteGroup
