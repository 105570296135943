import React, { useEffect, useRef, useState } from 'react'
import Debug from 'debug'
import { useDispatch, useSelector } from 'react-redux'
import { selectGroupsState } from 'store/resources/selectors'
import { CrudOperation, LoadingState } from 'types/enums'
import * as Api from 'types/api'
import { jsonToGroups, mapToGroupRest } from 'services/store/mapService'
import * as Store from 'types/store'
import { GroupForm } from 'components/StrategyViewer/forms/GroupForm/GroupForm'
import { createGroup, updateGroup } from 'store/resources/groups/actions'
import { fetchAssets } from 'store/resources/assets/actions'
import { getGroup } from 'api/groups'

const debug = Debug('Frontend')

interface Params {
  portfolioId: number
  group: Store.Group
  assets: Store.Asset[]
  operation: CrudOperation
  onClose: (group?: Store.Group) => void
}

export const GroupFormContainer: React.FC<Params> = ({ portfolioId, group, assets, operation, onClose }) => {
  const dispatch = useDispatch()
  const loadingState = useSelector(selectGroupsState)
  const [overrideParams, setOverrideParams] = useState<boolean>(false)
  const [updatedGroup, setUpdatedGroup] = useState<Store.Group>(null)
  const [modelHasChanged, setModelHasChanged] = useState<boolean>(false)
  const isMounted = useRef(false)
  const isLoading = operation === CrudOperation.Update ? LoadingState.Updating : LoadingState.Creating

  const closeEdit = () => onClose(updatedGroup ?? group)

  useEffect(() => {
    isMounted.current = true
    // Fetch new group data to pass onClose
    if (!group) {
      return
    }
    const updateGroup = async () => {
      const res = await getGroup(group.id)
      const json = ((res as Api.GroupsResponse).data.application_group)
      const groups = jsonToGroups(json)
      setUpdatedGroup(groups[0])
    }
    
    if ([LoadingState.Created, LoadingState.Updated].includes(loadingState)) {
      updateGroup()
      // Refetch assets to ensure the ones with single models
      // are updated in the UI for the user to see
      dispatch(fetchAssets())
    }

    return () => {
      isMounted.current = false
    }
  }, [loadingState, group])

  useEffect(() => {
    if (updatedGroup) {
      closeEdit()
    }
  }, [updatedGroup])

  const onSave = async (data: any) => {
    const creationPayload = mapToGroupRest(data, portfolioId)

    const completeCreationPayload = {
      ...creationPayload,
      override: overrideParams,
    }
    
    if (operation === CrudOperation.Create) {
      dispatch(createGroup(completeCreationPayload))
    }

    if (operation === CrudOperation.Update) {

      const updatePayload = {
        id: group.id,
        override: overrideParams,
        ...creationPayload,
      }

      dispatch(updateGroup(updatePayload))
    }

    closeEdit()
  }

  return (
    <GroupForm
      group={group}
      assets={assets}
      loading={loadingState === isLoading}
      onSave={(data) => onSave(data)}
      onClose={closeEdit}
      mode={operation}
      modelHasChanged={modelHasChanged}
      setModelHasChanged={setModelHasChanged}
      overrideParams={overrideParams}
      setOverrideParams={setOverrideParams}
    />
  )
}
