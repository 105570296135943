import React, { useCallback, useEffect, useState } from 'react'
import * as Store from 'types/store'
import { Button, Modal, Select, Tooltip } from 'antd'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { selectDashboard } from 'store/dashboard/selectors'
import { useTranslation } from 'react-i18next'
import { CloseCircleOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons'

const { Option } = Select

type AddButton = {
  id: string
  name: string
}

interface Props {
  layouts: (Store.Layout | AddButton)[]
  onSelect: (layout: Store.Layout) => void
  onDelete: (id: number) => void
}

const DashboardSelector: React.FC<Props> = ({ layouts, onSelect, onDelete }) => {
  const { t } = useTranslation()
  const dashboard = useSelector(selectDashboard)

  const [selectOptions, setSelectOptions] = useState<JSX.Element[]>([])
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [warningModalVisible, setWarningModalVisible] = useState<boolean>(false)
  const [layoutToDelete, setLayoutToDelete] = useState<number | null>(null)

  const onDahsboardSelected = (event: any, id: string | number) => {
    event.preventDefault()
    const layout = layouts.find((item) => item.id === id)
    onSelect(layout as Store.Layout)
  }

  const onLayoutDelete = (id: number) => {
    onDelete(id)
    setModalVisible(false)
    // Clear dashboard if the current layout is deleted
    if (id === layoutToDelete) {
      onSelect(null)
    }
  }

  const onClearDashboard = () => {
    if (dashboard.isDirty) {
      setWarningModalVisible(true)
      return
    }
    onSelect(null)
  }

  const options = [...layouts, { id: 'add', name: 'Clear Dashboard' }]

  useEffect(() => {
    const updatedOpts = options.map((layout) => {
      if (layout.id === 'add') {
        return (
          <Option key={layout.id} value={layout.id}>
            <div className='dashboard-item-content clear-button' onClick={() => onClearDashboard()}><CloseOutlined />&nbsp;<p>{layout.name}</p></div>
          </Option>
        )
      }
      return (
        <Option key={layout.id} value={layout.id}>
          <div className='dashboard-item-content'>
            <p onClick={(e) => onDahsboardSelected(e, layout.id)}>
              <Tooltip title={layout.name} mouseEnterDelay={1}>{layout.name}</Tooltip>
            </p>
            <Button
              type="dashed"
              size="small"
              onClick={(e) => {
                e.preventDefault()
                setModalVisible(true)
                setLayoutToDelete(layout.id as number)
              }}
              icon={<DeleteOutlined />}
            />
          </div>
        </Option>
      )
    })

    setSelectOptions(updatedOpts)
  }, [layouts, dashboard.isDirty])

  const onClearDashboardCancel = () => {
    onSelect(null)
    setWarningModalVisible(false)
  }

  return (
    <div className={`${styles['dashboard-selector']}`}>
      <Select
        clearIcon={<CloseCircleOutlined />}
        popupClassName='dashboard-selector-dropdown'
        value={dashboard.id}
        placeholder="Select a layout..."
      >
        {selectOptions}
      </Select>

      <Modal
        title={layoutToDelete && layouts && `Do you want to delete layout ${layouts.find((item) => item.id === layoutToDelete)?.name} ?`}
        open={modalVisible}
        style={{ backgroundColor: 'black', borderRadius: '5px' }}
        bodyStyle={{ display: 'none' }}
        onCancel={() => setModalVisible(false)}
        onOk={() => onLayoutDelete(layoutToDelete)}
        okText="Delete layout"
      />

      <Modal
        title="Unsaved changes will be lost. Are you sure you want to discard them?"
        open={warningModalVisible}
        style={{ backgroundColor: 'black', borderRadius: '5px' }}
        bodyStyle={{ display: 'none' }}
        onCancel={() => setWarningModalVisible(false)}
        onOk={() => onClearDashboardCancel()}
        okText="Discard changes"
      />
    </div>
  )
}

export default DashboardSelector
