import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Debug from 'debug'

import { Table, Tooltip } from 'antd'
import * as Store from 'types/store'

import styles from './styles.module.scss'
import { getAssetStatusLabel } from 'core/helpers'
import { formatNumber } from 'core/formats'
import { AssetStatus, OpenOperationType } from 'types/enums'

import { getTableHeights } from 'helpers/framePanelHelper'
import AssetStatusDropdown from 'v2/containers/CandleStick/Asset/AssetStatusDropdown'
import { ColumnsType } from 'antd/lib/table'
import { getCheckBoxFilter, getColumnSearchProps, sortNumber, sortString } from 'helpers/tableHelpers'
import { useSelector } from 'react-redux'
import { selectOperativeSessionAssets } from 'store/pages/selectors'
import { mapOldColumnsToNew } from 'services/api/mapService'
import { JITable, JIVirtualTable } from '../JITable'

const debug = new Debug('Frontend')

// const TABLE_HEIGHTS = getTableHeights()
// debug('TABLE_HEIGHTS', TABLE_HEIGHTS)
const TOP_OFFSET = 98

interface Props {
  height: number
  width: number
  frame: Store.Frame
  data: Store.PortfolioListAssetsTable[]
  columnKeys: string[]
  baseCurrency: string
}

const AssetsTable: React.FC<Props> = ({ data: tableData, height, width, frame, columnKeys = [], baseCurrency = '' }) => {
  const { t } = useTranslation()
  const assets = useSelector(selectOperativeSessionAssets)
  const assetsStatuses = useMemo(() => assets.map((asset) => asset.status), [assets])
  const data = useMemo(() => tableData, [tableData])

  const headerHeight = 26
  const scrollHeight = height - TOP_OFFSET + headerHeight

  const columnsConfigs = frame.options.columnsConfigs ?? []
  const assetColumnsConfigs = columnsConfigs.find((el) => el.table === 'asset')

  const getLastPosition = useCallback((type: OpenOperationType) => {
    switch (type) {
      case OpenOperationType.Long:
        return t('assets.long')
      case OpenOperationType.Short:
        return t('assets.short')
      case OpenOperationType.Flat:
        return t('assets.flat')
      default:
        return null
    }
  }, [])

  // const TABLE_COLUMNS: ColumnsType<Store.PortfolioListAssetsTable> = useMemo(() => [
  //   {
  //     title: <Tooltip title={t('table.column.assetName')}>Ast</Tooltip>,
  //     dataIndex: 'assetName',
  //     // sorter: data.length > 1 ? (a, b) => sortString(a.assetName, b.assetName) : null,
  //     defaultSortOrder: 'descend',
  //     ...(data.length > 1 ? getColumnSearchProps('assetName') : {}),
  //     width: 199,
  //     fixed: 'left',
  //     meta: {
  //       filterVariant: 'text'
  //     },
  //     sorter: 'includesString' as any,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.lastPrice')}>Last</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.lastPrice, b.lastPrice) : null,
  //     dataIndex: 'lastPrice',
  //     width: 80,
  //     render: (value) => {
  //       return value
  //     },
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.instrumentIsin')}>Isin</Tooltip>,
  //     ...(data.length > 1 ? getColumnSearchProps('instrumentIsin') : {}),
  //     dataIndex: 'instrumentIsin',
  //     width: 140,
  //     // sorter: data.length > 1 ? (a, b) => sortString(a.instrumentIsin, b.instrumentIsin) : null,
  //     meta: {
  //       filterVariant: 'text'
  //     },
  //     sorter: 'includesString' as any,
  //   },
  //   {
  //     // title: t('table.column.model'), JI-TRANSLATE
  //     title: 'Group',
  //     // sorter: data.length > 1 ? (a, b) => sortString(a.groupName, b.groupName) : null,
  //     dataIndex: 'groupName',
  //     width: 110,
  //     ...(data.length > 1 ? getColumnSearchProps('groupName') : {}),
  //     meta: {
  //       filterVariant: 'text'
  //     },
  //     sorter: 'includesString' as any,
  //   },
  //   {
  //     title: 'Model',
  //     // sorter: data.length > 1 ? (a, b) => sortString(a.modelName, b.modelName) : null,
  //     dataIndex: 'modelName',
  //     width: 140,
  //     ...(data.length > 1 ? getColumnSearchProps('modelName') : {}),
  //     meta: {
  //       filterVariant: 'text'
  //     },
  //     sorter: 'includesString' as any,
  //   },
  //   {
  //     // title: t('table.column.version'), JI-TRANSLATE
  //     title: <Tooltip title={'Model version'}>M Version</Tooltip>,
  //     // sorter: data.length > 1 ? (a, b) => sortString(a.modelVersion, b.modelVersion) : null,
  //     dataIndex: 'modelVersion',
  //     width: 95,
  //     ...(data.length > 1 ? getColumnSearchProps('modelVersion') : {}),
  //     meta: {
  //       filterVariant: 'text'
  //     },
  //     sorter: 'includesString' as any,
  //   },
  //   {
  //     // title: t('table.column.modelType'), JI-TRANSLATE
  //     title: <Tooltip title={'Model Type'}>M type</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortString(a.modelType, b.modelType) : null,
  //     dataIndex: 'modelType',
  //     width: 80,
  //     ...(data.length > 1 ? getColumnSearchProps('modelType') : {}),
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.modelStatus')}>M Status</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortString(a.modelStatus, b.modelStatus) : null,
  //     dataIndex: 'modelStatus',
  //     width: 90,
  //     align: 'center',
  //     // eslint-disable-next-line react/display-name
  //     render: (modelStatus: AssetStatus, assetData: Store.PortfolioListAssetsTable) => {
  //       const asset = assets.find((asset) => asset.name === assetData.assetName)
  //       if (!asset) {
  //         return null
  //       }
  //       return <AssetStatusDropdown asset={asset} disabled={false} />
  //     },
  //     ...(data.length > 1 ? getCheckBoxFilter('modelStatus', data, getAssetStatusLabel) : {}),
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.lastPosition')}>Pos Sign</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortString(a.lastPosition, b.lastPosition) : null,
  //     dataIndex: 'lastPosition',
  //     width: 90,
  //     // meta: {
  //     //   filterVariant: 'select',
  //     // },
  //     render: (lastPosition) => getLastPosition(lastPosition),
  //     ...(data.length > 1 ? getCheckBoxFilter('lastPosition', data, getLastPosition) : {}),
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.quantity')}>Qty</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.quantity, b.quantity) : null,
  //     dataIndex: 'quantity',
  //     width: 48,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.countervalue', { baseCurrency: baseCurrency ?? '' })}>{`Ctv ${baseCurrency}`}</Tooltip>, // ?? to avoid warning when value is not ready
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.value, b.value) : null,
  //     dataIndex: 'value',
  //     width: 85,
  //     render: (value) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyTradingReturn')}>{`TDY Trd Ret ${baseCurrency}`}</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyTradingRetBc, b.tdyTradingRetBc) : null,
  //     dataIndex: 'tdyTradingRetBc',
  //     width: 120,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyTradingReturnPercentage')}>TDY Trd Ret %</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyPercRet, b.tdyPercRet) : null,
  //     dataIndex: 'tdyPercRet',
  //     width: 105,
  //     render: (value) => {
  //       return `${formatNumber(value * 100)} %`
  //     },
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdTradingReturn')}>{`YTD Trd Ret ${baseCurrency}`}</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdTradingRetBc, b.ytdTradingRetBc) : null,
  //     dataIndex: 'ytdTradingRetBc',
  //     width: 115,
  //     render: (value) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdExchangeRatesReturn')}>{`YTD Exch Rate Ret ${baseCurrency}`}</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdExchRetBc, b.ytdExchRetBc) : null,
  //     dataIndex: 'ytdExchRetBc',
  //     width: 150,
  //     render: (value) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdTotalReturn')}>{`YTD Tot Ret ${baseCurrency}`}</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdAllRetBc, b.ytdAllRetBc) : null,
  //     dataIndex: 'ytdAllRetBc',
  //     width: 120,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip title={`YTD Total Ret. Fees ${baseCurrency}`}>{`YTD Tot Ret Fees ${baseCurrency}`}</Tooltip>,
  //     // sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdAllRetBc, b.ytdAllRetBc) : null,
  //     dataIndex: 'allRetBcFees',
  //     width: 145,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdTradingReturnPercentage')}>YTD Trd Ret %</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdPercRet, b.ytdPercRet) : null,
  //     dataIndex: 'ytdPercRet',
  //     width: 105,
  //     render: (value: number) => {
  //       return `${formatNumber(value * 100)} %`
  //     },
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.lastOpenTdyTradingReturn')}>{`Open Op TDY Trd Ret ${baseCurrency}`}</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.opTdyTradRetBc, b.opTdyTradRetBc) : null,
  //     dataIndex: 'opTdyTradRetBc',
  //     width: 170,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //   },
  //   {
  //     // RIGHT VALUE
  //     title: <Tooltip title={t('table.column.lastOpenTdyReturnPercetage')}>Open Op YTD Trd Ret %</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.opTdyPercRet, b.opTdyPercRet) : null,
  //     dataIndex: 'opTdyPercRet',
  //     width: 155,
  //     render: (value: number) => {
  //       return `${formatNumber(value * 100)} %`
  //     },
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyTotalOperations')}>TDY Tot Op</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyTotalOp, b.tdyTotalOp) : null,
  //     dataIndex: 'tdyTotalOp',
  //     width: 87,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyPositiveOperations')}>TDY Pos Op</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyPositiveOp, b.tdyPositiveOp) : null,
  //     dataIndex: 'tdyPositiveOp',
  //     width: 89,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyNegativeOperations')}>TDY Neg Op</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyNegativeOp, b.tdyNegativeOp) : null,
  //     dataIndex: 'tdyNegativeOp',
  //     width: 92,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdTotalOperations')}>YTD Tot Op</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdTotalOp, b.ytdTotalOp) : null,
  //     dataIndex: 'ytdTotalOp',
  //     width: 85,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdPositiveOperations')}>YTD Pos Op</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdPositiveOp, b.ytdPositiveOp) : null,
  //     dataIndex: 'ytdPositiveOp',
  //     width: 89,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdNegativeOperations')}>YTD Neg Op</Tooltip>,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdNegativeOp, b.ytdNegativeOp) : null,
  //     dataIndex: 'ytdNegativeOp',
  //     width: 95,
  //   },
  // ], [])

  // const JITableColumns = assets && mapOldColumnsToNew(TABLE_COLUMNS, data.length)

  const tableColumns = useMemo(() => ([
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.assetName')}>Asset</Tooltip>,
      accessorKey: 'assetName',
      id: 'assetName',
      size: 199,
      enableResizing: false,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      // fixed: 'left',
      meta: {
        filterVariant: 'text'
      },
      sorter: 'includesString' as any,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.lastPrice')}>Last</Tooltip>,
      accessorKey: 'lastPrice',
      id: 'lastPrice',
      size: 80,
      pin: false,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => {
        return <div className="num-right">{formatNumber(value.getValue())}</div>
      },
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.instrumentIsin')}>Isin</Tooltip>,
      accessorKey: 'instrumentIsin',
      id: 'instrumentIsin',
      size: 140,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      meta: {
        filterVariant: 'text'
      },
      sorter: 'includesString' as any,
    },
    {
      header: () => 'Group',
      accessorKey: 'groupName',
      id: 'groupName',
      size: 110,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      meta: {
        filterVariant: 'text'
      },
      sorter: 'includesString' as any,
    },
    {
      header: () => 'Model',
      accessorKey: 'modelName',
      id: 'modelName',
      size: 140,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      meta: {
        filterVariant: 'text'
      },
      sorter: 'includesString' as any,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={'Model version'}>M Version</Tooltip>,
      accessorKey: 'modelVersion',
      id: 'modelVersion',
      size: 95,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      meta: {
        filterVariant: 'text'
      },
      sorter: 'includesString' as any,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={'Model Type'}>M type</Tooltip>,
      accessorKey: 'modelType',
      id: 'modelType',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      meta: {
        filterVariant: 'select'
      },
      sorter: 'equalsString' as any,
    },
    {
      header: () => 'M Status',
      accessorKey: 'modelStatus',
      id: 'modelStatus',
      enableResizing: true,
      size: 90,
      fixed: false,
      cell: (tableInst) => {
        if (!assets) {
          return null
        }
        const asset = assets.find((asset) => asset.name === tableInst.row.original.assetName)
        if (!asset) {
          return null
        }
        return <AssetStatusDropdown asset={asset} disabled={false} />
      },
      meta: {
        filterVariant: 'select',
      },
      enableColumnFilter: data.length > 1,
      enableSorting: data.length > 1,
      filterFn: data.length > 1 ? 'equalsString' : null,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.lastPosition')}>Pos Sign</Tooltip>,
      accessorKey: 'lastPosition',
      id: 'lastPosition',
      size: 90,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => getLastPosition(value.cell.getValue()),
      meta: {
        filterVariant: 'select'
      },
      sorter: 'equalsString' as any,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.quantity')}>Qty</Tooltip>,
      accessorKey: 'quantity',
      id: 'quantity',
      size: 48,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>

    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.countervalue', { baseCurrency: baseCurrency ?? '' })}>{`Ctv ${baseCurrency}`}</Tooltip>,
      accessorKey: 'value',
      id: 'value',
      size: 85,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTradingReturn')}>{`TDY Trd Ret ${baseCurrency}`}</Tooltip>,
      accessorKey: 'tdyTradingRetBc',
      id: 'tdyTradingRetBc',
      size: 120,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTradingReturnPercentage')}>TDY Trd Ret %</Tooltip>,
      accessorKey: 'tdyPercRet',
      id: 'tdyPercRet',
      size: 105,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{`${formatNumber(value.cell.getValue() * 100)} %`}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTradingReturn')}>{`YTD Trd Ret ${baseCurrency}`}</Tooltip>,
      accessorKey: 'ytdTradingRetBc',
      id: 'ytdTradingRetBc',
      size: 115,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdExchangeRatesReturn')}>{`YTD Exch Rate Ret ${baseCurrency}`}</Tooltip>,
      accessorKey: 'ytdExchRetBc',
      id: 'ytdExchRetBc',
      size: 155,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTotalReturn')}>{`YTD Tot Ret ${baseCurrency}`}</Tooltip>,
      accessorKey: 'ytdAllRetBc',
      id: 'ytdAllRetBc',
      size: 120,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={`YTD Total Ret. Fees ${baseCurrency}`}>{`YTD Tot Ret Fees ${baseCurrency}`}</Tooltip>,
      accessorKey: 'allRetBcFees',
      id: 'allRetBcFees',
      size: 145,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTradingReturnPercentage')}>YTD Trd Ret %</Tooltip>,
      accessorKey: 'ytdPercRet',
      id: 'ytdPercRet',
      size: 105,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{`${formatNumber(value.cell.getValue() * 100)} %`}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.lastOpenTdyTradingReturn')}>{`Open Op TDY Trd Ret ${baseCurrency}`}</Tooltip>,
      accessorKey: 'opTdyTradRetBc',
      id: 'opTdyTradRetBc',
      size: 170,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.lastOpenTdyReturnPercetage')}>Open Op YTD Trd Ret %</Tooltip>,
      accessorKey: 'opTdyPercRet',
      id: 'opTdyPercRet',
      size: 155,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{`${formatNumber(value.cell.getValue() * 100)} %`}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTotalOperations')}>TDY Tot Op</Tooltip>,
      accessorKey: 'tdyTotalOp',
      id: 'tdyTotalOp',
      size: 87,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyPositiveOperations')}>TDY Pos Op</Tooltip>,
      accessorKey: 'tdyPositiveOp',
      id: 'tdyPositiveOp',
      size: 89,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyNegativeOperations')}>TDY Neg Op</Tooltip>,
      accessorKey: 'tdyNegativeOp',
      id: 'tdyNegativeOp',
      size: 92,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTotalOperations')}>YTD Tot Op</Tooltip>,
      accessorKey: 'ytdTotalOp',
      id: 'ytdTotalOp',
      size: 85,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdPositiveOperations')}>YTD Pos Op</Tooltip>,
      accessorKey: 'ytdPositiveOp',
      id: 'ytdPositiveOp',
      size: 89,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdNegativeOperations')}>YTD Neg Op</Tooltip>,
      accessorKey: 'ytdNegativeOp',
      id: 'ytdNegativeOp',
      size: 95,
      enableSorting: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
  ]), [assetsStatuses, data.length])

  // const cols = useMemo(() => JITableColumns, [assetsStatuses])

  // const getColumns = (
  //   defaultColumns: ColumnsType<Store.PortfolioListAssetsTable>,
  //   selectedColumnsKeys,
  // ): ColumnsType<Store.PortfolioListAssetsTable> => {
  //   let generatedCols = []

  //   if (selectedColumnsKeys.length) {
  //     const selectedColumns = defaultColumns.filter((col: any) => selectedColumnsKeys.includes(col.dataIndex))

  //     generatedCols = [
  //       {
  //         title: t('table.column.assetName'),
  //         dataIndex: 'assetName',
  //         sorter: (a, b) => sortString(a.assetName, b.assetName),
  //         defaultSortOrder: 'descend',
  //         ...getColumnSearchProps('assetName'),
  //         width: 199,
  //         fixed: 'left',
  //       },
  //       ...selectedColumns,
  //     ]
  //   } else {
  //     generatedCols = defaultColumns
  //   }

  //   return generatedCols
  // }

  // <section className={`${styles['assets-table']} assets-table nonDraggable`}>
  // </section>
  {/* <Table
    rowKey="assetName"
    // size='small'
    getPopupContainer={() => document.querySelector('.assets-table')}
    columns={getColumns(TABLE_COLUMNS, columnKeys)}
    dataSource={data}
    pagination={false}
    scroll={{ x: 200, y: scrollHeight }}
  /> */}
  return (
    <JIVirtualTable
      columns={tableColumns}
      data={data}
      frame={frame}
      width={width}
      scrollHeight={scrollHeight}
      // classname='assets-table'
      columnsConfig={assetColumnsConfigs}
    />
  )
}

export default AssetsTable
