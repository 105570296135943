// source: asset_security_data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.assets.security.alive', null, global);
goog.exportSymbol('proto.assets.security.asset_closed_candle', null, global);
goog.exportSymbol('proto.assets.security.asset_exchange_rate', null, global);
goog.exportSymbol('proto.assets.security.asset_open_candle', null, global);
goog.exportSymbol('proto.assets.security.asset_ref_data', null, global);
goog.exportSymbol('proto.assets.security.asset_security_bid_ask', null, global);
goog.exportSymbol('proto.assets.security.asset_security_bond_data', null, global);
goog.exportSymbol('proto.assets.security.asset_security_multipliers', null, global);
goog.exportSymbol('proto.assets.security.asset_security_ref_data', null, global);
goog.exportSymbol('proto.assets.security.asset_security_status', null, global);
goog.exportSymbol('proto.assets.security.asset_security_trade', null, global);
goog.exportSymbol('proto.assets.security.asset_security_volume', null, global);
goog.exportSymbol('proto.assets.security.decimal', null, global);
goog.exportSymbol('proto.assets.security.end_snapshot', null, global);
goog.exportSymbol('proto.assets.security.feed_event', null, global);
goog.exportSymbol('proto.assets.security.feed_event.EventTypeCase', null, global);
goog.exportSymbol('proto.assets.security.ji_trade', null, global);
goog.exportSymbol('proto.assets.security.ji_trade_fees', null, global);
goog.exportSymbol('proto.assets.security.tstamp', null, global);
goog.exportSymbol('proto.assets.security.update_payload', null, global);
goog.exportSymbol('proto.assets.security.update_snapshot', null, global);
goog.exportSymbol('proto.assets.security.update_ticker', null, global);
goog.exportSymbol('proto.assets.security.update_type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.tstamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.tstamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.tstamp.displayName = 'proto.assets.security.tstamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.decimal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.decimal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.decimal.displayName = 'proto.assets.security.decimal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_ref_data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_ref_data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_ref_data.displayName = 'proto.assets.security.asset_ref_data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_security_ref_data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_security_ref_data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_security_ref_data.displayName = 'proto.assets.security.asset_security_ref_data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_security_trade = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_security_trade, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_security_trade.displayName = 'proto.assets.security.asset_security_trade';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_security_bid_ask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_security_bid_ask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_security_bid_ask.displayName = 'proto.assets.security.asset_security_bid_ask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_security_volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_security_volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_security_volume.displayName = 'proto.assets.security.asset_security_volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_security_status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_security_status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_security_status.displayName = 'proto.assets.security.asset_security_status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_exchange_rate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_exchange_rate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_exchange_rate.displayName = 'proto.assets.security.asset_exchange_rate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.ji_trade = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.ji_trade, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.ji_trade.displayName = 'proto.assets.security.ji_trade';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.ji_trade_fees = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.ji_trade_fees, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.ji_trade_fees.displayName = 'proto.assets.security.ji_trade_fees';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_open_candle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_open_candle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_open_candle.displayName = 'proto.assets.security.asset_open_candle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_closed_candle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_closed_candle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_closed_candle.displayName = 'proto.assets.security.asset_closed_candle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_security_multipliers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_security_multipliers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_security_multipliers.displayName = 'proto.assets.security.asset_security_multipliers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.asset_security_bond_data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.asset_security_bond_data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.asset_security_bond_data.displayName = 'proto.assets.security.asset_security_bond_data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.update_payload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.update_payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.update_payload.displayName = 'proto.assets.security.update_payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.update_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.update_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.update_snapshot.displayName = 'proto.assets.security.update_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.end_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.end_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.end_snapshot.displayName = 'proto.assets.security.end_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.update_ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.update_ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.update_ticker.displayName = 'proto.assets.security.update_ticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.alive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.security.alive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.alive.displayName = 'proto.assets.security.alive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.security.feed_event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.assets.security.feed_event.oneofGroups_);
};
goog.inherits(proto.assets.security.feed_event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.security.feed_event.displayName = 'proto.assets.security.feed_event';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.tstamp.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.tstamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.tstamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.tstamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    offset: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    code: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.tstamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.tstamp;
  return proto.assets.security.tstamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.tstamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.tstamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.tstamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.tstamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.tstamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.tstamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * required sint64 time = 1;
 * @return {number}
 */
proto.assets.security.tstamp.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.tstamp} returns this
 */
proto.assets.security.tstamp.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.tstamp} returns this
 */
proto.assets.security.tstamp.prototype.clearTime = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.tstamp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 offset = 2;
 * @return {number}
 */
proto.assets.security.tstamp.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.tstamp} returns this
 */
proto.assets.security.tstamp.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.tstamp} returns this
 */
proto.assets.security.tstamp.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.tstamp.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required uint32 code = 3;
 * @return {number}
 */
proto.assets.security.tstamp.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.tstamp} returns this
 */
proto.assets.security.tstamp.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.tstamp} returns this
 */
proto.assets.security.tstamp.prototype.clearCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.tstamp.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.decimal.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.decimal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.decimal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.decimal.toObject = function(includeInstance, msg) {
  var f, obj = {
    coeff: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.decimal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.decimal;
  return proto.assets.security.decimal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.decimal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.decimal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setCoeff(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.decimal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.decimal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.decimal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.decimal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * required sint64 coeff = 1;
 * @return {number}
 */
proto.assets.security.decimal.prototype.getCoeff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.decimal} returns this
 */
proto.assets.security.decimal.prototype.setCoeff = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.decimal} returns this
 */
proto.assets.security.decimal.prototype.clearCoeff = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.decimal.prototype.hasCoeff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 exp = 2;
 * @return {number}
 */
proto.assets.security.decimal.prototype.getExp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.decimal} returns this
 */
proto.assets.security.decimal.prototype.setExp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.decimal} returns this
 */
proto.assets.security.decimal.prototype.clearExp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.decimal.prototype.hasExp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_ref_data.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_ref_data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_ref_data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_ref_data.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetName: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    portfolioName: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    strategyName: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    strategyTarget: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    baseCurrency: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_ref_data}
 */
proto.assets.security.asset_ref_data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_ref_data;
  return proto.assets.security.asset_ref_data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_ref_data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_ref_data}
 */
proto.assets.security.asset_ref_data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrategyName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrategyTarget(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_ref_data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_ref_data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_ref_data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_ref_data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * required string asset_name = 1;
 * @return {string}
 */
proto.assets.security.asset_ref_data.prototype.getAssetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.setAssetName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.clearAssetName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_ref_data.prototype.hasAssetName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string portfolio_name = 2;
 * @return {string}
 */
proto.assets.security.asset_ref_data.prototype.getPortfolioName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.setPortfolioName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.clearPortfolioName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_ref_data.prototype.hasPortfolioName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string strategy_name = 3;
 * @return {string}
 */
proto.assets.security.asset_ref_data.prototype.getStrategyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.setStrategyName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.clearStrategyName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_ref_data.prototype.hasStrategyName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string strategy_target = 4;
 * @return {string}
 */
proto.assets.security.asset_ref_data.prototype.getStrategyTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.setStrategyTarget = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.clearStrategyTarget = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_ref_data.prototype.hasStrategyTarget = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required string base_currency = 5;
 * @return {string}
 */
proto.assets.security.asset_ref_data.prototype.getBaseCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_ref_data} returns this
 */
proto.assets.security.asset_ref_data.prototype.clearBaseCurrency = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_ref_data.prototype.hasBaseCurrency = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_security_ref_data.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_security_ref_data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_security_ref_data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_ref_data.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketCode: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    symbol: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    instrumentCode: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    instrumentIsin: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    description: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    instrumentClass: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    tradingCurrency: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_security_ref_data}
 */
proto.assets.security.asset_security_ref_data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_security_ref_data;
  return proto.assets.security.asset_security_ref_data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_security_ref_data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_security_ref_data}
 */
proto.assets.security.asset_security_ref_data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentIsin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentClass(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradingCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_security_ref_data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_security_ref_data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_security_ref_data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_ref_data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * required string market_code = 1;
 * @return {string}
 */
proto.assets.security.asset_security_ref_data.prototype.getMarketCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.setMarketCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.clearMarketCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_ref_data.prototype.hasMarketCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string symbol = 2;
 * @return {string}
 */
proto.assets.security.asset_security_ref_data.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.setSymbol = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.clearSymbol = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_ref_data.prototype.hasSymbol = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string instrument_code = 3;
 * @return {string}
 */
proto.assets.security.asset_security_ref_data.prototype.getInstrumentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.setInstrumentCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.clearInstrumentCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_ref_data.prototype.hasInstrumentCode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string instrument_isin = 4;
 * @return {string}
 */
proto.assets.security.asset_security_ref_data.prototype.getInstrumentIsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.setInstrumentIsin = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.clearInstrumentIsin = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_ref_data.prototype.hasInstrumentIsin = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.assets.security.asset_security_ref_data.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_ref_data.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string instrument_class = 6;
 * @return {string}
 */
proto.assets.security.asset_security_ref_data.prototype.getInstrumentClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.setInstrumentClass = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.clearInstrumentClass = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_ref_data.prototype.hasInstrumentClass = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string trading_currency = 7;
 * @return {string}
 */
proto.assets.security.asset_security_ref_data.prototype.getTradingCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.setTradingCurrency = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_ref_data} returns this
 */
proto.assets.security.asset_security_ref_data.prototype.clearTradingCurrency = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_ref_data.prototype.hasTradingCurrency = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_security_trade.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_security_trade.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_security_trade} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_trade.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: (f = msg.getPrice()) && proto.assets.security.decimal.toObject(includeInstance, f),
    size: (f = msg.getSize()) && proto.assets.security.decimal.toObject(includeInstance, f),
    tradeTs: (f = msg.getTradeTs()) && proto.assets.security.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_security_trade}
 */
proto.assets.security.asset_security_trade.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_security_trade;
  return proto.assets.security.asset_security_trade.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_security_trade} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_security_trade}
 */
proto.assets.security.asset_security_trade.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 2:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 3:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setTradeTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_security_trade.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_security_trade.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_security_trade} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_trade.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTradeTs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * required decimal price = 1;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.asset_security_trade.prototype.getPrice = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 1, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.asset_security_trade} returns this
*/
proto.assets.security.asset_security_trade.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_trade} returns this
 */
proto.assets.security.asset_security_trade.prototype.clearPrice = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_trade.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal size = 2;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_trade.prototype.getSize = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 2));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_trade} returns this
*/
proto.assets.security.asset_security_trade.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_trade} returns this
 */
proto.assets.security.asset_security_trade.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_trade.prototype.hasSize = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required tstamp trade_ts = 3;
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.asset_security_trade.prototype.getTradeTs = function() {
  return /** @type{!proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 3, 1));
};


/**
 * @param {!proto.assets.security.tstamp} value
 * @return {!proto.assets.security.asset_security_trade} returns this
*/
proto.assets.security.asset_security_trade.prototype.setTradeTs = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_trade} returns this
 */
proto.assets.security.asset_security_trade.prototype.clearTradeTs = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_trade.prototype.hasTradeTs = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_security_bid_ask.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_security_bid_ask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_security_bid_ask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_bid_ask.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidPrice: (f = msg.getBidPrice()) && proto.assets.security.decimal.toObject(includeInstance, f),
    bidTotalSize: (f = msg.getBidTotalSize()) && proto.assets.security.decimal.toObject(includeInstance, f),
    askPrice: (f = msg.getAskPrice()) && proto.assets.security.decimal.toObject(includeInstance, f),
    askTotalSize: (f = msg.getAskTotalSize()) && proto.assets.security.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_security_bid_ask}
 */
proto.assets.security.asset_security_bid_ask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_security_bid_ask;
  return proto.assets.security.asset_security_bid_ask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_security_bid_ask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_security_bid_ask}
 */
proto.assets.security.asset_security_bid_ask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setBidPrice(value);
      break;
    case 2:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setBidTotalSize(value);
      break;
    case 3:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setAskPrice(value);
      break;
    case 4:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setAskTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_security_bid_ask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_security_bid_ask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_security_bid_ask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_bid_ask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidPrice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getBidTotalSize();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAskPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAskTotalSize();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal bid_price = 1;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_bid_ask.prototype.getBidPrice = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 1));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_bid_ask} returns this
*/
proto.assets.security.asset_security_bid_ask.prototype.setBidPrice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_bid_ask} returns this
 */
proto.assets.security.asset_security_bid_ask.prototype.clearBidPrice = function() {
  return this.setBidPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_bid_ask.prototype.hasBidPrice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal bid_total_size = 2;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_bid_ask.prototype.getBidTotalSize = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 2));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_bid_ask} returns this
*/
proto.assets.security.asset_security_bid_ask.prototype.setBidTotalSize = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_bid_ask} returns this
 */
proto.assets.security.asset_security_bid_ask.prototype.clearBidTotalSize = function() {
  return this.setBidTotalSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_bid_ask.prototype.hasBidTotalSize = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal ask_price = 3;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_bid_ask.prototype.getAskPrice = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 3));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_bid_ask} returns this
*/
proto.assets.security.asset_security_bid_ask.prototype.setAskPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_bid_ask} returns this
 */
proto.assets.security.asset_security_bid_ask.prototype.clearAskPrice = function() {
  return this.setAskPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_bid_ask.prototype.hasAskPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal ask_total_size = 4;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_bid_ask.prototype.getAskTotalSize = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 4));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_bid_ask} returns this
*/
proto.assets.security.asset_security_bid_ask.prototype.setAskTotalSize = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_bid_ask} returns this
 */
proto.assets.security.asset_security_bid_ask.prototype.clearAskTotalSize = function() {
  return this.setAskTotalSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_bid_ask.prototype.hasAskTotalSize = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_security_volume.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_security_volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_security_volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    cumulativeSize: (f = msg.getCumulativeSize()) && proto.assets.security.decimal.toObject(includeInstance, f),
    cumulativeAmount: (f = msg.getCumulativeAmount()) && proto.assets.security.decimal.toObject(includeInstance, f),
    averagePrice: (f = msg.getAveragePrice()) && proto.assets.security.decimal.toObject(includeInstance, f),
    volumeTs: (f = msg.getVolumeTs()) && proto.assets.security.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_security_volume}
 */
proto.assets.security.asset_security_volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_security_volume;
  return proto.assets.security.asset_security_volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_security_volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_security_volume}
 */
proto.assets.security.asset_security_volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setCumulativeSize(value);
      break;
    case 2:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setCumulativeAmount(value);
      break;
    case 3:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setAveragePrice(value);
      break;
    case 4:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setVolumeTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_security_volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_security_volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_security_volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCumulativeSize();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getCumulativeAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAveragePrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getVolumeTs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal cumulative_size = 1;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_volume.prototype.getCumulativeSize = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 1));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_volume} returns this
*/
proto.assets.security.asset_security_volume.prototype.setCumulativeSize = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_volume} returns this
 */
proto.assets.security.asset_security_volume.prototype.clearCumulativeSize = function() {
  return this.setCumulativeSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_volume.prototype.hasCumulativeSize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal cumulative_amount = 2;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_volume.prototype.getCumulativeAmount = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 2));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_volume} returns this
*/
proto.assets.security.asset_security_volume.prototype.setCumulativeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_volume} returns this
 */
proto.assets.security.asset_security_volume.prototype.clearCumulativeAmount = function() {
  return this.setCumulativeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_volume.prototype.hasCumulativeAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal average_price = 3;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_volume.prototype.getAveragePrice = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 3));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_volume} returns this
*/
proto.assets.security.asset_security_volume.prototype.setAveragePrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_volume} returns this
 */
proto.assets.security.asset_security_volume.prototype.clearAveragePrice = function() {
  return this.setAveragePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_volume.prototype.hasAveragePrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional tstamp volume_ts = 4;
 * @return {?proto.assets.security.tstamp}
 */
proto.assets.security.asset_security_volume.prototype.getVolumeTs = function() {
  return /** @type{?proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 4));
};


/**
 * @param {?proto.assets.security.tstamp|undefined} value
 * @return {!proto.assets.security.asset_security_volume} returns this
*/
proto.assets.security.asset_security_volume.prototype.setVolumeTs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_volume} returns this
 */
proto.assets.security.asset_security_volume.prototype.clearVolumeTs = function() {
  return this.setVolumeTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_volume.prototype.hasVolumeTs = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_security_status.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_security_status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_security_status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_status.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradingSessionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    tradingStatus: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    reopenTime: (f = msg.getReopenTime()) && proto.assets.security.tstamp.toObject(includeInstance, f),
    statusTs: (f = msg.getStatusTs()) && proto.assets.security.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_security_status}
 */
proto.assets.security.asset_security_status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_security_status;
  return proto.assets.security.asset_security_status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_security_status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_security_status}
 */
proto.assets.security.asset_security_status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradingSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradingStatus(value);
      break;
    case 3:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setReopenTime(value);
      break;
    case 4:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setStatusTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_security_status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_security_status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_security_status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReopenTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getStatusTs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string trading_session_id = 1;
 * @return {string}
 */
proto.assets.security.asset_security_status.prototype.getTradingSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_security_status} returns this
 */
proto.assets.security.asset_security_status.prototype.setTradingSessionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_status} returns this
 */
proto.assets.security.asset_security_status.prototype.clearTradingSessionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_status.prototype.hasTradingSessionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string trading_status = 2;
 * @return {string}
 */
proto.assets.security.asset_security_status.prototype.getTradingStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.asset_security_status} returns this
 */
proto.assets.security.asset_security_status.prototype.setTradingStatus = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_security_status} returns this
 */
proto.assets.security.asset_security_status.prototype.clearTradingStatus = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_status.prototype.hasTradingStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional tstamp reopen_time = 3;
 * @return {?proto.assets.security.tstamp}
 */
proto.assets.security.asset_security_status.prototype.getReopenTime = function() {
  return /** @type{?proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 3));
};


/**
 * @param {?proto.assets.security.tstamp|undefined} value
 * @return {!proto.assets.security.asset_security_status} returns this
*/
proto.assets.security.asset_security_status.prototype.setReopenTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_status} returns this
 */
proto.assets.security.asset_security_status.prototype.clearReopenTime = function() {
  return this.setReopenTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_status.prototype.hasReopenTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional tstamp status_ts = 4;
 * @return {?proto.assets.security.tstamp}
 */
proto.assets.security.asset_security_status.prototype.getStatusTs = function() {
  return /** @type{?proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 4));
};


/**
 * @param {?proto.assets.security.tstamp|undefined} value
 * @return {!proto.assets.security.asset_security_status} returns this
*/
proto.assets.security.asset_security_status.prototype.setStatusTs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_status} returns this
 */
proto.assets.security.asset_security_status.prototype.clearStatusTs = function() {
  return this.setStatusTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_status.prototype.hasStatusTs = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_exchange_rate.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_exchange_rate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_exchange_rate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_exchange_rate.toObject = function(includeInstance, msg) {
  var f, obj = {
    exchangeRate: (f = msg.getExchangeRate()) && proto.assets.security.decimal.toObject(includeInstance, f),
    exchangeRateTs: (f = msg.getExchangeRateTs()) && proto.assets.security.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_exchange_rate}
 */
proto.assets.security.asset_exchange_rate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_exchange_rate;
  return proto.assets.security.asset_exchange_rate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_exchange_rate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_exchange_rate}
 */
proto.assets.security.asset_exchange_rate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setExchangeRate(value);
      break;
    case 2:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setExchangeRateTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_exchange_rate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_exchange_rate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_exchange_rate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_exchange_rate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExchangeRate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getExchangeRateTs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal exchange_rate = 1;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_exchange_rate.prototype.getExchangeRate = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 1));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_exchange_rate} returns this
*/
proto.assets.security.asset_exchange_rate.prototype.setExchangeRate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_exchange_rate} returns this
 */
proto.assets.security.asset_exchange_rate.prototype.clearExchangeRate = function() {
  return this.setExchangeRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_exchange_rate.prototype.hasExchangeRate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional tstamp exchange_rate_ts = 2;
 * @return {?proto.assets.security.tstamp}
 */
proto.assets.security.asset_exchange_rate.prototype.getExchangeRateTs = function() {
  return /** @type{?proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 2));
};


/**
 * @param {?proto.assets.security.tstamp|undefined} value
 * @return {!proto.assets.security.asset_exchange_rate} returns this
*/
proto.assets.security.asset_exchange_rate.prototype.setExchangeRateTs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_exchange_rate} returns this
 */
proto.assets.security.asset_exchange_rate.prototype.clearExchangeRateTs = function() {
  return this.setExchangeRateTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_exchange_rate.prototype.hasExchangeRateTs = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.ji_trade.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.ji_trade.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.ji_trade} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.ji_trade.toObject = function(includeInstance, msg) {
  var f, obj = {
    sign: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    quantity: (f = msg.getQuantity()) && proto.assets.security.decimal.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && proto.assets.security.decimal.toObject(includeInstance, f),
    time: (f = msg.getTime()) && proto.assets.security.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.ji_trade}
 */
proto.assets.security.ji_trade.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.ji_trade;
  return proto.assets.security.ji_trade.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.ji_trade} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.ji_trade}
 */
proto.assets.security.ji_trade.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSign(value);
      break;
    case 2:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setQuantity(value);
      break;
    case 3:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 4:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.ji_trade.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.ji_trade.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.ji_trade} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.ji_trade.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * required string sign = 1;
 * @return {string}
 */
proto.assets.security.ji_trade.prototype.getSign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.ji_trade} returns this
 */
proto.assets.security.ji_trade.prototype.setSign = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.ji_trade} returns this
 */
proto.assets.security.ji_trade.prototype.clearSign = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.ji_trade.prototype.hasSign = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required decimal quantity = 2;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.ji_trade.prototype.getQuantity = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 2, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.ji_trade} returns this
*/
proto.assets.security.ji_trade.prototype.setQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.ji_trade} returns this
 */
proto.assets.security.ji_trade.prototype.clearQuantity = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.ji_trade.prototype.hasQuantity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required decimal price = 3;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.ji_trade.prototype.getPrice = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 3, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.ji_trade} returns this
*/
proto.assets.security.ji_trade.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.ji_trade} returns this
 */
proto.assets.security.ji_trade.prototype.clearPrice = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.ji_trade.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required tstamp time = 4;
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.ji_trade.prototype.getTime = function() {
  return /** @type{!proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 4, 1));
};


/**
 * @param {!proto.assets.security.tstamp} value
 * @return {!proto.assets.security.ji_trade} returns this
*/
proto.assets.security.ji_trade.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.ji_trade} returns this
 */
proto.assets.security.ji_trade.prototype.clearTime = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.ji_trade.prototype.hasTime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.ji_trade_fees.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.ji_trade_fees.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.ji_trade_fees} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.ji_trade_fees.toObject = function(includeInstance, msg) {
  var f, obj = {
    brokerVarFeesContract: (f = msg.getBrokerVarFeesContract()) && proto.assets.security.decimal.toObject(includeInstance, f),
    brokerVarFeesPerc: (f = msg.getBrokerVarFeesPerc()) && proto.assets.security.decimal.toObject(includeInstance, f),
    brokerFixedFees: (f = msg.getBrokerFixedFees()) && proto.assets.security.decimal.toObject(includeInstance, f),
    lendingFees: (f = msg.getLendingFees()) && proto.assets.security.decimal.toObject(includeInstance, f),
    transactionTax: (f = msg.getTransactionTax()) && proto.assets.security.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.ji_trade_fees}
 */
proto.assets.security.ji_trade_fees.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.ji_trade_fees;
  return proto.assets.security.ji_trade_fees.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.ji_trade_fees} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.ji_trade_fees}
 */
proto.assets.security.ji_trade_fees.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setBrokerVarFeesContract(value);
      break;
    case 2:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setBrokerVarFeesPerc(value);
      break;
    case 3:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setBrokerFixedFees(value);
      break;
    case 4:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setLendingFees(value);
      break;
    case 5:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setTransactionTax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.ji_trade_fees.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.ji_trade_fees.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.ji_trade_fees} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.ji_trade_fees.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrokerVarFeesContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getBrokerVarFeesPerc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getBrokerFixedFees();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getLendingFees();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTransactionTax();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal broker_var_fees_contract = 1;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.ji_trade_fees.prototype.getBrokerVarFeesContract = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 1));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.ji_trade_fees} returns this
*/
proto.assets.security.ji_trade_fees.prototype.setBrokerVarFeesContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.ji_trade_fees} returns this
 */
proto.assets.security.ji_trade_fees.prototype.clearBrokerVarFeesContract = function() {
  return this.setBrokerVarFeesContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.ji_trade_fees.prototype.hasBrokerVarFeesContract = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal broker_var_fees_perc = 2;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.ji_trade_fees.prototype.getBrokerVarFeesPerc = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 2));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.ji_trade_fees} returns this
*/
proto.assets.security.ji_trade_fees.prototype.setBrokerVarFeesPerc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.ji_trade_fees} returns this
 */
proto.assets.security.ji_trade_fees.prototype.clearBrokerVarFeesPerc = function() {
  return this.setBrokerVarFeesPerc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.ji_trade_fees.prototype.hasBrokerVarFeesPerc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal broker_fixed_fees = 3;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.ji_trade_fees.prototype.getBrokerFixedFees = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 3));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.ji_trade_fees} returns this
*/
proto.assets.security.ji_trade_fees.prototype.setBrokerFixedFees = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.ji_trade_fees} returns this
 */
proto.assets.security.ji_trade_fees.prototype.clearBrokerFixedFees = function() {
  return this.setBrokerFixedFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.ji_trade_fees.prototype.hasBrokerFixedFees = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal lending_fees = 4;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.ji_trade_fees.prototype.getLendingFees = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 4));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.ji_trade_fees} returns this
*/
proto.assets.security.ji_trade_fees.prototype.setLendingFees = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.ji_trade_fees} returns this
 */
proto.assets.security.ji_trade_fees.prototype.clearLendingFees = function() {
  return this.setLendingFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.ji_trade_fees.prototype.hasLendingFees = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal transaction_tax = 5;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.ji_trade_fees.prototype.getTransactionTax = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 5));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.ji_trade_fees} returns this
*/
proto.assets.security.ji_trade_fees.prototype.setTransactionTax = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.ji_trade_fees} returns this
 */
proto.assets.security.ji_trade_fees.prototype.clearTransactionTax = function() {
  return this.setTransactionTax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.ji_trade_fees.prototype.hasTransactionTax = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_open_candle.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_open_candle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_open_candle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_open_candle.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTs: (f = msg.getStartTs()) && proto.assets.security.tstamp.toObject(includeInstance, f),
    open: (f = msg.getOpen()) && proto.assets.security.decimal.toObject(includeInstance, f),
    close: (f = msg.getClose()) && proto.assets.security.decimal.toObject(includeInstance, f),
    high: (f = msg.getHigh()) && proto.assets.security.decimal.toObject(includeInstance, f),
    low: (f = msg.getLow()) && proto.assets.security.decimal.toObject(includeInstance, f),
    endTs: (f = msg.getEndTs()) && proto.assets.security.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_open_candle}
 */
proto.assets.security.asset_open_candle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_open_candle;
  return proto.assets.security.asset_open_candle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_open_candle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_open_candle}
 */
proto.assets.security.asset_open_candle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setStartTs(value);
      break;
    case 2:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setOpen(value);
      break;
    case 3:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setClose(value);
      break;
    case 4:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setHigh(value);
      break;
    case 5:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setLow(value);
      break;
    case 6:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setEndTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_open_candle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_open_candle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_open_candle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_open_candle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getOpen();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getClose();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getHigh();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getLow();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getEndTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * required tstamp start_ts = 1;
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.asset_open_candle.prototype.getStartTs = function() {
  return /** @type{!proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 1, 1));
};


/**
 * @param {!proto.assets.security.tstamp} value
 * @return {!proto.assets.security.asset_open_candle} returns this
*/
proto.assets.security.asset_open_candle.prototype.setStartTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_open_candle} returns this
 */
proto.assets.security.asset_open_candle.prototype.clearStartTs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_open_candle.prototype.hasStartTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required decimal open = 2;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.asset_open_candle.prototype.getOpen = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 2, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.asset_open_candle} returns this
*/
proto.assets.security.asset_open_candle.prototype.setOpen = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_open_candle} returns this
 */
proto.assets.security.asset_open_candle.prototype.clearOpen = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_open_candle.prototype.hasOpen = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required decimal close = 3;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.asset_open_candle.prototype.getClose = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 3, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.asset_open_candle} returns this
*/
proto.assets.security.asset_open_candle.prototype.setClose = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_open_candle} returns this
 */
proto.assets.security.asset_open_candle.prototype.clearClose = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_open_candle.prototype.hasClose = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required decimal high = 4;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.asset_open_candle.prototype.getHigh = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 4, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.asset_open_candle} returns this
*/
proto.assets.security.asset_open_candle.prototype.setHigh = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_open_candle} returns this
 */
proto.assets.security.asset_open_candle.prototype.clearHigh = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_open_candle.prototype.hasHigh = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required decimal low = 5;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.asset_open_candle.prototype.getLow = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 5, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.asset_open_candle} returns this
*/
proto.assets.security.asset_open_candle.prototype.setLow = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_open_candle} returns this
 */
proto.assets.security.asset_open_candle.prototype.clearLow = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_open_candle.prototype.hasLow = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp end_ts = 6;
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.asset_open_candle.prototype.getEndTs = function() {
  return /** @type{!proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 6, 1));
};


/**
 * @param {!proto.assets.security.tstamp} value
 * @return {!proto.assets.security.asset_open_candle} returns this
*/
proto.assets.security.asset_open_candle.prototype.setEndTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_open_candle} returns this
 */
proto.assets.security.asset_open_candle.prototype.clearEndTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_open_candle.prototype.hasEndTs = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_closed_candle.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_closed_candle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_closed_candle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_closed_candle.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTs: (f = msg.getStartTs()) && proto.assets.security.tstamp.toObject(includeInstance, f),
    open: (f = msg.getOpen()) && proto.assets.security.decimal.toObject(includeInstance, f),
    close: (f = msg.getClose()) && proto.assets.security.decimal.toObject(includeInstance, f),
    high: (f = msg.getHigh()) && proto.assets.security.decimal.toObject(includeInstance, f),
    low: (f = msg.getLow()) && proto.assets.security.decimal.toObject(includeInstance, f),
    endTs: (f = msg.getEndTs()) && proto.assets.security.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_closed_candle}
 */
proto.assets.security.asset_closed_candle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_closed_candle;
  return proto.assets.security.asset_closed_candle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_closed_candle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_closed_candle}
 */
proto.assets.security.asset_closed_candle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setStartTs(value);
      break;
    case 2:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setOpen(value);
      break;
    case 3:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setClose(value);
      break;
    case 4:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setHigh(value);
      break;
    case 5:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setLow(value);
      break;
    case 6:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setEndTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_closed_candle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_closed_candle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_closed_candle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_closed_candle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getOpen();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getClose();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getHigh();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getLow();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getEndTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * required tstamp start_ts = 1;
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.asset_closed_candle.prototype.getStartTs = function() {
  return /** @type{!proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 1, 1));
};


/**
 * @param {!proto.assets.security.tstamp} value
 * @return {!proto.assets.security.asset_closed_candle} returns this
*/
proto.assets.security.asset_closed_candle.prototype.setStartTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_closed_candle} returns this
 */
proto.assets.security.asset_closed_candle.prototype.clearStartTs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_closed_candle.prototype.hasStartTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required decimal open = 2;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.asset_closed_candle.prototype.getOpen = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 2, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.asset_closed_candle} returns this
*/
proto.assets.security.asset_closed_candle.prototype.setOpen = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_closed_candle} returns this
 */
proto.assets.security.asset_closed_candle.prototype.clearOpen = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_closed_candle.prototype.hasOpen = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required decimal close = 3;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.asset_closed_candle.prototype.getClose = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 3, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.asset_closed_candle} returns this
*/
proto.assets.security.asset_closed_candle.prototype.setClose = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_closed_candle} returns this
 */
proto.assets.security.asset_closed_candle.prototype.clearClose = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_closed_candle.prototype.hasClose = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required decimal high = 4;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.asset_closed_candle.prototype.getHigh = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 4, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.asset_closed_candle} returns this
*/
proto.assets.security.asset_closed_candle.prototype.setHigh = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_closed_candle} returns this
 */
proto.assets.security.asset_closed_candle.prototype.clearHigh = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_closed_candle.prototype.hasHigh = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required decimal low = 5;
 * @return {!proto.assets.security.decimal}
 */
proto.assets.security.asset_closed_candle.prototype.getLow = function() {
  return /** @type{!proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 5, 1));
};


/**
 * @param {!proto.assets.security.decimal} value
 * @return {!proto.assets.security.asset_closed_candle} returns this
*/
proto.assets.security.asset_closed_candle.prototype.setLow = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_closed_candle} returns this
 */
proto.assets.security.asset_closed_candle.prototype.clearLow = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_closed_candle.prototype.hasLow = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp end_ts = 6;
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.asset_closed_candle.prototype.getEndTs = function() {
  return /** @type{!proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 6, 1));
};


/**
 * @param {!proto.assets.security.tstamp} value
 * @return {!proto.assets.security.asset_closed_candle} returns this
*/
proto.assets.security.asset_closed_candle.prototype.setEndTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.asset_closed_candle} returns this
 */
proto.assets.security.asset_closed_candle.prototype.clearEndTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_closed_candle.prototype.hasEndTs = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_security_multipliers.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_security_multipliers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_security_multipliers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_multipliers.toObject = function(includeInstance, msg) {
  var f, obj = {
    lotMultiplier: (f = msg.getLotMultiplier()) && proto.assets.security.decimal.toObject(includeInstance, f),
    contractSize: (f = msg.getContractSize()) && proto.assets.security.decimal.toObject(includeInstance, f),
    conversionFactor: (f = msg.getConversionFactor()) && proto.assets.security.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_security_multipliers}
 */
proto.assets.security.asset_security_multipliers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_security_multipliers;
  return proto.assets.security.asset_security_multipliers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_security_multipliers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_security_multipliers}
 */
proto.assets.security.asset_security_multipliers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setLotMultiplier(value);
      break;
    case 2:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setContractSize(value);
      break;
    case 3:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setConversionFactor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_security_multipliers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_security_multipliers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_security_multipliers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_multipliers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLotMultiplier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getContractSize();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getConversionFactor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal lot_multiplier = 1;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_multipliers.prototype.getLotMultiplier = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 1));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_multipliers} returns this
*/
proto.assets.security.asset_security_multipliers.prototype.setLotMultiplier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_multipliers} returns this
 */
proto.assets.security.asset_security_multipliers.prototype.clearLotMultiplier = function() {
  return this.setLotMultiplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_multipliers.prototype.hasLotMultiplier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal contract_size = 2;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_multipliers.prototype.getContractSize = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 2));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_multipliers} returns this
*/
proto.assets.security.asset_security_multipliers.prototype.setContractSize = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_multipliers} returns this
 */
proto.assets.security.asset_security_multipliers.prototype.clearContractSize = function() {
  return this.setContractSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_multipliers.prototype.hasContractSize = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal conversion_factor = 3;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_multipliers.prototype.getConversionFactor = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 3));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_multipliers} returns this
*/
proto.assets.security.asset_security_multipliers.prototype.setConversionFactor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_multipliers} returns this
 */
proto.assets.security.asset_security_multipliers.prototype.clearConversionFactor = function() {
  return this.setConversionFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_multipliers.prototype.hasConversionFactor = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.asset_security_bond_data.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.asset_security_bond_data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.asset_security_bond_data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_bond_data.toObject = function(includeInstance, msg) {
  var f, obj = {
    accruedInterest: (f = msg.getAccruedInterest()) && proto.assets.security.decimal.toObject(includeInstance, f),
    couponRate: (f = msg.getCouponRate()) && proto.assets.security.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.asset_security_bond_data}
 */
proto.assets.security.asset_security_bond_data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.asset_security_bond_data;
  return proto.assets.security.asset_security_bond_data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.asset_security_bond_data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.asset_security_bond_data}
 */
proto.assets.security.asset_security_bond_data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setAccruedInterest(value);
      break;
    case 2:
      var value = new proto.assets.security.decimal;
      reader.readMessage(value,proto.assets.security.decimal.deserializeBinaryFromReader);
      msg.setCouponRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.asset_security_bond_data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.asset_security_bond_data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.asset_security_bond_data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.asset_security_bond_data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccruedInterest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
  f = message.getCouponRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal accrued_interest = 1;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_bond_data.prototype.getAccruedInterest = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 1));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_bond_data} returns this
*/
proto.assets.security.asset_security_bond_data.prototype.setAccruedInterest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_bond_data} returns this
 */
proto.assets.security.asset_security_bond_data.prototype.clearAccruedInterest = function() {
  return this.setAccruedInterest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_bond_data.prototype.hasAccruedInterest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal coupon_rate = 2;
 * @return {?proto.assets.security.decimal}
 */
proto.assets.security.asset_security_bond_data.prototype.getCouponRate = function() {
  return /** @type{?proto.assets.security.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.decimal, 2));
};


/**
 * @param {?proto.assets.security.decimal|undefined} value
 * @return {!proto.assets.security.asset_security_bond_data} returns this
*/
proto.assets.security.asset_security_bond_data.prototype.setCouponRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.asset_security_bond_data} returns this
 */
proto.assets.security.asset_security_bond_data.prototype.clearCouponRate = function() {
  return this.setCouponRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.asset_security_bond_data.prototype.hasCouponRate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.update_payload.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.update_payload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.update_payload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.update_payload.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetRefData: (f = msg.getAssetRefData()) && proto.assets.security.asset_ref_data.toObject(includeInstance, f),
    assetSecurityRefData: (f = msg.getAssetSecurityRefData()) && proto.assets.security.asset_security_ref_data.toObject(includeInstance, f),
    assetSecurityTrade: (f = msg.getAssetSecurityTrade()) && proto.assets.security.asset_security_trade.toObject(includeInstance, f),
    assetSecurityBidAsk: (f = msg.getAssetSecurityBidAsk()) && proto.assets.security.asset_security_bid_ask.toObject(includeInstance, f),
    assetSecurityVolume: (f = msg.getAssetSecurityVolume()) && proto.assets.security.asset_security_volume.toObject(includeInstance, f),
    assetSecurityStatus: (f = msg.getAssetSecurityStatus()) && proto.assets.security.asset_security_status.toObject(includeInstance, f),
    assetExchangeRate: (f = msg.getAssetExchangeRate()) && proto.assets.security.asset_exchange_rate.toObject(includeInstance, f),
    jiTrade: (f = msg.getJiTrade()) && proto.assets.security.ji_trade.toObject(includeInstance, f),
    jiTradeFees: (f = msg.getJiTradeFees()) && proto.assets.security.ji_trade_fees.toObject(includeInstance, f),
    assetOpenCandle: (f = msg.getAssetOpenCandle()) && proto.assets.security.asset_open_candle.toObject(includeInstance, f),
    assetClosedCandle: (f = msg.getAssetClosedCandle()) && proto.assets.security.asset_closed_candle.toObject(includeInstance, f),
    assetSecurityMultipliers: (f = msg.getAssetSecurityMultipliers()) && proto.assets.security.asset_security_multipliers.toObject(includeInstance, f),
    assetSecurityBondData: (f = msg.getAssetSecurityBondData()) && proto.assets.security.asset_security_bond_data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.update_payload}
 */
proto.assets.security.update_payload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.update_payload;
  return proto.assets.security.update_payload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.update_payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.update_payload}
 */
proto.assets.security.update_payload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.asset_ref_data;
      reader.readMessage(value,proto.assets.security.asset_ref_data.deserializeBinaryFromReader);
      msg.setAssetRefData(value);
      break;
    case 2:
      var value = new proto.assets.security.asset_security_ref_data;
      reader.readMessage(value,proto.assets.security.asset_security_ref_data.deserializeBinaryFromReader);
      msg.setAssetSecurityRefData(value);
      break;
    case 3:
      var value = new proto.assets.security.asset_security_trade;
      reader.readMessage(value,proto.assets.security.asset_security_trade.deserializeBinaryFromReader);
      msg.setAssetSecurityTrade(value);
      break;
    case 4:
      var value = new proto.assets.security.asset_security_bid_ask;
      reader.readMessage(value,proto.assets.security.asset_security_bid_ask.deserializeBinaryFromReader);
      msg.setAssetSecurityBidAsk(value);
      break;
    case 5:
      var value = new proto.assets.security.asset_security_volume;
      reader.readMessage(value,proto.assets.security.asset_security_volume.deserializeBinaryFromReader);
      msg.setAssetSecurityVolume(value);
      break;
    case 6:
      var value = new proto.assets.security.asset_security_status;
      reader.readMessage(value,proto.assets.security.asset_security_status.deserializeBinaryFromReader);
      msg.setAssetSecurityStatus(value);
      break;
    case 7:
      var value = new proto.assets.security.asset_exchange_rate;
      reader.readMessage(value,proto.assets.security.asset_exchange_rate.deserializeBinaryFromReader);
      msg.setAssetExchangeRate(value);
      break;
    case 8:
      var value = new proto.assets.security.ji_trade;
      reader.readMessage(value,proto.assets.security.ji_trade.deserializeBinaryFromReader);
      msg.setJiTrade(value);
      break;
    case 9:
      var value = new proto.assets.security.ji_trade_fees;
      reader.readMessage(value,proto.assets.security.ji_trade_fees.deserializeBinaryFromReader);
      msg.setJiTradeFees(value);
      break;
    case 10:
      var value = new proto.assets.security.asset_open_candle;
      reader.readMessage(value,proto.assets.security.asset_open_candle.deserializeBinaryFromReader);
      msg.setAssetOpenCandle(value);
      break;
    case 11:
      var value = new proto.assets.security.asset_closed_candle;
      reader.readMessage(value,proto.assets.security.asset_closed_candle.deserializeBinaryFromReader);
      msg.setAssetClosedCandle(value);
      break;
    case 12:
      var value = new proto.assets.security.asset_security_multipliers;
      reader.readMessage(value,proto.assets.security.asset_security_multipliers.deserializeBinaryFromReader);
      msg.setAssetSecurityMultipliers(value);
      break;
    case 13:
      var value = new proto.assets.security.asset_security_bond_data;
      reader.readMessage(value,proto.assets.security.asset_security_bond_data.deserializeBinaryFromReader);
      msg.setAssetSecurityBondData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.update_payload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.update_payload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.update_payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.update_payload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetRefData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.asset_ref_data.serializeBinaryToWriter
    );
  }
  f = message.getAssetSecurityRefData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.asset_security_ref_data.serializeBinaryToWriter
    );
  }
  f = message.getAssetSecurityTrade();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.asset_security_trade.serializeBinaryToWriter
    );
  }
  f = message.getAssetSecurityBidAsk();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.security.asset_security_bid_ask.serializeBinaryToWriter
    );
  }
  f = message.getAssetSecurityVolume();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.security.asset_security_volume.serializeBinaryToWriter
    );
  }
  f = message.getAssetSecurityStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.security.asset_security_status.serializeBinaryToWriter
    );
  }
  f = message.getAssetExchangeRate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.security.asset_exchange_rate.serializeBinaryToWriter
    );
  }
  f = message.getJiTrade();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.security.ji_trade.serializeBinaryToWriter
    );
  }
  f = message.getJiTradeFees();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.security.ji_trade_fees.serializeBinaryToWriter
    );
  }
  f = message.getAssetOpenCandle();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.security.asset_open_candle.serializeBinaryToWriter
    );
  }
  f = message.getAssetClosedCandle();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.security.asset_closed_candle.serializeBinaryToWriter
    );
  }
  f = message.getAssetSecurityMultipliers();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.security.asset_security_multipliers.serializeBinaryToWriter
    );
  }
  f = message.getAssetSecurityBondData();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.security.asset_security_bond_data.serializeBinaryToWriter
    );
  }
};


/**
 * optional asset_ref_data asset_ref_data_ = 1;
 * @return {?proto.assets.security.asset_ref_data}
 */
proto.assets.security.update_payload.prototype.getAssetRefData = function() {
  return /** @type{?proto.assets.security.asset_ref_data} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_ref_data, 1));
};


/**
 * @param {?proto.assets.security.asset_ref_data|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetRefData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetRefData = function() {
  return this.setAssetRefData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetRefData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional asset_security_ref_data asset_security_ref_data_ = 2;
 * @return {?proto.assets.security.asset_security_ref_data}
 */
proto.assets.security.update_payload.prototype.getAssetSecurityRefData = function() {
  return /** @type{?proto.assets.security.asset_security_ref_data} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_security_ref_data, 2));
};


/**
 * @param {?proto.assets.security.asset_security_ref_data|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetSecurityRefData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetSecurityRefData = function() {
  return this.setAssetSecurityRefData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetSecurityRefData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional asset_security_trade asset_security_trade_ = 3;
 * @return {?proto.assets.security.asset_security_trade}
 */
proto.assets.security.update_payload.prototype.getAssetSecurityTrade = function() {
  return /** @type{?proto.assets.security.asset_security_trade} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_security_trade, 3));
};


/**
 * @param {?proto.assets.security.asset_security_trade|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetSecurityTrade = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetSecurityTrade = function() {
  return this.setAssetSecurityTrade(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetSecurityTrade = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional asset_security_bid_ask asset_security_bid_ask_ = 4;
 * @return {?proto.assets.security.asset_security_bid_ask}
 */
proto.assets.security.update_payload.prototype.getAssetSecurityBidAsk = function() {
  return /** @type{?proto.assets.security.asset_security_bid_ask} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_security_bid_ask, 4));
};


/**
 * @param {?proto.assets.security.asset_security_bid_ask|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetSecurityBidAsk = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetSecurityBidAsk = function() {
  return this.setAssetSecurityBidAsk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetSecurityBidAsk = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional asset_security_volume asset_security_volume_ = 5;
 * @return {?proto.assets.security.asset_security_volume}
 */
proto.assets.security.update_payload.prototype.getAssetSecurityVolume = function() {
  return /** @type{?proto.assets.security.asset_security_volume} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_security_volume, 5));
};


/**
 * @param {?proto.assets.security.asset_security_volume|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetSecurityVolume = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetSecurityVolume = function() {
  return this.setAssetSecurityVolume(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetSecurityVolume = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional asset_security_status asset_security_status_ = 6;
 * @return {?proto.assets.security.asset_security_status}
 */
proto.assets.security.update_payload.prototype.getAssetSecurityStatus = function() {
  return /** @type{?proto.assets.security.asset_security_status} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_security_status, 6));
};


/**
 * @param {?proto.assets.security.asset_security_status|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetSecurityStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetSecurityStatus = function() {
  return this.setAssetSecurityStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetSecurityStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional asset_exchange_rate asset_exchange_rate_ = 7;
 * @return {?proto.assets.security.asset_exchange_rate}
 */
proto.assets.security.update_payload.prototype.getAssetExchangeRate = function() {
  return /** @type{?proto.assets.security.asset_exchange_rate} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_exchange_rate, 7));
};


/**
 * @param {?proto.assets.security.asset_exchange_rate|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetExchangeRate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetExchangeRate = function() {
  return this.setAssetExchangeRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetExchangeRate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ji_trade ji_trade_ = 8;
 * @return {?proto.assets.security.ji_trade}
 */
proto.assets.security.update_payload.prototype.getJiTrade = function() {
  return /** @type{?proto.assets.security.ji_trade} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.ji_trade, 8));
};


/**
 * @param {?proto.assets.security.ji_trade|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setJiTrade = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearJiTrade = function() {
  return this.setJiTrade(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasJiTrade = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ji_trade_fees ji_trade_fees_ = 9;
 * @return {?proto.assets.security.ji_trade_fees}
 */
proto.assets.security.update_payload.prototype.getJiTradeFees = function() {
  return /** @type{?proto.assets.security.ji_trade_fees} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.ji_trade_fees, 9));
};


/**
 * @param {?proto.assets.security.ji_trade_fees|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setJiTradeFees = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearJiTradeFees = function() {
  return this.setJiTradeFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasJiTradeFees = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional asset_open_candle asset_open_candle_ = 10;
 * @return {?proto.assets.security.asset_open_candle}
 */
proto.assets.security.update_payload.prototype.getAssetOpenCandle = function() {
  return /** @type{?proto.assets.security.asset_open_candle} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_open_candle, 10));
};


/**
 * @param {?proto.assets.security.asset_open_candle|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetOpenCandle = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetOpenCandle = function() {
  return this.setAssetOpenCandle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetOpenCandle = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional asset_closed_candle asset_closed_candle_ = 11;
 * @return {?proto.assets.security.asset_closed_candle}
 */
proto.assets.security.update_payload.prototype.getAssetClosedCandle = function() {
  return /** @type{?proto.assets.security.asset_closed_candle} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_closed_candle, 11));
};


/**
 * @param {?proto.assets.security.asset_closed_candle|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetClosedCandle = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetClosedCandle = function() {
  return this.setAssetClosedCandle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetClosedCandle = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional asset_security_multipliers asset_security_multipliers_ = 12;
 * @return {?proto.assets.security.asset_security_multipliers}
 */
proto.assets.security.update_payload.prototype.getAssetSecurityMultipliers = function() {
  return /** @type{?proto.assets.security.asset_security_multipliers} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_security_multipliers, 12));
};


/**
 * @param {?proto.assets.security.asset_security_multipliers|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetSecurityMultipliers = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetSecurityMultipliers = function() {
  return this.setAssetSecurityMultipliers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetSecurityMultipliers = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional asset_security_bond_data asset_security_bond_data_ = 13;
 * @return {?proto.assets.security.asset_security_bond_data}
 */
proto.assets.security.update_payload.prototype.getAssetSecurityBondData = function() {
  return /** @type{?proto.assets.security.asset_security_bond_data} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.asset_security_bond_data, 13));
};


/**
 * @param {?proto.assets.security.asset_security_bond_data|undefined} value
 * @return {!proto.assets.security.update_payload} returns this
*/
proto.assets.security.update_payload.prototype.setAssetSecurityBondData = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_payload} returns this
 */
proto.assets.security.update_payload.prototype.clearAssetSecurityBondData = function() {
  return this.setAssetSecurityBondData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_payload.prototype.hasAssetSecurityBondData = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.update_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.update_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.update_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.update_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.security.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.security.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.update_snapshot}
 */
proto.assets.security.update_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.update_snapshot;
  return proto.assets.security.update_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.update_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.update_snapshot}
 */
proto.assets.security.update_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.security.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 5:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 6:
      var value = new proto.assets.security.update_payload;
      reader.readMessage(value,proto.assets.security.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.update_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.update_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.update_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.update_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.security.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.security.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.security.update_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.security.update_type}
 */
proto.assets.security.update_snapshot.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.security.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.security.update_type} value
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_snapshot.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.security.update_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required string key = 4;
 * @return {string}
 */
proto.assets.security.update_snapshot.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.clearKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_snapshot.prototype.hasKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required tstamp event_ts = 5;
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.update_snapshot.prototype.getEventTs = function() {
  return /** @type{!proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 5, 1));
};


/**
 * @param {!proto.assets.security.tstamp} value
 * @return {!proto.assets.security.update_snapshot} returns this
*/
proto.assets.security.update_snapshot.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_snapshot.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional update_payload sigmas = 6;
 * @return {?proto.assets.security.update_payload}
 */
proto.assets.security.update_snapshot.prototype.getSigmas = function() {
  return /** @type{?proto.assets.security.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.update_payload, 6));
};


/**
 * @param {?proto.assets.security.update_payload|undefined} value
 * @return {!proto.assets.security.update_snapshot} returns this
*/
proto.assets.security.update_snapshot.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_snapshot} returns this
 */
proto.assets.security.update_snapshot.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_snapshot.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.end_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.end_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.end_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.end_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.end_snapshot}
 */
proto.assets.security.end_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.end_snapshot;
  return proto.assets.security.end_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.end_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.end_snapshot}
 */
proto.assets.security.end_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.end_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.end_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.end_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.end_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.security.end_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.end_snapshot} returns this
 */
proto.assets.security.end_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.end_snapshot} returns this
 */
proto.assets.security.end_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.end_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string service = 2;
 * @return {string}
 */
proto.assets.security.end_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.end_snapshot} returns this
 */
proto.assets.security.end_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.end_snapshot} returns this
 */
proto.assets.security.end_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.end_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.update_ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.update_ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.update_ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.update_ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    sequence: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.security.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.security.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.update_ticker}
 */
proto.assets.security.update_ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.update_ticker;
  return proto.assets.security.update_ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.update_ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.update_ticker}
 */
proto.assets.security.update_ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.security.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSequence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = new proto.assets.security.tstamp;
      reader.readMessage(value,proto.assets.security.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 7:
      var value = new proto.assets.security.update_payload;
      reader.readMessage(value,proto.assets.security.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.update_ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.update_ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.update_ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.update_ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.security.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.security.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.security.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.security.update_ticker.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_ticker.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.security.update_type}
 */
proto.assets.security.update_ticker.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.security.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.security.update_type} value
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_ticker.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.security.update_ticker.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_ticker.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required sint64 sequence = 4;
 * @return {number}
 */
proto.assets.security.update_ticker.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.setSequence = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.clearSequence = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_ticker.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required string key = 5;
 * @return {string}
 */
proto.assets.security.update_ticker.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.clearKey = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_ticker.prototype.hasKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp event_ts = 6;
 * @return {!proto.assets.security.tstamp}
 */
proto.assets.security.update_ticker.prototype.getEventTs = function() {
  return /** @type{!proto.assets.security.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.tstamp, 6, 1));
};


/**
 * @param {!proto.assets.security.tstamp} value
 * @return {!proto.assets.security.update_ticker} returns this
*/
proto.assets.security.update_ticker.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_ticker.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional update_payload sigmas = 7;
 * @return {?proto.assets.security.update_payload}
 */
proto.assets.security.update_ticker.prototype.getSigmas = function() {
  return /** @type{?proto.assets.security.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.update_payload, 7));
};


/**
 * @param {?proto.assets.security.update_payload|undefined} value
 * @return {!proto.assets.security.update_ticker} returns this
*/
proto.assets.security.update_ticker.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.update_ticker} returns this
 */
proto.assets.security.update_ticker.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.update_ticker.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.alive.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.alive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.alive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.alive.toObject = function(includeInstance, msg) {
  var f, obj = {
    aliveId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.alive}
 */
proto.assets.security.alive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.alive;
  return proto.assets.security.alive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.alive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.alive}
 */
proto.assets.security.alive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setAliveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.alive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.alive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.alive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.alive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
};


/**
 * required sint64 alive_id = 1;
 * @return {number}
 */
proto.assets.security.alive.prototype.getAliveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.security.alive} returns this
 */
proto.assets.security.alive.prototype.setAliveId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.security.alive} returns this
 */
proto.assets.security.alive.prototype.clearAliveId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.alive.prototype.hasAliveId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.assets.security.feed_event.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.assets.security.feed_event.EventTypeCase = {
  EVENT_TYPE_NOT_SET: 0,
  SNAPSHOT: 1,
  TICKER: 2,
  END_SNAPSHOT: 3,
  ALIVE: 4
};

/**
 * @return {proto.assets.security.feed_event.EventTypeCase}
 */
proto.assets.security.feed_event.prototype.getEventTypeCase = function() {
  return /** @type {proto.assets.security.feed_event.EventTypeCase} */(jspb.Message.computeOneofCase(this, proto.assets.security.feed_event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.security.feed_event.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.security.feed_event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.security.feed_event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.feed_event.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.assets.security.update_snapshot.toObject(includeInstance, f),
    ticker: (f = msg.getTicker()) && proto.assets.security.update_ticker.toObject(includeInstance, f),
    endSnapshot: (f = msg.getEndSnapshot()) && proto.assets.security.end_snapshot.toObject(includeInstance, f),
    alive: (f = msg.getAlive()) && proto.assets.security.alive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.security.feed_event}
 */
proto.assets.security.feed_event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.security.feed_event;
  return proto.assets.security.feed_event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.security.feed_event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.security.feed_event}
 */
proto.assets.security.feed_event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.security.update_snapshot;
      reader.readMessage(value,proto.assets.security.update_snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 2:
      var value = new proto.assets.security.update_ticker;
      reader.readMessage(value,proto.assets.security.update_ticker.deserializeBinaryFromReader);
      msg.setTicker(value);
      break;
    case 3:
      var value = new proto.assets.security.end_snapshot;
      reader.readMessage(value,proto.assets.security.end_snapshot.deserializeBinaryFromReader);
      msg.setEndSnapshot(value);
      break;
    case 4:
      var value = new proto.assets.security.alive;
      reader.readMessage(value,proto.assets.security.alive.deserializeBinaryFromReader);
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.security.feed_event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.security.feed_event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.security.feed_event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.security.feed_event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.security.update_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getTicker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.security.update_ticker.serializeBinaryToWriter
    );
  }
  f = message.getEndSnapshot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.security.end_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.security.alive.serializeBinaryToWriter
    );
  }
};


/**
 * optional update_snapshot snapshot = 1;
 * @return {?proto.assets.security.update_snapshot}
 */
proto.assets.security.feed_event.prototype.getSnapshot = function() {
  return /** @type{?proto.assets.security.update_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.update_snapshot, 1));
};


/**
 * @param {?proto.assets.security.update_snapshot|undefined} value
 * @return {!proto.assets.security.feed_event} returns this
*/
proto.assets.security.feed_event.prototype.setSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.assets.security.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.feed_event} returns this
 */
proto.assets.security.feed_event.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.feed_event.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional update_ticker ticker = 2;
 * @return {?proto.assets.security.update_ticker}
 */
proto.assets.security.feed_event.prototype.getTicker = function() {
  return /** @type{?proto.assets.security.update_ticker} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.update_ticker, 2));
};


/**
 * @param {?proto.assets.security.update_ticker|undefined} value
 * @return {!proto.assets.security.feed_event} returns this
*/
proto.assets.security.feed_event.prototype.setTicker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.assets.security.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.feed_event} returns this
 */
proto.assets.security.feed_event.prototype.clearTicker = function() {
  return this.setTicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.feed_event.prototype.hasTicker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional end_snapshot end_snapshot = 3;
 * @return {?proto.assets.security.end_snapshot}
 */
proto.assets.security.feed_event.prototype.getEndSnapshot = function() {
  return /** @type{?proto.assets.security.end_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.end_snapshot, 3));
};


/**
 * @param {?proto.assets.security.end_snapshot|undefined} value
 * @return {!proto.assets.security.feed_event} returns this
*/
proto.assets.security.feed_event.prototype.setEndSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.assets.security.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.feed_event} returns this
 */
proto.assets.security.feed_event.prototype.clearEndSnapshot = function() {
  return this.setEndSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.feed_event.prototype.hasEndSnapshot = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional alive alive = 4;
 * @return {?proto.assets.security.alive}
 */
proto.assets.security.feed_event.prototype.getAlive = function() {
  return /** @type{?proto.assets.security.alive} */ (
    jspb.Message.getWrapperField(this, proto.assets.security.alive, 4));
};


/**
 * @param {?proto.assets.security.alive|undefined} value
 * @return {!proto.assets.security.feed_event} returns this
*/
proto.assets.security.feed_event.prototype.setAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.assets.security.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.security.feed_event} returns this
 */
proto.assets.security.feed_event.prototype.clearAlive = function() {
  return this.setAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.security.feed_event.prototype.hasAlive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.assets.security.update_type = {
  SNAPSHOT: 1,
  VARIATIONS: 2
};

goog.object.extend(exports, proto.assets.security);
