import { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, Modal, Switch, Tooltip } from 'antd'
import { ColumnKeys, TableType, FrameOptions, Frame, TableConfig, ColumnConfig } from 'types/store'
import { useDispatch } from 'react-redux'
import styles from './columnSelector.module.scss'
import { updateFrameOptions } from 'store/dashboard/actions'
import { getColumns } from 'v2/components/settings/modals/SelectColumnsModal/columns'

type ColumnSelectorProps = {
	frame: Frame
	open: boolean
	tableTypes: TableType[]
	onClose: () => void
	baseCurrency?: string
}

export const ColumnSelector = ({ frame, open, onClose, tableTypes, baseCurrency }: ColumnSelectorProps) => {
  const dispatch = useDispatch()
  const storeColumnsConfigs = frame.options.columnsConfigs || []
  const [columnsConfigs, setSelectedColums] = useState<TableConfig[]>(storeColumnsConfigs)

	const columnsLists = useMemo(() => tableTypes.map(type => ({tableName: type, tableColumns: getColumns(type, baseCurrency ?? '')})), [frame.options.columnsConfigs])

	const save = useCallback(
    (columnsConfigs: TableConfig[]) => {
      const options: FrameOptions = { ...frame.options, columnsConfigs }

      dispatch(updateFrameOptions({ id: frame.id, options }))
      onClose()
    },
    [frame],
  )

	useEffect(() => {
		// If there are no columns selected, set the initial state
		if (storeColumnsConfigs.length === 0) {
			setSelectedColums(columnsLists.map((table) => ({table: table.tableName, columns: table.tableColumns.map((column) => ({key: column.key, hidden: false, width: 0}))})))
		}
	}, [])

  const onSetSelectedKey = (selectedCols: ColumnConfig, tableType: TableType) => {
		if (columnsConfigs.length > 0) {
			// Insert new key in the respective table
			const updatedKeys = columnsConfigs.map((list) => {
				if (list.table === tableType) {
					return { ...list, columns: list.columns.map((col) => col.key === selectedCols.key ? selectedCols : col) }
				}
				return list
			})
			// console.log('updatedKeys', updatedKeys)
			setSelectedColums(updatedKeys)
			return
		}
		setSelectedColums([
			{table: tableType, columns: [selectedCols]}
		])
  }

  return (
		<Modal
			open={open}
			onCancel={onClose}
			onOk={() => save(columnsConfigs)}
			title="Show / Hide Columns"
			width={560}
			style={{ backgroundColor: 'black'}}
		>
			<div className={styles['list-container']}>
				{columnsLists.map((list, i) => {
					const listName = `list_${list.tableName}`
					return (
						<div key={listName} className={styles['switch-col']}>
							<h3 className={styles['col-title']}>{`${list.tableName} columns`}</h3>
							<div className={styles['col']}>
								{list.tableColumns.map(column => {
									// const col = columnsConfigs.some((item) => item.table === list.tableName && !item.columns.find(col => col.key === column.key)?.hidden)
									const currentColumn = columnsConfigs.find((item) => item.table === list.tableName)?.columns.find(col => col.key === column.key)
									return (
										<div className={styles['switch']} key={column.key}>
											<Switch
												key={`${listName}_${column.key}`}
												checked={!currentColumn?.hidden}
												onChange={(e) => {
													onSetSelectedKey({key: currentColumn.key, hidden: !e, width: 0}, list.tableName)
												}}
											/>
											<Tooltip mouseEnterDelay={0.5} title={column.title}>
												{column.shortName}
											</Tooltip>
										</div>
									)
								})}
							</div>
						</div>
					)
				})}
			</div>
		</Modal>
  )
}
