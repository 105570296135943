import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import * as K from './constants'
import * as Api from 'types/api'
import * as Store from 'types/store'
import Debug from 'debug'
import { ErrorCode, LoadingState } from 'types/enums'
import * as api from 'api/sessions'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToSessionAsset } from 'services/store/mapService'
import { getAssetsFromIds } from 'services/sessionService'
import { removeFramesBySessionType } from 'store/dashboard/actions'
import { AnyAction } from 'redux'
import { fetchResources } from 'store/App/sagas'
import { showDetailedError } from 'core/helpers'

const debug = new Debug('Frontend')

export const selectSessions = (state) => state.root.sessions.data
export const selectAssets = (state) => state.root.resources.assets.data
const selectSessionAssets = (state) => state.root.pages.setupSession.assets

export function* fetchSessionSetupUpdate(action: AnyAction) {
  debug('Saga fetchSessionSetupUpdate')
  try {
    const sessionId = action.payload as number
    const response = yield call(api.getSessionAssets, sessionId)
    if (isApiError(response) || isGraphQlError(response)) {
      throw new ApplicationError(ErrorCode.Api, 'fetchSessionSetupUpdate failed')
    }

    const json = (response as Api.SessionAssetsResponse).data.application_asset_model_instance
    const sessionAssets = jsonToSessionAsset(json)
    const allAssets: Array<Store.Asset> = yield select(selectAssets)
    const assets = getAssetsFromIds(sessionAssets, allAssets)

    // // save in redux
    yield put({ type: K.UPDATE_ASSETS_SUCCESFULL, payload: assets })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
    debug('fetchSessionSetupUpdate Error', error)
  }
}

export function* loadSessionSetup(action: AnyAction) {
  debug('Saga getSession')
  try {
    yield put({ type: K.RESET_PAGE, payload: null })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })

    //Retrieve resources
    yield call(fetchResources)
    yield fetchSessionSetupUpdate(action)
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
    debug('getSession Error', error)
  }
}

export function* removeSessionAssets(action: AnyAction) {
  debug('Saga removeSessionAssets')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleting })
    const { sessionId, assets } = action.payload as Store.UpdateSessionAssetsPayload

    const assetIds = assets.map((asset) => asset.id)

    //(GG-2021) Send to server and don't wait...otherwise UI will take too much to update
    // yield call(api.removeSessionAssets, sessionId, assetIds)
    const response = yield call(api.removeSessionAssets, sessionId, assetIds)

    if (isApiError(response) || isGraphQlError(response)) {
      // console.log('removeSessionAssets failed', response)
      showDetailedError(response.result.responseEnum, response.result.description)
      yield put({ type: K.SET_STATUS, payload: LoadingState.DeleteFailed })

      throw new ApplicationError(ErrorCode.Api, 'removeSessionAssets failed')
    }

    const sessionAssets: Array<Store.Asset> = yield select(selectSessionAssets)
    const newSessionAssets = sessionAssets.filter((asset) => !assetIds.includes(asset.id))

    // // save in redux
    yield put({ type: K.UPDATE_ASSETS_SUCCESFULL, payload: newSessionAssets })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleted })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.DeleteFailed })
    debug('removeSessionAssets Error', error)
  }
}

export function* addSessionAssets(action: AnyAction) {
  debug('Saga addSessionAssets')
  try {
    // console.log('adding asset', action.payload)
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })
    const { sessionId, assets, sessionType } = action.payload as Store.UpdateSessionAssetsPayload

    const assetIds = assets.map((asset) => asset.id)

    const response = yield call(api.addSessionAssets, sessionId, assetIds)
    if (isApiError(response) || isGraphQlError(response)) {
      debug('fetch addSessionAssets failed', response, response as ApiError)

      yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })

      throw new ApplicationError(ErrorCode.Api, 'fetch addSessionAssets failed')
    }

    // const sessions: Array<Store.Session> = yield select(selectSessions)
    // const session = sessions.find((session) => session.id === sessionId)

    const sessionAssets: Array<Store.Asset> = yield select(selectSessionAssets)
    // const sessionAssets = response.content.assets

    const newSessionAssets = [...(sessionAssets ?? []), ...assets.map((asset) => ({ ...asset }))]

    // TODO: Check this:
    // Remove all frames after restarting session
    // if (sessionAssets && sessionAssets.length === 0) {
    //   yield put(removeFramesBySessionType(sessionType))
    // }

    // save in redux
    yield put({ type: K.UPDATE_ASSETS_SUCCESFULL, payload: newSessionAssets })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })

    // console.log('addSessionAssets Error', error)
    debug('addSessionAssets Error', error)
  }
}

export function* fetchBrokenEntities(action: AnyAction) {
  debug('Saga addSessionAssets')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })
    const sessionId = action.payload as number

    const response = yield call(api.getBrokenEntities, sessionId)
    if (isApiError(response)) {
      debug('fetch removeSessionAssets failed', response, response as ApiError)
      throw new ApplicationError(ErrorCode.Api, 'fetch addSessionAssets failed')
    }
    // // save in redux
    const data = response as Api.SessionBrokenEntities
    yield put({ type: K.FETCH_SESSION_BROKEN_ENTITIES_SUCCESS, payload: data.content })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
    debug('addSessionAssets Error', error)
  }
}

function* watch() {
  yield all([
    takeLatest(K.FETCH_SESSION_ASSETS, loadSessionSetup),
    takeLatest(K.FETCH_SESSION_SETUP_UPDATE, fetchSessionSetupUpdate),
    takeLatest(K.UPDATE_REMOVE_ASSETS, removeSessionAssets),
    takeLatest(K.UPDATE_ADD_ASSETS, addSessionAssets),
    takeLatest(K.FETCH_SESSION_BROKEN_ENTITIES, fetchBrokenEntities),
  ])
}

export default watch
