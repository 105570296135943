import React, { useEffect, useState } from "react"
import { Col, Modal, Radio, Row, Switch, Table, Typography } from "antd"

export const OverrideModal = ({
  onSave,
  listOfAssets,
  overrideParams,
  setOverrideParams,
  overrideModalOpen,
  modifiedParamsList,
  setOverrideModalOpen,
}) => {

  const handleOk = () => {
    onSave()
    setOverrideModalOpen(false)
  }

  const handleCancel = () => {
    setOverrideModalOpen(false)
  }

  return (
    <Modal
      title="Confirm custom parameters update"
      open={overrideModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="group-form"
      className="params-override-modal"
      style={{backgroundColor: 'black'}}
      okText="Save"
    >
      {

      }
      <Typography.Text strong>
        {
          modifiedParamsList.length > 0
            ? 'The following model parameters can be overridden:'
            : 'Assets model parameter values can be overriden'
        }
      </Typography.Text>
        {
          modifiedParamsList?.length > 0 &&
            <div style={{maxHeight: 150, overflowY: 'auto', backgroundColor: '#131a2c', padding: '6px 12px'}}>
              {modifiedParamsList.map((param, index) => <div key={`${index}-${param}`}><Typography.Text>- {JSON.parse(param).name}</Typography.Text></div>)}
            </div>
        }
      <br />
      <Typography.Text strong>For the following assets:</Typography.Text>
      <div style={{maxHeight: 150, overflowY: 'auto', backgroundColor: '#131a2c', padding: '6px 12px'}}>
        {listOfAssets && listOfAssets.map((asset, index) => <div key={`${index}-${asset}`}><Typography.Text>{`${index +1}) ${asset}`}</Typography.Text></div>)}
      </div>
      <br />
      <div className="override-modal_radio-container" style={{display: 'flex', flexDirection: 'column'}}>
        <Radio checked={!overrideParams} onChange={() => setOverrideParams(!overrideParams)}>Keep all assets parameters values</Radio>
        <Radio checked={overrideParams} onChange={() => setOverrideParams(!overrideParams)}>Override all assets parameters values</Radio>
      </div>
    </Modal>
  )
}
