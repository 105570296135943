import React, { useState } from 'react'
import * as Store from 'types/store'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import ModelsTable from './ModelsTable'
import { CrudOperation } from 'types/enums'
const { Paragraph } = Typography

interface Props {
  mode?: number
  data: Store.ModelClass[]
  modelClass: Store.ModelClass
  assetBelongToGroup: boolean
  onSelect: (id: number) => void
}

const ModelField: React.FC<Props> = ({ mode, data, onSelect, modelClass, assetBelongToGroup }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {
        mode !== CrudOperation.Read && !assetBelongToGroup &&
        <>
          <ModelsTable visible={showModal} data={data} onCancel={() => setShowModal(false)} onSelect={onSelect} />
          <Row align="middle" gutter={[5, 0]} justify="center">
            <Col className="mb-12">
              <Button block onClick={() => setShowModal(true)} type="primary">
                Model Association
              </Button>
            </Col>
          </Row>
        </>
      }

      <Row align="middle" gutter={[5, 0]}>
        <Col span={12}>
          <Paragraph>{t('assets.modelName')}</Paragraph>
        </Col>
        <Col span={12} className="pt-10 pb-10">
          <Form.Item name="model" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.modelName }}>
            {modelClass?.modelName}
          </Paragraph>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 0]} className="pt-10 pb-10">
        <Col span={12}>
          {/* <Paragraph>{t('table.column.version')}</Paragraph> JI-TRANSLATE */}
          <Paragraph>Model Version</Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.version }}>{modelClass?.version}</Paragraph>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 0]}>
        <Col span={12}>
          <Paragraph>{t('assets.modelType')}</Paragraph>
        </Col>
        <Col span={12} className="pt-10 pb-10">
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.modelType }}>
            {modelClass?.modelType}
          </Paragraph>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 0]} className="pt-10 pb-10">
        <Col span={12}>
          <Paragraph>{t('assets.modelLanguage')}</Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.modelLanguage }}>
            {modelClass?.modelLanguage}
          </Paragraph>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 0]} className="pt-10 pb-10">
        <Col span={12}>
          <Paragraph>{t('assets.modelDescription')}</Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.modelDescription }}>
            {modelClass?.modelDescription}
          </Paragraph>
        </Col>
      </Row>
    </>
  )
}

export default ModelField
