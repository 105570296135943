import React, { useContext, useState } from 'react'
import { useWindowSize } from 'react-use'
import { Modal, Spin, Typography } from 'antd'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { CELL_HEIGHT, CELL_WIDTH, confirmFrameSize, getGridItemClass } from 'services/dashboardService'
import { useTranslation } from 'react-i18next'
import ContextMenu from '../ContextMenu'
import { FrameSize, GridItemActions, ViewType } from 'types/ui'
import AssetMetricsContainer from 'v2/containers/frames/TechnicalChart/AssetMetrics'
import SessionMetricsContainer from 'v2/containers/frames/TechnicalChart/SessionMetrics'
import { TechnicalFrameContext } from 'contexts/TechnicalFrameContext'
import { CheckOutlined } from '@ant-design/icons'
import ViewTypeSelector from './ViewTypeSelector'

interface Props {
  onItemClick: (action: GridItemActions) => void,
  reportEnabled: boolean,
}

const TechnicalChart: React.FC<Props> = ({ onItemClick, reportEnabled }) => {
  const { t } = useTranslation()
  const [filteredValues, setFilteredValues] = useState<Json>(null)
  const context = useContext(TechnicalFrameContext)

  const { squares, viewType, setViewType, isFullscreen, setFullScreen: setFullscreen, timeRange } = context
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const [width, height] = isFullscreen
    ? [windowWidth, windowHeight]
    : [squares.x * CELL_WIDTH, squares.y * CELL_HEIGHT]

  const isLarge = confirmFrameSize(width, height, FrameSize.WithCandleStickY)

  const toggleFullscreen = () => {
    setFullscreen(!isFullscreen)
  }

  const onMenuItemClick = (action: GridItemActions) => {
    if (action === GridItemActions.FullScreen) {
      toggleFullscreen()
      return
    }
    onItemClick(action)
  }

  const isLoading = !timeRange

  const panelContent = isLoading ? (
    <section className="running-session-spin-container">
      <ContextMenu
        isFullscreen={isFullscreen}
        onMenuItemClick={onMenuItemClick}
        items={[{ key: GridItemActions.Close, label: t('contextMenu.close') }]}
      />
      <Spin
        tip={t('operativeSession.sessionNotStarted')}
        size="large"
        wrapperClassName="session-running"
      ></Spin>
    </section>
  ) : (
    <section className={styles['technical-chart']}>
      <div className={classNames(getGridItemClass(width, height), { 'full-screen': isFullscreen })}>
        <div className="frame-header">
          <div className="frame-header__top-row">
            <Typography.Title level={3} className="title">
              {t('frameType.technical')}
            </Typography.Title>

            <ContextMenu
              isFullscreen={isFullscreen}
              onMenuItemClick={onMenuItemClick}
              items={[
                {
                  key: GridItemActions.Configure,
                  label: t('contextMenu.selectTimeRange'),
                },
                {
                  key: GridItemActions.Download,
                  label: 'Generate Report',
                  disabled: !reportEnabled,
                },
                {
                  key: GridItemActions.FullScreen,
                  label: t('contextMenu.fullscreen'),
                  icon: isFullscreen ? <CheckOutlined /> : null,
                },
                !isFullscreen && { key: GridItemActions.Close, label: t('contextMenu.close') },
              ]}
            />
          </div>
        </div>
        <div className="frame-content">
          <div className="technical-config-row">
            <ViewTypeSelector value={viewType} onChange={setViewType} />
          </div>

          {isLarge ? (
            <>
              <AssetMetricsContainer filteredValues={filteredValues} setFilteredValues={setFilteredValues} />
              <SessionMetricsContainer />
            </>
          ) : (
            <>
              {viewType === ViewType.AnalysisModel ? (
                <AssetMetricsContainer
                  filteredValues={filteredValues}
                  setFilteredValues={setFilteredValues}
                />
              ) : (
                <SessionMetricsContainer />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  )

  if (isFullscreen) {
    return (
      <Modal
        centered
        open={true}
        width={'100%'}
        wrapClassName={'technical-frame-fullscreen nonDraggable'}
        footer={null}
        closable={false}
      >
        {panelContent}
      </Modal>
    )
  }

  return panelContent
}

export default TechnicalChart
