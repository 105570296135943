import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as api from 'api/metrics'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { isApiError } from 'core'
import * as Api from 'types/api'
import * as Store from 'types/store'
import {
  mapJsonToAssetMetrics,
  mapUint8ArrayMessage,
  mapUint8ArrayToAssetMetrics,
} from 'services/store/mapMetrics'
// @ts-ignore
import { Metric } from 'sockets/metrics_report_pb'
import AnalysisModelStatusTable from 'v2/components/frames/TechnicalChart/AssetAnalysis/AnalysisModelStatusTable'
import { useInterval } from 'react-use'
import { TechnicalFrameContext } from 'contexts/TechnicalFrameContext'
import { getLastIntervalTime, getPoolingParams } from 'core/metricsHelper'

const getAssetMetrics = async (
  session: number,
  interval: number,
  startTime: string,
  endTime: string,
): Promise<Api.AssetsMetricsResponse> => {
  const response = await api.getAssetsMetrics(session, interval, startTime, endTime)
  if (isApiError(response)) {
    return null
  }

  return response as Api.AssetsMetricsResponse
}

interface Props {
  filteredValues: Json
  setFilteredValues: (values: Json) => void
}

const AssetMetricsContainer: React.FC<Props> = ({ filteredValues, setFilteredValues }) => {
  const session = useSelector(selectOperativeSessionSession)
  const [metrics, setMetrics] = useState<Metric.AsObject[]>([])
  const [assets, setAssets] = useState<Store.AssetMetric[]>([])
  const [interval, setTimeInterval] = useState<[Date, Date]>()

  const [liveStatuses, setLiveStatuses] = useState<Json<string>>({})

  const onMessage = async (message: MessageEvent<Blob>) => {
    const bytes = new Uint8Array(await message.data.arrayBuffer())
    const protoMessage = mapUint8ArrayMessage(bytes)
    if (protoMessage.metric) {
      const { metric } = protoMessage
      setLiveStatuses((statuses) => ({
        ...statuses,
        [metric.key]: metric.status,
      }))
    }
  }

  const { timeRange } = useContext(TechnicalFrameContext)

  const getData = async (seconds: number, from: Date, to: Date) => {
    const toValue = !session.isHistorical
      ? getLastIntervalTime(from, to, seconds).toISOString()
      : to.toISOString()

    const data = await getAssetMetrics(session.id, seconds, from.toISOString(), toValue)
    if (data) {
      if (data.metrics) {
        const metrics = mapUint8ArrayToAssetMetrics(data.metrics)
        setMetrics(metrics)
        const assetsMetrics = mapJsonToAssetMetrics(data.anagrafica, metrics)
        setAssets(assetsMetrics)
      }
    }
  }

  const params = getPoolingParams(interval)

  const fetchData = () => {
    if (params) {
      const { fromWithExtraRange, seconds, toWithExtraRange } = params
      const [startTime, endTime] = timeRange

      const end = endTime?.toDate() || new Date()

      const startFrom = startTime.toDate() > fromWithExtraRange ? startTime.toDate() : fromWithExtraRange
      const endTo = end <= toWithExtraRange ? end : toWithExtraRange

      getData(seconds, startFrom, endTo)
    }
  }

  useEffect(() => {
    if (interval) {
      fetchData()
    }
  }, [interval])

  useEffect(() => {
    if (!session.isHistorical) {
      console.log('subscribe tech')
      const unsubscribe = api.subscribeToAssetsMetrics(session.id, onMessage)
      return () => unsubscribe && unsubscribe()
    }
  }, [])

  const poolInterval = () => {
    if (!session.endTime && params && interval) {
      const [, endTime] = timeRange
      const { poolInterval, toWithExtraRange } = params

      if (endTime) {
        const end = endTime.toDate()
        return toWithExtraRange >= end ? poolInterval * 1000 : null
      }

      return poolInterval * 1000
    }
    return null
  }

  useInterval(fetchData, poolInterval())

  const assetsWithLatestStatuses = assets.map((value) => {
    if (liveStatuses[value.cmpKey]) {
      return {
        ...value,
        status: liveStatuses[value.cmpKey],
      }
    }
    return value
  })

  return (
    <AnalysisModelStatusTable
      metrics={metrics}
      assets={assetsWithLatestStatuses}
      setTimeInterval={setTimeInterval}
      filteredValues={filteredValues}
      setFilteredValues={setFilteredValues}
    />
  )
}

export default AssetMetricsContainer
