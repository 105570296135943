import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectApp } from 'store/App/selectors'
import { selectOperativeSessionSession, selectOperativeSessionState } from 'store/pages/selectors'
import { Tag } from 'antd'

import { useTranslation } from 'react-i18next'
import { LoadingState, MetricType, SessionStatus } from 'types/enums'
import * as api from 'api/liveSession'
import { mapUint8ArrayMessage } from 'services/store/mapMetrics'
import { getSocketSessionStatus } from 'services/enumConverter'
import { SocketSessionStatus } from 'types/enums.api'
import debounce from 'debounce'
import * as Store from 'types/store'
import {
  loadFinishedById,
  refreshSession,
  updateOperativeSessionStatus,
  updateBacktestProgress,
} from 'store/pages/operative-session/actions'

import Debug from 'debug'

import { sessionMetricsTypeSelector } from 'helpers/common'
import SessionStatusRenderer from 'components/Sessions/SessionStatusRenderer'

const debug = Debug('Frontend')

const SessionStatusIndicator: React.FC = () => {
  const { t } = useTranslation()
  const session = useSelector(selectOperativeSessionSession)
  const { isSocketAlive } = useSelector(selectApp)
  const [errors, setError] = useState<Store.SessionError[]>(null)
  const [backtestCurrentTime, setBacktestCurrentTime] = useState<Date>(null)
  const [percentage, setPercentage] = useState<number>(0)

  const isSocketOffline = isSocketAlive === false
  const loadingState = useSelector(selectOperativeSessionState)
  const isPageReady = session && loadingState !== LoadingState.Loading

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateBacktestProgress({percentage: percentage, time: backtestCurrentTime}))
  }, [backtestCurrentTime, percentage])

  const updateAssets = useCallback(
    debounce((id: number) => {
      dispatch(refreshSession(id))
    }, 500),
    [dispatch, session?.id],
  )

  const checkForError = async (sessionStatus: SessionStatus) => {
    const error = await api.getSessionErrors(session)
    setError(error)
  }

  const sessionCallback = (status: SocketSessionStatus) => {
    const sessionStatus = getSocketSessionStatus(status)
    if (
      status === SocketSessionStatus.Completed ||
      status === SocketSessionStatus.Stopped ||
      status === SocketSessionStatus.Broken
    ) {
      dispatch(loadFinishedById(session.id))
    }
    checkForError(sessionStatus)
    dispatch(updateOperativeSessionStatus(sessionStatus))
  }

  const assetCallback = () => {
    updateAssets(session.id)
  }

  const onMessage = async (message: MessageEvent<Blob>) => {
    const bytes = new Uint8Array(await message.data.arrayBuffer())
    const protoMessage = mapUint8ArrayMessage(bytes)
    if (protoMessage.metric) {
      const { metric } = protoMessage
      if (metric) {
        const { key, status } = metric
        const metricType = sessionMetricsTypeSelector(key)
        switch (metricType) {
          case MetricType.Asset:
            assetCallback()
            break
          case MetricType.Progress:
            setPercentage(protoMessage.metric.value)
            break
          case MetricType.Clock:
            // Set backtestCurrentTime only if the value it's greater than the current one
            setBacktestCurrentTime((prev) => {
              const currentTime = protoMessage.metric.value ? new Date(protoMessage.metric.value) : null
              if (currentTime > prev) {
                return currentTime
              }
              return prev
            })
            break
          case MetricType.Session:
            sessionCallback(status as SocketSessionStatus)
            break
        }
      }
      debug('Session Update proto message', protoMessage)
    }
  }

  useEffect(() => {
    if (isPageReady) {
      checkForError(session.status)
      if (!session.isHistorical) {
        const unsubscribe = api.subscribeToSessionMetrics(session.id, onMessage)
        return () => unsubscribe && unsubscribe()
      }
    }
  }, [loadingState])

  if (loadingState === LoadingState.Loading) {
    return null
  }

  if (isSocketOffline) {
    return <Tag color="red">{t('general.offline')}</Tag>
  }

  return <SessionStatusRenderer errors={errors} session={session} />
}

export default SessionStatusIndicator
