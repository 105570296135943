import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Table, Tooltip } from 'antd'
import styles from './styles.module.scss'
import { ColumnDef } from '@tanstack/react-table'
import * as Store from 'types/store'
import { formatNumber } from 'core/formats'
import { ColumnsType } from 'antd/lib/table'
import { getColumnSearchProps, sortNumber } from 'helpers/tableHelpers'
import { JITable } from '../JITable'

const TOP_OFFSET = 100
interface Props {
  isStrategyTable: boolean
  height: number
  width: number
  data: Store.PortfolioTable[]
  // frame: Store.Frame
  columnKeys: string[]
  columnsConfig: Store.TableConfig
  baseCurrency: string
}

const PortfolioTable: React.FC<Props> = ({ isStrategyTable, height, width, data: tableData, columnKeys = [], columnsConfig, baseCurrency = '' }) => {
  // console.log('PortfolioTable', columnKeys)
  const { t } = useTranslation()
  const data = useMemo(() => tableData, [tableData])
  // const columnHelper = createColumnHelper<Store.PortfolioTable>()

  // console.log('height', height)

  const scrollHeight = isStrategyTable ? height - TOP_OFFSET : 60
  const tableWidth = useMemo(() => width, [width])

  // const TABLE_COLUMNS: ColumnsType<Store.PortfolioTable> = [
  //   {
  //     title: <Tooltip title={t('table.column.portfolioName')}>Prt</Tooltip>,
  //     dataIndex: 'portfolioName',
  //     width: 199,
  //     fixed: true,
  //     ...(data.length > 1 ? getColumnSearchProps('portfolioName') : {}),
  //   },
  //   !isStrategyTable && {
  //     title: <Tooltip title={t('table.column.strategyName')}>Stg</Tooltip>,
  //     dataIndex: 'strategyName',
  //     width: 199,
  //   },
  //   !isStrategyTable && {
  //     title: <Tooltip title={t('table.column.baseCurrency')}>Ref. Ccy</Tooltip>,
  //     dataIndex: 'baseCurrency',
  //     width: 50,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.assets')}># Ast</Tooltip>,
  //     dataIndex: 'assetsNum',
  //     width: 45,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.assetsNum, b.assetsNum) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.countervalue', { baseCurrency: baseCurrency ?? '' })}>Ctv</Tooltip>, // ?? to avoid warning when value is not ready
  //     dataIndex: 'value',
  //     width: 40,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.value, b.value) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.longCountervalue')}>LNG Ctv</Tooltip>,
  //     dataIndex: 'valueLong',
  //     width: 65,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.valueLong, b.valueLong) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.shortCountervalue')}>SHT Ctv</Tooltip>,
  //     dataIndex: 'valueShort',
  //     width: 55,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.valueShort, b.valueShort) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyTradingReturn')}>TDY Trd Ret</Tooltip>,
  //     dataIndex: 'tdyTradingRetBc',
  //     width: 70,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyTradingRetBc, b.tdyTradingRetBc) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyExchangeRatesReturn')}>TDY Exch Rate Ret</Tooltip>,
  //     dataIndex: 'tdyExchRetBc',
  //     width: 110,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyExchRetBc, b.tdyExchRetBc) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyTotalReturn')}>TDY Tot Ret</Tooltip>,
  //     dataIndex: 'tdyAllRetBc',
  //     width: 70,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyAllRetBc, b.tdyAllRetBc) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdTradingReturn')}>YTD Trd Ret</Tooltip>,
  //     dataIndex: 'ytdTradingRetBc',
  //     width: 75,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdTradingRetBc, b.ytdTradingRetBc) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdExchangeRatesReturn')}>YTD Exch Rate Ret</Tooltip>,
  //     dataIndex: 'ytdExchRetBc',
  //     width: 110,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdExchRetBc, b.ytdExchRetBc) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdTotalReturn')}>YTD Tot Ret</Tooltip>,
  //     dataIndex: 'ytdAllRetBc',
  //     width: 70,
  //     render: (value: number) => {
  //       return formatNumber(value)
  //     },
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdAllRetBc, b.ytdAllRetBc) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyTotalOperations')}>TDY Tot Op</Tooltip>,
  //     dataIndex: 'tdyTotalOp',
  //     width: 75,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyTotalOp, b.tdyTotalOp) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyPositiveOperations')}>TDY Pos Op</Tooltip>,
  //     dataIndex: 'tdyPositiveOp',
  //     width: 75,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyPositiveOp, b.tdyPositiveOp) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.tdyNegativeOperations')}>TDY Neg Op </Tooltip>,
  //     dataIndex: 'tdyNegativeOp',
  //     width: 75,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyNegativeOp, b.tdyNegativeOp) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdTotalOperations')}>YTD Tot Op</Tooltip>,
  //     dataIndex: 'ytdTotalOp',
  //     width: 75,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdTotalOp, b.ytdTotalOp) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdPositiveOperations')}>YTD Pos Op</Tooltip>,
  //     dataIndex: 'ytdPositiveOp',
  //     width: 75,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdPositiveOp, b.ytdPositiveOp) : null,
  //   },
  //   {
  //     title: <Tooltip title={t('table.column.ytdNegativeOperations')}>YTD Neg Op</Tooltip>,
  //     dataIndex: 'ytdNegativeOp',
  //     width: 80,
  //     sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdNegativeOp, b.ytdNegativeOp) : null,
  //   },
  // ].filter((column) => column)
  /* eslint-enable */

  // const getColumns = (
  //   defaultColumns: ColumnsType<Store.PortfolioTable>,
  //   selectedColumnsKeys,
  // ): ColumnsType<Store.PortfolioTable> => {
  //   let generatedCols = []

  //   if (selectedColumnsKeys.length) {
  //     const selectedColumns = defaultColumns.filter((col: any) => selectedColumnsKeys.includes(col.dataIndex))

  //     generatedCols = [
  //       {
  //         title: t('table.column.portfolioName'),
  //         dataIndex: 'portfolioName',
  //         width: 199,
  //         fixed: true,
  //       },
  //       ...selectedColumns,
  //     ]
  //   } else {
  //     generatedCols = defaultColumns
  //   }

  //   return generatedCols
  // }

  // JI-TRANSLATE: all headers short names
  const tableColumns: ColumnDef<Store.PortfolioTable, any>[] = useMemo(() => [
    {
      header: 'Portfolio',
      accessorKey: 'portfolioName',
      id: 'portfolioName',
      enableResizing: false,
      size: 199,
      fixed: 'left',
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      meta: {
        filterVariant: 'text'
      },
      sorter: 'includesString' as any,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.assets')}># Ast</Tooltip>,
      accessorKey: 'assetsNum',
      id: 'assetsNum',
      size: 45,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.countervalue', { baseCurrency: baseCurrency ?? '' })}>{`Ctv ${baseCurrency}`}</Tooltip>, // ?? to avoid warning when value is not ready
      accessorKey: 'value',
      id: 'value',
      size: 60,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.longCountervalue')}>LNG Ctv</Tooltip>,
      accessorKey: 'valueLong',
      id: 'valueLong',
      size: 65,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.shortCountervalue')}>SHT Ctv</Tooltip>,
      accessorKey: 'valueShort',
      id: 'valueShort',
      size: 65,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTradingReturn')}>TDY Trd Ret</Tooltip>,
      accessorKey: 'tdyTradingRetBc',
      id: 'tdyTradingRetBc',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyExchangeRatesReturn')}>TDY Exch Rate Ret</Tooltip>,
      accessorKey: 'tdyExchRetBc',
      id: 'tdyExchRetBc',
      size: 120,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTotalReturn')}>TDY Tot Ret</Tooltip>,
      accessorKey: 'tdyAllRetBc',
      id: 'tdyAllRetBc',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTradingReturn')}>YTD Trd Ret</Tooltip>,
      accessorKey: 'ytdTradingRetBc',
      id: 'ytdTradingRetBc',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdExchangeRatesReturn')}>YTD Exch Rate Ret</Tooltip>,
      accessorKey: 'ytdExchRetBc',
      id: 'ytdExchRetBc',
      size: 115,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTotalReturn')}>YTD Tot Ret</Tooltip>,
      accessorKey: 'ytdAllRetBc',
      id: 'ytdAllRetBc',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{formatNumber(value.getValue())}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyTotalOperations')}>TDY Tot Op</Tooltip>,
      accessorKey: 'tdyTotalOp',
      id: 'tdyTotalOp',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyPositiveOperations')}>TDY Pos Op</Tooltip>,
      accessorKey: 'tdyPositiveOp',
      id: 'tdyPositiveOp',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.tdyNegativeOperations')}>TDY Neg Op</Tooltip>,
      accessorKey: 'tdyNegativeOp',
      id: 'tdyNegativeOp',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdTotalOperations')}>YTD Tot Op</Tooltip>,
      accessorKey: 'ytdTotalOp',
      id: 'ytdTotalOp',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdPositiveOperations')}>YTD Pos Op</Tooltip>,
      accessorKey: 'ytdPositiveOp',
      id: 'ytdPositiveOp',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
    {
      header: () => <Tooltip mouseEnterDelay={0.7} title={t('table.column.ytdNegativeOperations')}>YTD Neg Op</Tooltip>,
      accessorKey: 'ytdNegativeOp',
      id: 'ytdNegativeOp',
      // fixed: 'right',
      size: 80,
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      cell: (value) => <div className="num-right">{value.getValue()}</div>
    },
  ], [])

  if (!isStrategyTable) {
    tableColumns.splice(1, 0, {
        header: 'Strategy',
        accessorKey: 'strategyName',
        id: 'strategyName',
        enableResizing: false,
        // fixed: 'left',
        enableSorting: data.length > 1,
        enableColumnFilter: data.length > 1,
        size: 199,
      }
    )
    tableColumns.splice(2, 0, {
      header: () => (
        <Tooltip mouseEnterDelay={0.7} title={t('table.column.baseCurrency')}>
          Ref. Ccy
        </Tooltip>
      ),
      accessorKey: 'baseCurrency',
      id: 'baseCurrency',
      enableSorting: data.length > 1,
      enableColumnFilter: data.length > 1,
      size: 55,
    })
  }

  // const [columns] = useState<typeof tableColumns>(() => [
  //   ...tableColumns,
  // ])

  // return (
  //   <section className={`${styles['portfolio-table']} portfolio-table nonDraggable`}>
  //     <Table
  //       rowKey="portfolioName"
  //       // size='small'
  //       getPopupContainer={() => document.querySelector('.portfolio-table')}
  //       columns={getColumns(TABLE_COLUMNS, columnKeys)}
  //       dataSource={data}
  //       pagination={false}
  //       scroll={{ x: 200, y: scrollHeight }}
  //     /> 
  //     {tableElement}
  //     <JITable
  //       columns={columns}
  //       data={data}
  //     />
  //   </section>
  // )

  return (
    <JITable
      columns={tableColumns}
      data={data ?? []}
      scrollHeight={scrollHeight}
      // visibleColumns={columnKeys}
      columnsConfigs={columnsConfig}
    />
  )
}

export default PortfolioTable
