import React, { useMemo, useState } from 'react'
import { GridItemActions } from 'types/ui'
import * as Store from 'types/store'
import styles from './styles.module.scss'
import { Input, Popover, Typography } from 'antd'
import { useMeasure } from 'react-use'
import { getGridItemClass } from 'services/dashboardService'
import { useTranslation } from 'react-i18next'
import ContextMenu from '../ContextMenu'
import SignalsTable from './SignalsTable'
import IconFont from 'core/IconFont'
import Debug from 'debug'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import colors from 'config/colors'
import classNames from 'classnames'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'
const debug = new Debug('Frontend')

interface Props {
  data: Array<Store.SignalsData>
  onItemClick: (action: GridItemActions) => void
  isOffline: boolean
  frame?: Store.Frame
}

const SignalsPanel: React.FC<Props> = ({ data, onItemClick, isOffline, frame }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const [ref, { width, height }] = useMeasure()
  const [filter, setFilter] = useState('')
  const tableClass = classNames(styles['signals-panel'], themeStyles['theme'], {
    'offline-frame': isOffline,
  })

  const dataSource = data.filter((item) => {
    if (!item.name) {
      debug('Signals Panel: item has no name')
      return false
    }
    return item.name.toLowerCase().includes(filter.toLowerCase())
  })

  function handleFilterSearch(e) {
    const currValue = e.target.value
    setFilter(currValue)
  }

  const panelContent = (
    <div className="signals-panel__container nonDraggable">
      <div className="signals-panel__body">
        <SignalsTable data={dataSource} height={height} width={width} frame={frame} />
      </div>
    </div>
  )

  const troubleIcon = isOffline && (
    <Popover content={t('error.technicalProblems')} trigger="hover">
      <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
    </Popover>
  )

  return (
    <section className={tableClass} ref={ref}>
      <div className={getGridItemClass(width, height)}>
        <div className="frame-header">
          <div className="frame-header__top-row">
            {troubleIcon}
            <Typography.Title level={3} className="title">
              {t('frameType.signalsTable')}
            </Typography.Title>

            {/* <div className="search-input-wrapper">
              <Input
                size="small"
                value={filter}
                onChange={(e) => handleFilterSearch(e)}
                suffix={<IconFont type="icon-magnifier" />}
              />
            </div> */}

            <ContextMenu
              isFullscreen={false}
              onMenuItemClick={onItemClick}
              items={[{ key: GridItemActions.Close, label: t('contextMenu.close') }]}
            />
          </div>
        </div>
        <div className="frame-content">{panelContent}</div>
      </div>
    </section>
  )
}

export default SignalsPanel
